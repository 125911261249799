import { baseURL } from "../base"

const controller = `${baseURL}/user`

export async function getFamilyMappingsAPI(idToken, accessToken){
    const response = await fetch(`${controller}/familymappings`, {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    })
    return response
}

export async function getFamilyEventsAPI(idToken, accessToken, famId){
    const request = {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    }
    if (famId){
        request.headers.FamilyId = famId
    }
    const response = await fetch(`${controller}/familyevents`, request)
    return response
}

export async function getFamilyEventsTomorrowAPI(idToken, accessToken, famId){
    const request = {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    }
    if (famId){
        request.headers.FamilyId = famId
    }
    const response = await fetch(`${controller}/familyevents/tomorrow`, request)
    return response
}

export async function getFamilyEventsTwoDaysAPI(idToken, accessToken, famId){
    const request = {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    }
    if (famId){
        request.headers.FamilyId = famId
    }
    const response = await fetch(`${controller}/familyevents/twodays`, request)
    return response
}

export async function getUnmappedFamilyAPI(idToken, accessToken){
    const response = await fetch(`${controller}/unmappedfamily`, {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    })
    return response
}

export async function updateFamilyMappingAPI(idToken, accessToken, request){
    const response = await fetch(`${controller}/updatemapping`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function removeFamilyMappingAPI(idToken, accessToken, request){
    const response = await fetch(`${controller}/removemapping`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function getFamilyEventImageAPI(idToken, accessToken, day, famId, cols){
    const request = {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    }
    if (famId){
        request.headers.FamilyId = famId
    }
    if (cols){
        request.headers['custom-columns'] = cols
    }

    const response = await fetch(`${controller}/familyeventpicture/${day}`, request)
    return response
}

export async function updateExtraFamilyAPI(idToken, accessToken, request){
    const response = await fetch(`${controller}/extrafam`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function deleteExtraFamilyAPI(idToken, accessToken, id){
    const response = await fetch(`${controller}/extrafam/${id}`, {
        method: 'DELETE',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        },
    })
    return response
}

export async function buyExtraFamilyAPI(idToken, accessToken, qty){
    const response = await fetch(`${controller}/buyextrafams/${qty}`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        },
    })
    return response
}