const agencyTrackingStatus = {
    Unknown: 0,
    Active: 1,
    BeatingExpectations: 2,
    NotEligibleThisMonth: 4,
    HigherTier: 8,
    UnderExpectations: 16,
    BehindOnHours: 32,
    AheadOnHours: 64,
    BehindOnBeans: 128,
    AheadOnBeans: 256,
    BehindOnPCU: 512,
    AheadOnPCU: 1024,
    OnTrackToTierUp: 2048,
    NotActive: 4096,
    CantMakeHours: 8192,
}

export default agencyTrackingStatus