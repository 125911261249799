import { baseURL } from "../base"

const controller = `${baseURL}/discordsettings`

export async function getDiscordSettingsAPI(idToken, accessToken){
    const response = await fetch(`${controller}/usersettings`, {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: 'Bearer ' + idToken,
            Access: accessToken
        }
    })
    return response
}


export async function updateDiscordSettingsAPI(idToken, accessToken, request){
    const response = await fetch(`${controller}/updatesettings`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: 'Bearer ' + idToken,
            Access: accessToken
        },
        body: JSON.stringify(request)
    })
    return response
}


export async function manualFamilyPicAPI(idToken, accessToken){
    const response = await fetch(`${controller}/familypic`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: 'Bearer ' + idToken,
            Access: accessToken
        },
    })
    return response
}

export async function manualAgencyPicAPI(idToken, accessToken){
    const response = await fetch(`${controller}/agencypic`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: 'Bearer ' + idToken,
            Access: accessToken
        },
    })
    return response
}