import {useState, useEffect, Fragment, useMemo} from 'react'
import { Row, Col, ProgressBar, Form, ButtonGroup, Button } from 'react-bootstrap'
import { CircularProgress, Tabs, Tab, useMediaQuery, Checkbox } from '@material-ui/core'
import { getTokensSyncNoUpdate } from '../../api/auth/session'
import { handleErrors } from '../../api/base'
import { makeStyles } from "@material-ui/styles"
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import Edit from '@material-ui/icons/Edit'
import { userLinkURL, botLinkURL, discordTokenLocation, discordFlags } from '../../enums/bigoDiscord'
import { getDiscordUserInfoAPI, getDiscordServerList, getDiscordChannelList, getDiscordRefreshTokenAPI, getDiscordRoleList } from '../../api/bigo/discordapi'
import { getDiscordSettingsAPI, updateDiscordSettingsAPI, manualFamilyPicAPI, manualAgencyPicAPI } from '../../api/bigo/discordsettingsapi'
import { realPlans as testPlans} from '../../enums/bigoPlans/defaultPlan'
import userStatus from '../../enums/userStatus'
import SettingsIcon from '@material-ui/icons/Settings'
import { ImageColumns } from './components/imageColumns'

const useStyles = makeStyles({
    colLabel: {
        fontWeight: 'bold'
    },
    editIcon: {
        cursor: 'pointer',
        marginLeft: '10px'
    }
})

export const DiscordSettings = ({notify, sub, user}) => {

    const [loading, setLoading] = useState(true)
    const tokenInfo = getTokensSyncNoUpdate()
    const classes = useStyles()
    const isSmall = useMediaQuery('(max-width:650px)')
    const isLarge = useMediaQuery('(min-width:1200px)')
    const isXLarge = useMediaQuery('(min-width:1500px)')
    const [discordInfo, setDiscordInfo] = useState(null)
    const [discordToken, setDiscordToken] = useState(null)
    const [serverList, setServerList] = useState([])
    const [channelList, setChannelList] = useState([])
    const [roleList, setRoleList] = useState([])
    const [flags, setFlags] = useState(0)
    const [originalData, setOriginalData] = useState({})
    const [channelsLoadedFor, setChannelsLoadedFor] = useState('')
    const [hasFamily, setHasFamily] = useState(false)
    const [hasAgency, setHasAgency] = useState(false)

    const [postTime, setPostTime] = useState('')
    const [agPostTime, setAGPostTime] = useState('')

    const [serverId, setServerId] = useState('')
    const [editServer, setEditServer] = useState(false)
    const [ownerId, setOwnerId] = useState(null)

    const [imageChannel, setImageChannel] = useState('')
    const [editImageChannel, setEditImageChannel] = useState(false)

    const [reminderChannel, setReminderChannel] = useState('')
    const [editReminderChannel, setEditReminderChannel] = useState(false)

    const [agImageChannel, setAGImageChannel] = useState('')
    const [editAGImageChannel, setEditAGImageChannel] = useState(false)

    const [agReminderChannel, setAGReminderChannel] = useState('')
    const [editAGReminderChannel, setEditAGReminderChannel] = useState(false)

    const [agCols, setAGCols] = useState(null)
    const [famCols, setFamCols] = useState(null)

    const [showAGCols, setShowAGCols] = useState(null)
    const [showFamCols, setShowFamCols] = useState(null)

    const [agRoleId, setAGRoleId] = useState(null)
    const [famRoleId, setFamRoleId] = useState(null)
    const [editAGRole, setEditAGRole] = useState(false)
    const [editFamRole, setEditFamRole] = useState(false)

    useEffect(() => {
        const loadData = async () => {
            await loadSettings()
            const existingToken = getDiscordTokenFromMemory()
            if (!existingToken){
                setLoading(false)
            }
            setUserPlan()
        }
        loadData()
    }, [user, sub])

    useEffect(() => {
        const loadDiscord = async () => {
            await Promise.all([loadDiscordInfo(), getServerList(), getChannelList(), getRoleList()])
        }

        if (discordToken && !discordInfo){
            console.log('Loading For ' + JSON.stringify(discordToken))
            loadDiscord()
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [discordToken])

    useEffect(() => {
        if (!discordToken){
            let discordChecker = setInterval(() => {
                getDiscordTokenFromMemory()
            }, 2000)
            return () => {
                discordChecker && clearInterval(discordChecker)
            }
        }
    }, [discordToken])


    const setUserPlan = () => {
        if (user && sub && sub.UserStatus === userStatus.Paid && sub.SubscriptionId){
            const matchPlan = testPlans.filter(x => x.StripeId === sub.PlanId)[0]
            if (matchPlan){
                setHasFamily(matchPlan.Family !== 0)
                setHasAgency(matchPlan.Agency !== 0)
            }
            else if (sub.SubscriptionId === 'MASTER'){
                setHasFamily(true)
                setHasAgency(true)
            }
            else{
                setHasFamily(false)
                setHasAgency(false)
            }
        }
        else{
            setHasFamily(false)
            setHasAgency(false)
        }
    }

    const getDiscordTokenFromMemory = () => {
        let token = JSON.parse(localStorage.getItem(discordTokenLocation))
        if (token && token.access_token){
            setDiscordToken(token)
            return token
        }
    }

    const loadDiscordInfo = async (ovrToken) => {
        const tokenToUse = ovrToken || discordToken.access_token
        if (!tokenToUse){
            return
        }
        try{
            const response = await getDiscordUserInfoAPI(tokenToUse)
            let result = await handleErrors(response)

            if (result && result.ID === null){
                const refResponse = await getDiscordRefreshTokenAPI(discordToken.refresh_token)
                result = await handleErrors(refResponse)
                if (!result || !result.access_token){
                    localStorage.removeItem(discordTokenLocation)
                    setDiscordInfo(null)
                    setDiscordToken(null)
                }
                else{
                    localStorage.setItem(discordTokenLocation, JSON.stringify(result))
                    await loadDiscordInfo(result.access_token)
                }
                return
            }

            setDiscordInfo(result)
        }     
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
            localStorage.removeItem(discordTokenLocation)
            setDiscordToken(null)
        }
    }

    const logOutFromDiscord = () => {
        localStorage.removeItem(discordTokenLocation)
        setDiscordInfo(null)
        setDiscordToken(null)
    }

    const openInNewTab = (url) => {
        window.open(url, '_blank').focus()
    }

    const getServerList = async () => {
        try{
            const response = await getDiscordServerList(tokenInfo.IdToken, discordToken.access_token)
            const result = await handleErrors(response)
            result && setServerList(result)
        }     
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
    }

    const getIconFromList = (id, list) => {
        const x = list.filter(y => y.ID === id)[0]
        return x && x.Icon && <img src={`https://cdn.discordapp.com/icons/${x.ID}/${x.Icon}.png?size=32`} style={{marginRight: '10px'}} />
    }
    const getNameFromList = (id, list) => {
        const x = list.filter(y => y.ID === id)[0]

        if (x && x.Name){
            return x.Name
        }
        else if (id){
            return 'Unknown'
        }
    }

    const getChannelList = async () => {
        try{
            if (!serverId){
                return
            }
            const response = await getDiscordChannelList(tokenInfo.IdToken, discordToken.access_token, serverId)
            const result = await handleErrors(response)
            result && setChannelList(result)
            setChannelsLoadedFor(serverId)
        }     
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
    }

    const getRoleList = async () => {
        try{
            if (!serverId){
                return
            }
            const response = await getDiscordRoleList(tokenInfo.IdToken, discordToken.access_token, serverId)
            const result = await handleErrors(response)
            result && setRoleList(result)
            setChannelsLoadedFor(serverId)
        }     
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
    }

    const loadSettings = async () => {
        try{
            const response = await getDiscordSettingsAPI(tokenInfo.IdToken, tokenInfo.AccessToken)
            const result = await handleErrors(response)
            if (result){
                setServerId(result.ServerId || '')
                setFlags(result.Flags)
                setImageChannel(result.FamImageChannelId || '')
                setReminderChannel(result.FamReminderChannelId || '')
                setPostTime((result.FamPostTime && new Date(result.FamPostTime + ' UTC')) || '')
                setAGImageChannel(result.AGImageChannelId || '')
                setAGReminderChannel(result.AGReminderChannelId || '')
                setAGPostTime((result.AGPostTime && new Date(result.AGPostTime + ' UTC')) || '')
                setOriginalData(result)
                setAGCols(result.AGImageColumns)
                setFamCols(result.FamImageColumns)
                setOwnerId(result.OwnerId)
                setAGRoleId(result.AGRoleId)
                setFamRoleId(result.FamRoleId)
            }
        }     
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
    }

    const saveSettings = async () => {
        if (loading){
            return
        }
        setLoading(true)
        try{
            const request = {
                ServerId: serverId,
                Flags: flags,

                FamImageChannelId: imageChannel || null,
                FamReminderChannelId: reminderChannel || null,
                FamPostTime: postTime || null,

                AGImageChannelId: agImageChannel || null,
                AGReminderChannelId: agReminderChannel || null,
                AGPostTime: agPostTime || null,

                AGImageColumns: agCols ?? null,
                FamImageColumns: famCols ?? null,
                OwnerId: ownerId || null,

                AGRoleId: agRoleId || null,
                FamRoleId: famRoleId || null
            }

            window.postTime = postTime

            const response = await updateDiscordSettingsAPI(tokenInfo.IdToken, tokenInfo.AccessToken, request)
            const result = await handleErrors(response)
            if (result === 'Success'){
                setOriginalData(request)
                setEditAGImageChannel(false)
                setEditAGReminderChannel(false)
                setEditImageChannel(false)
                setEditReminderChannel(false)
                setEditServer(false)
            }
        }     
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
        setLoading(false)
    }

    const hasFlag = (s, f) => {
        return (s & f) !== 0
    }

    const setDiscordFlag = (f) => {
        hasFlag(flags, f) ? setFlags(flags & ~f) : setFlags(flags | f)
    }

    const reloadChannelsIfServerChanged = async () => {
        if (serverId !== channelsLoadedFor && serverId){
            await getChannelList()
            await getRoleList()
        }
    }

    const manualFamilyPicPush = async () => {
        setLoading(true)
        try{
            const response = await manualFamilyPicAPI(tokenInfo.IdToken, tokenInfo.AccessToken)
            const result = await handleErrors(response)
            if (result === 'Success'){
                notify('Success', 'Image Will Be Posted To Discord')
            }
        }     
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
        setLoading(false)
    }

    const manualAgencyPicPush = async () => {
        setLoading(true)
        try{
            const response = await manualAgencyPicAPI(tokenInfo.IdToken, tokenInfo.AccessToken)
            const result = await handleErrors(response)
            if (result === 'Success'){
                notify('Success', 'Image Will Be Posted To Discord')
            }
        }     
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
        setLoading(false)
    }

    return (
        <Fragment>
            <Row>
                <Col>
                    <h2>
                        Discord Settings
                    </h2>
                </Col>
                <Col xs={6} lg={4} xl={3}>
                    <Button style={{width: '100%'}} onClick={saveSettings}>Save</Button>
                </Col>
            </Row>

            <hr />
            
            <Row style={{marginTop: '5px', height: '85%', width: '100%'}}>
                <div style={{width: '100%'}}>
                    {loading
                    ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%', width: '100%' }}>
                        <CircularProgress style={{ width: '100px', height: '100px' }} />
                    </div>
                    : <Fragment>
                        <Form.Group as={Row}>
                            <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                Link User
                            </Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                {discordInfo 
                                    ? <Row style={{alignItems: 'center'}}>
                                        <span>{discordInfo.Username} #{discordInfo.Discriminator}</span>
                                        <Button variant='danger' onClick={logOutFromDiscord} style={{marginLeft: '10px'}}>Log Out</Button>
                                    </Row>
                                    : <Button onClick={() => openInNewTab(userLinkURL)}>Open Discord</Button>
                                }
                            </Col>
                            <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                Link Bot
                            </Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <Button onClick={() => openInNewTab(botLinkURL)}>Add Bot</Button>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                Select Server
                            </Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                {editServer
                                    ? <Form.Control as="select" value={serverId} onChange={(e) => setServerId(e.target.value)}>
                                        <option value="">Select Server</option>
                                        {serverList.map(x => <option value={x.ID} key={x.ID}>{x.Name}</option>)}
                                    </Form.Control>
                                    : <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                        <span>
                                            {serverId && getIconFromList(serverId, serverList)}
                                            {(serverId  && getNameFromList(serverId, serverList)) || 'Not Set'}
                                        </span>
                                        {discordInfo && <Edit className={classes.editIcon} onClick={() => { !serverList.length && getServerList(); setEditServer(true) }} />}
                                    </div>
                                }
                            </Col>
                            <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                Manager
                            </Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <Row style={{alignItems: 'center', height: '100%'}}>
                                    {ownerId
                                        ? discordInfo && ownerId === discordInfo.ID ? 'Me' : ownerId
                                        : 'Not Set'
                                    }
                                    {discordInfo && discordInfo.ID && ownerId !== discordInfo.ID && (
                                        <Button style={{marginLeft: '20px'}} onClick={() => setOwnerId(discordInfo.ID)}>Set As Me</Button>
                                    )}
                                </Row>
                            </Col>
                        </Form.Group>

                        {hasFamily && <Fragment>
                            <Row>
                                <Col>
                                    <h3 style={{marginLeft: '20px'}}>Family Settings</h3>
                                </Col>
                            </Row>
                            <hr style={{width: '80%', marginLeft: '30px'}} />
                            <Form.Group as={Row}>
                                <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                    Select Image Channel
                                </Form.Label>
                                <Col xl={2} lg={3} md={3}>
                                    {editImageChannel
                                        ? <Form.Control as="select" value={imageChannel} onChange={(e) => setImageChannel(e.target.value)}>
                                            <option value="">Select Channel</option>
                                            {channelList.map(x => <option value={x.ID} key={x.ID}>{x.Name}</option>)}
                                        </Form.Control>
                                        : <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                            <span>
                                                {(imageChannel && getNameFromList(imageChannel, channelList)) || 'Not Set'}
                                            </span>
                                            {discordInfo && serverId && <Edit className={classes.editIcon} onClick={() => {
                                                reloadChannelsIfServerChanged()
                                                setEditImageChannel(true)
                                            }} />}
                                        </div>
                                    }
                                </Col>
                                <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                    Select Reminder Channel
                                </Form.Label>
                                <Col xl={2} lg={3} md={3}>
                                    {editReminderChannel
                                        ? <Form.Control as="select" value={reminderChannel} onChange={(e) => setReminderChannel(e.target.value)}>
                                            <option value="">Select Channel</option>
                                            {channelList.map(x => <option value={x.ID} key={x.ID}>{x.Name}</option>)}
                                        </Form.Control>
                                        : <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                            <span>
                                                {(channelList && getNameFromList(reminderChannel, channelList)) || 'Not Set'}
                                            </span>
                                            {discordInfo && serverId && <Edit className={classes.editIcon} onClick={() => {
                                                reloadChannelsIfServerChanged()
                                                setEditReminderChannel(true)
                                            }} />}
                                        </div>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                    Post Images
                                </Form.Label>
                                <Col xl={2} lg={3} md={3}>
                                    <Checkbox checked={hasFlag(flags, discordFlags.FamilyImages)} onChange={(e) => setDiscordFlag(discordFlags.FamilyImages)} />
                                    <SettingsIcon
                                        style={{marginLeft: '20px', cursor: 'pointer'}}
                                        onClick={(e) => setShowFamCols(e.target)}
                                    />
                                    <ImageColumns
                                        id='Discord-Fam-Cols'
                                        el={showFamCols}
                                        close={(flags) => {
                                            setShowFamCols(null)
                                            setFamCols(flags)
                                        }}
                                        startingFlags={famCols}
                                    />
                                </Col>
                                <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                    Send Reminders
                                </Form.Label>
                                <Col xl={2} lg={3} md={3}>
                                    <Checkbox checked={hasFlag(flags, discordFlags.FamilyReminders)} onChange={(e) => setDiscordFlag(discordFlags.FamilyReminders)} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                    Tag Users With Events
                                </Form.Label>
                                <Col xl={2} lg={3} md={3}>
                                    <Checkbox checked={hasFlag(flags, discordFlags.FamilyIncludeTags)} onChange={(e) => setDiscordFlag(discordFlags.FamilyIncludeTags)} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                    Image Post Time
                                </Form.Label>
                                <Col xl={2} lg={3} md={3}>
                                    <DatePicker 
                                        selected={postTime}
                                        onChange={(d) => setPostTime(d)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                    />
                                </Col>
                                <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                    Manually Send Image
                                </Form.Label>
                                <Col xl={2} lg={3} md={3}>
                                    <Button onClick={manualFamilyPicPush}>Send Image</Button>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                    Default Role
                                </Form.Label>
                                <Col xl={2} lg={3} md={3}>
                                    {editFamRole
                                        ? <Form.Control as="select" value={famRoleId} onChange={(e) => setFamRoleId(e.target.value)}>
                                            <option value="">Select Role</option>
                                            {roleList.map(x => <option value={x.ID} key={x.ID}>{x.Name}</option>)}
                                        </Form.Control>
                                        : <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                            <span>
                                                {(roleList && getNameFromList(famRoleId, roleList)) || 'Not Set'}
                                            </span>
                                            {discordInfo && serverId && <Edit className={classes.editIcon} onClick={() => {
                                                reloadChannelsIfServerChanged()
                                                setEditFamRole(true)
                                            }} />}
                                        </div>
                                    }
                                </Col>
                            </Form.Group>
                        </Fragment>}

                        {hasAgency && <Fragment>
                            <Row>
                                <Col>
                                    <h3 style={{marginLeft: '20px'}}>Agency Settings</h3>
                                </Col>
                            </Row>
                            <hr style={{width: '80%', marginLeft: '30px'}} />
                            <Form.Group as={Row}>
                                <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                    Select Image Channel
                                </Form.Label>
                                <Col xl={2} lg={3} md={3}>
                                    {editAGImageChannel
                                        ? <Form.Control as="select" value={agImageChannel} onChange={(e) => setAGImageChannel(e.target.value)}>
                                            <option value="">Select Channel</option>
                                            {channelList.map(x => <option value={x.ID} key={x.ID}>{x.Name}</option>)}
                                        </Form.Control>
                                        : <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                            <span>
                                                {(agImageChannel && getNameFromList(agImageChannel, channelList)) || 'Not Set'}
                                            </span>
                                            {discordInfo && serverId && <Edit className={classes.editIcon} onClick={() => {
                                                reloadChannelsIfServerChanged()
                                                setEditAGImageChannel(true)
                                            }} />}
                                        </div>
                                    }
                                </Col>
                                <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                    Select Reminder Channel
                                </Form.Label>
                                <Col xl={2} lg={3} md={3}>
                                    {editAGReminderChannel
                                        ? <Form.Control as="select" value={agReminderChannel} onChange={(e) => setAGReminderChannel(e.target.value)}>
                                            <option value="">Select Channel</option>
                                            {channelList.map(x => <option value={x.ID} key={x.ID}>{x.Name}</option>)}
                                        </Form.Control>
                                        : <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                            <span>
                                                {(channelList && getNameFromList(agReminderChannel, channelList)) || 'Not Set'}
                                            </span>
                                            {discordInfo && serverId && <Edit className={classes.editIcon} onClick={() => {
                                                reloadChannelsIfServerChanged()
                                                setEditAGReminderChannel(true)
                                            }} />}
                                        </div>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                    Post Images
                                </Form.Label>
                                <Col xl={2} lg={3} md={3}>
                                    <Checkbox checked={hasFlag(flags, discordFlags.AgencyImages)} onChange={(e) => setDiscordFlag(discordFlags.AgencyImages)} />
                                    <SettingsIcon
                                        style={{marginLeft: '20px', cursor: 'pointer'}}
                                        onClick={(e) => setShowAGCols(e.target)}
                                    />
                                    <ImageColumns
                                        id='Discord-AG-Cols'
                                        el={showAGCols}
                                        close={(flags) => {
                                            setShowAGCols(null)
                                            setAGCols(flags)
                                        }}
                                        startingFlags={agCols}
                                    />
                                </Col>
                                <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                    Send Reminders
                                </Form.Label>
                                <Col xl={2} lg={3} md={3}>
                                    <Checkbox checked={hasFlag(flags, discordFlags.AgencyReminders)} onChange={(e) => setDiscordFlag(discordFlags.AgencyReminders)} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                    Tag Users With Events
                                </Form.Label>
                                <Col xl={2} lg={3} md={3}>
                                    <Checkbox checked={hasFlag(flags, discordFlags.AgencyIncludeTags)} onChange={(e) => setDiscordFlag(discordFlags.AgencyIncludeTags)} />
                                </Col>
                                <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                    Include Family Events
                                </Form.Label>
                                <Col xl={2} lg={3} md={3}>
                                    <Checkbox checked={hasFlag(flags, discordFlags.AgencyIncludeFamily)} onChange={(e) => setDiscordFlag(discordFlags.AgencyIncludeFamily)} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                    Image Post Time
                                </Form.Label>
                                <Col xl={2} lg={3} md={3}>
                                    <DatePicker 
                                        selected={agPostTime}
                                        onChange={(d) => setAGPostTime(d)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                    />
                                </Col>
                                <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                    Manually Send Image
                                </Form.Label>
                                <Col xl={2} lg={3} md={3}>
                                    <Button onClick={manualAgencyPicPush}>Send Image</Button>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                    Default Role
                                </Form.Label>
                                <Col xl={2} lg={3} md={3}>
                                    {editAGRole
                                        ? <Form.Control as="select" value={agRoleId} onChange={(e) => setAGRoleId(e.target.value)}>
                                            <option value="">Select Role</option>
                                            {roleList.map(x => <option value={x.ID} key={x.ID}>{x.Name}</option>)}
                                        </Form.Control>
                                        : <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                            <span>
                                                {(roleList && getNameFromList(agRoleId, roleList)) || 'Not Set'}
                                            </span>
                                            {discordInfo && serverId && <Edit className={classes.editIcon} onClick={() => {
                                                reloadChannelsIfServerChanged()
                                                setEditAGRole(true)
                                            }} />}
                                        </div>
                                    }
                                </Col>
                            </Form.Group>
                        </Fragment>}
                    </Fragment>}
                </div>
            </Row>
        </Fragment>
    )
}