import {useState, useEffect, Fragment, useMemo} from 'react'
import { Row, Col, ProgressBar, Form, ButtonGroup, Button } from 'react-bootstrap'
import { CircularProgress, Tabs, Tab, useMediaQuery, Checkbox } from '@material-ui/core'
import { getMainDashboardAPI } from '../../api/bigo/dashboardapi'
import { getTokensSyncNoUpdate } from '../../api/auth/session'
import { handleErrors } from '../../api/base'
import { makeStyles } from "@material-ui/styles"
import qs from 'query-string'
import { getDiscordTokenAPI } from '../../api/bigo/discordapi'
import { discordTokenLocation } from '../../enums/bigoDiscord'

export const DiscordLink = (props) => {

    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState('')
    

    useEffect(() => {
        const loadToken = async (c) => {
            await getTokenFromCode(c)
            setLoading(false)
        }
        const value = qs.parse(props.location.search);
        const code = value.code
        
        if (code){
            loadToken(code)
        }
        else{
            setMessage('Bad URL')
            setLoading(false)
        }
    }, [props.location])

    const getTokenFromCode = async (code) => {
        try{
            const response = await getDiscordTokenAPI(code)
            const result = await handleErrors(response)

            if (!result || !result.access_token){
                setMessage('Invalid Code')
                setLoading(false)
                return
            }

            localStorage.setItem(discordTokenLocation, JSON.stringify(result))

            setMessage('You may now close this window.')
            setTimeout(() => { window.close() }, 1000)
        }        
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            setMessage(finalError.toString())
        }
        setLoading(false)
    }

    return (
        <div style={{width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#e4e5e8'}}>
            {loading
                ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%', width: '100%' }}>
                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                </div>
                : <h1>{message || ''}</h1>
            }
        </div>
    )
}