import { baseURL } from "../base"

const controller = `${baseURL}/signup/agency`

export async function getAgencySignUpsAPI(idToken, accessToken){
    const response = await fetch(`${controller}/owner`, {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    })
    return response
}

export async function getAgencySignUpInfoAPI(code){
    const response = await fetch(`${controller}/${code}`, {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
        }
    })
    return response
}

export async function submitAgencySignUpInfoAPI(code, request){
    const response = await fetch(`${controller}/${code}`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function updateAgencySignUpsAPI(idToken, accessToken, request){
    const response = await fetch(`${controller}/owner`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        },
        body: JSON.stringify(request)
    })
    return response
}