import react, {useState, useEffect, Fragment} from 'react'
import { Row, Col, Button, Form, Modal, InputGroup } from 'react-bootstrap'
import { Card, CircularProgress, Checkbox, TextField, Tabs, Tab, useMediaQuery, FormControlLabel, Switch } from '@material-ui/core'
import { getTokensSyncNoUpdate } from '../../../api/auth/session'
import { handleErrors } from "../../../api/base"
import userFlags from "../../../enums/userFlags"
import { getFamilySignUpInfoAPI, submitFamilySignUpInfoAPI } from '../../../api/bigo/familysignupsapi'
import { makeStyles } from "@material-ui/styles"
import { useParams } from 'react-router-dom'
import { Autocomplete } from "@material-ui/lab"
import MaskedInput from 'react-text-mask'
import { CountrySelector } from "../components/countrySelector"
import { Toasty } from '../../public/toast'
import { TimeZonePicker } from "../components/timezone"
import { userLinkURL, discordTokenLocation } from '../../../enums/bigoDiscord'
import { getDiscordUserInfoAPI } from '../../../api/bigo/discordapi'

const useStyles = makeStyles({
    colLabel: {
        fontWeight: 'bold'
    }
})

export const FamilySelfSignUp = () => {

    const [loading, setLoading] = useState(true)
    const [signUpInfo, setSignUpInfo] = useState(null)
    // const tokenInfo = getTokensSyncNoUpdate()
    const classes = useStyles()
    const isSmall = useMediaQuery('(max-width:650px)')
    const isLarge = useMediaQuery('(min-width:1200px)')
    const isXLarge = useMediaQuery('(min-width:1500px)')
    const userHasPremiumSMS = () => Boolean(signUpInfo && signUpInfo.PremiumSMS)
    let { code } = useParams()
    const [toasts, setToasts] = useState([])

    const [sendSMS, setSendSMS] = useState(true)
    const [bigoId, setBigoId] = useState('')
    const [phone, setPhone] = useState('')
    const [carrierId, setCarrierId] = useState(0)
    const [country, setCountry] = useState('USA')
    const [tz, setTZ] = useState(0)
    const [dst, setDST] = useState(false)
    const [discordToken, setDiscordToken] = useState(null)
    const [discordInfo, setDiscordInfo] = useState(null)

    const [submitted, setSubmitted] = useState(false)

    const dateFormatter = new Intl.DateTimeFormat('en-US', {
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short'
    })

    useEffect(() => {
        const loadInfo = async () => {
            await reloadSignUp()
        }
        loadInfo()
    }, [code])

    const reloadSignUp = async () => {
        try{
            if (!code){
                return
            }
            const response = await getFamilySignUpInfoAPI(code)
            const result = await handleErrors(response)
            result && setSignUpInfo(result)
            console.log(result)
            setLoading(false)
        }
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
    }

    const notify = (title, msg) => {
        let tsts = toasts.map(x => x)
        const time = new Date()
        tsts.push({
            title: title,
            message: msg,
            key: time.toLocaleTimeString()
        })
        setToasts(tsts)
        return tsts
    }

    const getDiscordTokenFromMemory = () => {
        let token = JSON.parse(localStorage.getItem(discordTokenLocation))
        if (token && token.access_token){
            setDiscordToken(token)
            return token
        }
    }

    useEffect(() => {
        if (!discordToken){
            let discordChecker = setInterval(() => {
                getDiscordTokenFromMemory()
            }, 2000)
            return () => {
                discordChecker && clearInterval(discordChecker)
            }
        }
    }, [discordToken])

    const openInNewTab = (url) => {
        window.open(url, '_blank').focus()
    }

    useEffect(() => {
        const loadDiscord = async () => {
            await loadDiscordInfo()
        }

        if (discordToken && !discordInfo){
            console.log('Loading For ' + JSON.stringify(discordToken))
            loadDiscord()
        }
    }, [discordToken])

    const loadDiscordInfo = async (ovrToken) => {
        const tokenToUse = ovrToken || discordToken.access_token
        if (!tokenToUse){
            return
        }
        try{
            const response = await getDiscordUserInfoAPI(tokenToUse)
            let result = await handleErrors(response)

            if (result && result.ID === null){
                const refResponse = await getDiscordRefreshTokenAPI(discordToken.refresh_token)
                result = await handleErrors(refResponse)
                if (!result || !result.access_token){
                    localStorage.removeItem(discordTokenLocation)
                    setDiscordInfo(null)
                    setDiscordToken(null)
                }
                else{
                    localStorage.setItem(discordTokenLocation, JSON.stringify(result))
                    await loadDiscordInfo(result.access_token)
                }
                return
            }

            setDiscordInfo(result)
        }     
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
            localStorage.removeItem(discordTokenLocation)
            setDiscordToken(null)
        }
    }

    const logOutFromDiscord = () => {
        localStorage.removeItem(discordTokenLocation)
        setDiscordInfo(null)
        setDiscordToken(null)
    }

    const submitData = async () => {
        if (!bigoId.trim()){
            notify('BIGO ID Required', 'Your BIGO ID is Required')
            return
        }
        const request  = {
            UserName: bigoId,
            TimezoneOffset: tz ? Number(tz) : null,
            ObservesDST: dst,
            CountryCode: country,
            NoTexts: !sendSMS,
            DiscordId: (discordInfo && discordInfo.ID) || null,
        }

        if (sendSMS){
            if (!phone.length){
                notify('Phone Number Required', 'A Phone Number is Required to Receive Text Alerts')
                return
            }
            else if (phone.length !== 14){
                notify('Invalid Phone Number', 'Invalid Phone Number Length')
                return
            }
            request.Phone = phone
            if (!userHasPremiumSMS()){
                if (!Number(carrierId)){
                    notify('Invalid Carrier', 'Must Select A Carrier')
                    return
                }
                request.CarrierId = Number(carrierId)
            }
        }

        setLoading(true)
        try{
            const response = await submitFamilySignUpInfoAPI(code, request)
            const result = await handleErrors(response)
            result && setSubmitted(true)
        }
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
        setLoading(false)
    }
    
    return <Fragment>
        <div style={{width: '100vw', height: '100vh', backgroundColor: '#e4e5e8'}}>
        <Row style={{paddingTop: '30px'}}>
            <Col>
                <h2>
                    {(signUpInfo && signUpInfo.FamilyName) || ''}
                </h2>
            </Col>
        </Row>

        <hr />

        <Row style={{marginTop: '5px', height: '85%', width: '100%'}}>
            <div style={{width: '100%'}}>
                {loading
                ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%', width: '100%' }}>
                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                </div>
                : submitted 
                    ? <Row style={{marginTop: '50px'}}>
                        <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                            <h3>Thank You</h3>
                            <h3 style={{textAlign: 'center'}}>Your Information Has Been Submitted For Review!</h3>
                        </Col>
                    </Row>
                    : <Fragment>
                    <Form.Group as={Row}>
                        <Form.Label className={classes.colLabel} column style={{ display: 'flex', alignItems: 'center', justifyContent: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                            Bigo ID
                        </Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            <Autocomplete
                                id="name-autocomplete"
                                options={(signUpInfo && signUpInfo.FamilyMembers) || []}
                                freeSolo
                                inputValue={bigoId}
                                defaultValue={bigoId}
                                onInputChange={(e, v) => setBigoId(v)}
                                renderInput={(params) => <TextField {...params} maxLength={200} variant="outlined" />}
                            />
                        </Col>
                        <Form.Label className={classes.colLabel} column style={{ display: 'flex', alignItems: 'center', justifyContent: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                            Do You Want Text Alerts?
                        </Form.Label>
                        <Col xl={2} lg={3} md={3} style={{display: 'flex', alignItems: 'center'}}>
                            <FormControlLabel control={<Switch checked={sendSMS} onChange={() => setSendSMS(!sendSMS)} color="primary" />} label={sendSMS ? 'Yes' : 'No'} />
                        </Col>
                    </Form.Group>
                    {sendSMS && <Form.Group as={Row}>
                        <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                            Phone
                        </Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            <MaskedInput
                                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                className="form-control"
                                guide={false}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                                type="tel"
                                value={phone}
                            />
                        </Col>
                        {!userHasPremiumSMS() && <Fragment>
                            <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                Carrier
                            </Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <Form.Control as="select" value={carrierId} maxLength={200} onChange={(e) => setCarrierId(e.target.value)}>
                                    <option value={0}>-- Select --</option>
                                    {signUpInfo && signUpInfo.Carriers.sort((a, b) => a.Name > b.Name ? 1 : -1).map(x => {
                                        return <option value={x.Id} key={x.Id}>{x.Name}</option>
                                    })}
                                </Form.Control>
                            </Col>
                            </Fragment>
                        }
                    </Form.Group>
                    }
                    <Form.Group as={Row}>
                        <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                            Country
                        </Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            <CountrySelector getter={country} setter={(e) => setCountry(e.target.value)} />
                        </Col>
                        <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                            Discord (optional)
                        </Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            {discordInfo 
                                ? <Row style={{alignItems: 'center'}}>
                                    <span>{discordInfo.Username} #{discordInfo.Discriminator}</span>
                                    <Button variant='danger' onClick={logOutFromDiscord} style={{marginLeft: '10px'}}>Log Out</Button>
                                </Row>
                                : <Button onClick={() => openInNewTab(userLinkURL)}>Open Discord</Button>
                            }
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                            Timezone
                        </Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            <TimeZonePicker getter={tz} setter={(e) => setTZ(e.target.value)} />
                        </Col>
                        <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                            Observe DST
                        </Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            <Checkbox checked={dst} onChange={(e) => setDST(e.target.checked)} />
                        </Col>
                    </Form.Group>
                    <Row>
                        <Col xs={6}>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Button variant="success" onClick={submitData}>Submit</Button>
                            </div>
                        </Col>
                    </Row>
                </Fragment>
                }
            </div>
        </Row>
        </div>
        <div style={{position: 'fixed', top: '8vh', right: '20px'}}>
                <Toasty toasts={toasts} setToasts={setToasts} />
            </div>
    </Fragment>
}