import { baseURL } from "../base"

const controller = `${baseURL}/agency/dashboard`

export async function getMainDashboardAPI(idToken, accessToken){
    const response = await fetch(`${controller}/main`, {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    })
    return response
}