import react, { useState, useEffect, Fragment } from 'react'
import { Button, Form, Modal, Row, Table, Col } from 'react-bootstrap'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline'
import { getMasterUserListAPI, getMasterJobStatusAPI, switchMasterUserAPI, setMasterJobStatusAPI } from '../../api/bigo/masterapi'
import { handleErrors } from '../../api/base'
import { Autocomplete, TextField } from '@mui/material'
import { getTokensSyncNoUpdate, setTokens } from '../../api/auth/session'
import { CircularProgress, useMediaQuery } from '@material-ui/core'
import { makeStyles } from "@material-ui/styles"
import { utcStringToLocal } from '../../utility/dateHelper'

const useStyles = makeStyles({
    colLabel: {
        fontWeight: 'bold'
    }
})

export const MasterAdmin = ({notify, show, onHide}) => {

    const [loading, setLoading] = useState(true)
    const tokenInfo = getTokensSyncNoUpdate()
    const classes = useStyles()
    const isSmall = useMediaQuery('(max-width:650px)')
    const isLarge = useMediaQuery('(min-width:1200px)')
    const isXLarge = useMediaQuery('(min-width:1500px)')

    const [targetUser, setTargetUser] = useState(tokenInfo.Master)
    const [userList, setUserList] = useState([])
    const [jobInfo, setJobInfo] = useState(null)

    const defaultOffs = ['FREE_PREMIUM_SMS', 'FAMILY_SKIP_URL']

    useEffect(() => {
        
        if (!show){
            return
        }

        const loadData = async () => {
            await loadUsers()
            await loadJobs()
            setLoading(false)
        }

        loadData()
    }, [show])

    const loadUsers = async () => {
        try{
            const response = await getMasterUserListAPI(tokenInfo.IdToken, tokenInfo.AccessToken)
            const result = await handleErrors(response)
            
            setUserList(result)
        }
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
    }

    const loadJobs = async () => {
        try{
            const response = await getMasterJobStatusAPI(tokenInfo.IdToken, tokenInfo.AccessToken)
            const result = await handleErrors(response)
            
            setJobInfo(result)
        }
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
    }

    const updateJob = async (job, on) => {
        setLoading(true)
        try{
            const response = await setMasterJobStatusAPI(tokenInfo.IdToken, tokenInfo.AccessToken, job, on)
            const result = await handleErrors(response)
            
            await loadJobs()
        }
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
        setLoading(false)
    }

    const switchUser = async () => {
        try{
            const response = await switchMasterUserAPI(tokenInfo.IdToken, tokenInfo.AccessToken, targetUser)
            const result = await handleErrors(response)
            
            if (result === 'Success'){
                setLoading(true)
                const clone = Object.assign({}, tokenInfo)
                const match = userList.filter(x => x.CognitoUsername === targetUser)[0]
                clone.Master = match.CognitoUsername
                clone.Email = match.Email

                setTokens(clone)
                window.location.reload()
            }
        }
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
    }

    return <Modal show={show} onHide={onHide} size='lg' backdrop='static' centered>
        <Modal.Header closeButton>
            <h3>Master Admin</h3>
        </Modal.Header>
        <Modal.Body>
            {loading
                ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%', width: '100%' }}>
                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                </div>
                : <Fragment>
                    <Form.Group as={Row}>
                        <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                            Change User
                        </Form.Label>
                        <Col xl={6} lg={3} md={3}>
                            <Form.Control as="select" value={targetUser} maxLength={200} onChange={(e) => setTargetUser(e.target.value)}>
                                {userList.map(x => {
                                    return <option value={x.CognitoUsername} key={x.CognitoUsername}>{x.Email}{x.Family && ` (${x.Family})`}</option>
                                })}
                            </Form.Control>
                        </Col>
                        <Col xl={2} lg={3} md={3}>
                            <Button onClick={switchUser}>Switch</Button>
                        </Col>
                    </Form.Group>
                    {jobInfo && jobInfo.map(x => {
                        let untilTime = ''
                        if (x.Until){
                            untilTime = 'until ' + utcStringToLocal(x.Until, 'datetime')
                        }
                        return <Fragment key={x.Name}>
                            <hr />
                            <Form.Group as={Row}>
                            <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={3} lg={4} md={4}>
                                {x.Name}
                            </Form.Label>
                            <Col xl={5} lg={4} md={3}>
                                {defaultOffs.includes(x.Name)
                                    ? x.Status
                                        ? 'Enabled ' + untilTime
                                        : 'Disabled'
                                    : x.Status 
                                        ? 'Running' 
                                        : 'Stopped ' + untilTime
                                }
                            </Col>
                            <Col>
                                {defaultOffs.includes(x.Name)
                                ? x.Status
                                    ? <Fragment>
                                        <Button variant="danger" style={{marginRight: '5px'}} onClick={() => updateJob(x.Name, false)}>DISABLE</Button>
                                        <Button variant="warning" onClick={() => updateJob(x.Name, true)}>EXTEND</Button>
                                    </Fragment>
                                    : <Button variant="success" onClick={() => updateJob(x.Name, true)}>ENABLE</Button>
                                : x.Status
                                    ? <Button variant="danger" onClick={() => updateJob(x.Name, false)}>24 HOUR PAUSE</Button>
                                    : <Fragment>
                                        <Button variant="success" style={{marginRight: '5px'}} onClick={() => updateJob(x.Name, true)}>RESUME</Button>
                                        <Button variant="warning" onClick={() => updateJob(x.Name, false)}>EXTEND</Button>
                                    </Fragment>
                                }
                            </Col>
                        </Form.Group>
                        </Fragment>
                    })}
                </Fragment>}
        </Modal.Body>
    </Modal>
}