import React, { useEffect, useState, Fragment } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { LeftNav } from '../navigation/leftnav'
import { TopNav } from "../navigation/topnav";
import { getTokensSyncNoUpdate } from '../../api/auth/session'
import userPages from '../../enums/userPages'
import { CreateAccount } from '../user/createAccount'
import { Toasty } from '../public/toast'
import { AccountAccess } from '../user/accountAccess'
import { ProductCategories } from '../account/productCategory/productCategories'
import { AccountCustomer } from '../customer/accountCustomer'
import { EmailHome } from '../email/emailHome'
import { UserProfile } from '../user/userProfile'

import { OrderHistory } from '../order/history'
import { Coupons } from '../account/coupons';
import { CouponDetail } from '../account/couponDetail'
import { AccountSettingsHome } from '../user/accoutSettingsHome'
import { ViewReports } from '../account/viewReports'
import { ViewDailySnapshotReport } from '../account/viewDailySnapshotReport'
import { Ingredients } from '../ingredients/ingredients'
import { ProductHome } from '../products/productHome'
import { HelpPage } from '../account/helpPage';
import helpType from '../../enums/helpType';
import { BigoUser } from '../bigo/user';
import { FamilyManager } from '../bigo/family';
import { AgencyManager } from '../bigo/agency';
import { getBuildNumber } from '../../api/auth/auth';
import { handleErrors } from '../../api/base';
import { AgencyTracking } from '../bigo/agencyTracking';
import { BigoHelp } from '../bigo/bigoHelp';
import { AgencyDashboard } from '../bigo/agencyDashboard';
import { DiscordSettings } from '../bigo/discordSettings';
import { FamilySignUps } from '../bigo/familySignUps';
import { AgencySignUps } from '../bigo/agencySignUps';
import { AgencyHistory } from '../bigo/agencyHistory';

export const BigoHome = () => {

    const isSmall = useMediaQuery('(max-width:525px)')
    const isShort = useMediaQuery('(max-height:500px)')
    const tokenInfo = getTokensSyncNoUpdate()

    const accountSelectedPageLocation = 'ACCOUNT_SELECTED_PAGE'

    const storedPage = sessionStorage.getItem(accountSelectedPageLocation)

    // TODO : Convert to Enum
    const availablePages = [userPages.BigoUser, userPages.BigoFamily, userPages.BigoAgency]

    const [selectedPage, setSelectedPage] = useState(availablePages[0]) 
    const [selectedSubPage, setSelectedSubPage] = useState('')   
    const [toasts, setToasts] = useState([])
    
    const [user, setUser] = useState(null)
    const [sub, setSub] = useState(null)
    const [buildNumber, setBuildNumber] = useState(null)

    const frontBuild = (process.env.REACT_APP_BUILDNO && Number(process.env.REACT_APP_BUILDNO)) || 0

    const commonProps = {
        user: user,
        setUser: setUser,
        sub: sub,
        setSub: setSub
    }

    const notify = (title, msg) => {
        let tsts = toasts.map(x => x)
        const time = new Date()
        tsts.push({
            title: title,
            message: msg,
            key: time.toLocaleTimeString()
        })
        setToasts(tsts)
        return tsts
    }

    /* eslint-disable */
    useEffect(() => {
        const loadBuild = async () => {
            try{
                const response = await getBuildNumber()
                const result = await handleErrors(response)
                setBuildNumber(result)
            }
            catch (error){
                let finalError = error
                try{
                    finalError = await error
                }
                catch(err){}
                console.log(finalError)
                notify('Error', finalError.toString())
            }
        }

        if (!buildNumber){
            loadBuild()
        }
    }, [])
    /* eslint-enable */

    const getTopPadding = () => {
        if (isShort){
            return '10vh'
        }
        if (selectedPage === userPages.Emails){
            return '6vh'
        }
        return '8vh'
    }

    const setSelectedPageWithMemory = (newPage, newSubPage) => {
        // sessionStorage.setItem(accountSelectedPageLocation, newPage)
            setSelectedPage(newPage)
            setSelectedSubPage('')
    }
    
    return (
        <div style={{overflow: 'hidden'}}>
            <div>
                <TopNav setSelectedPage={setSelectedPageWithMemory} overrideName="BIGO F.A.M."  />
            </div>
            <div style={{display: 'flex'}}>
                 <div style={{width: '15vw', height: '100vh', backgroundColor: '#eeeeee'}}> 
                    <LeftNav props={{setSelectedPage: setSelectedPageWithMemory, selectedPage, sub, user, buildNumber, frontBuild, setSelectedSubPage, selectedSubPage}}  />
                </div>
                <div style={{width: '100%', height: '100vh', backgroundColor: '#e4e5e8', paddingTop: getTopPadding(), paddingLeft: isSmall ? '4vw' : '0vw', overflowY: 'auto'}}>
                    {<Fragment>
                        {!selectedSubPage && selectedPage === userPages.BigoUser && <BigoUser notify={notify} {...commonProps} />}
                        {selectedPage === userPages.BigoUser && selectedSubPage === userPages.BigoDiscordSettings && <DiscordSettings notify={notify} {...commonProps} />}

                        {!selectedSubPage && selectedPage === userPages.BigoFamily && <FamilyManager notify={notify} {...commonProps} />}
                        {selectedPage === userPages.BigoFamily && selectedSubPage === userPages.BigoFamilySignUps && <FamilySignUps notify={notify} {...commonProps} />}

                        {!selectedSubPage && selectedPage === userPages.BigoAgency && <AgencyManager notify={notify} {...commonProps} />}
                        {selectedPage === userPages.BigoAgency && selectedSubPage === userPages.BigoAgencyTracking && <AgencyTracking notify={notify} {...commonProps} />}
                        {selectedPage === userPages.BigoAgency && selectedSubPage === userPages.BigoAgencyDashboard && <AgencyDashboard notify={notify} {...commonProps} />}
                        {selectedPage === userPages.BigoAgency && selectedSubPage === userPages.BigoAgencySignUps && <AgencySignUps notify={notify} {...commonProps} />}
                        {selectedPage === userPages.BigoAgency && selectedSubPage === userPages.BigoAgencyHistory && <AgencyHistory notify={notify} {...commonProps} />}
                        
                        {selectedPage === userPages.BigoHelp && <BigoHelp notify={notify} {...commonProps} />}
                    </Fragment>}
                </div>
            </div>
            <div style={{position: 'fixed', top: '8vh', right: '20px'}}>
                <Toasty toasts={toasts} setToasts={setToasts} />
            </div>
        </div>
    )
}