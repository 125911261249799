import react, {useState, useEffect, Fragment, useMemo} from 'react'
import { Row, Col, Table, Form, Modal, InputGroup, Button } from 'react-bootstrap'
import { Card, CircularProgress, Checkbox, useMediaQuery } from '@material-ui/core'
import { getTokensSyncNoUpdate } from '../../api/auth/session'
import { handleErrors } from "../../api/base"
import EditIcon from '@material-ui/icons/Edit'
import { ContentCopy as CopyIcon } from '@mui/icons-material'
import userFlags from "../../enums/userFlags"
import { getFamilySignUpsAPI, updateFamilySignUpsAPI } from '../../api/bigo/familysignupsapi'
import { makeStyles } from "@material-ui/styles"
import { copyTextToClipboard } from '../../utility/commands'

const useStyles = makeStyles({
    colLabel: {
        fontWeight: 'bold'
    },
    centerCol: {
        display: 'flex',
        justifyContent: 'center',
    },
})

export const FamilySignUps = ({notify, sub, user}) => {

    const carrierLocation = 'BIGO_CARRIERS'

    const [loading, setLoading] = useState(true)
    const [signUpInfo, setSignUpInfo] = useState(null)
    const [carriers, setCarriers] = useState([])
    const tokenInfo = getTokensSyncNoUpdate()
    const classes = useStyles()
    const isSmall = useMediaQuery('(max-width:650px)')
    const isLarge = useMediaQuery('(min-width:1200px)')
    const isXLarge = useMediaQuery('(min-width:1500px)')
    const userHasPremiumSMS = () => Boolean(user && (user.Flags & userFlags.PremiumSMS))
    const [editId, setEditId] = useState(null)
    const [modalLoading, setModalLoading] = useState(false)
    const [statusFilter, setStatusFilter] = useState(0)

    const dateFormatter = new Intl.DateTimeFormat('en-US', {
        // hour12: true,
        // hour: 'numeric',
        // minute: 'numeric',
        // timeZoneName: 'short',
        month: 'short',
        year: '2-digit',
        day: 'numeric'
    })

    useEffect(() => {
        const loadInfo = async () => {
            await reloadSignUp()
        }

        loadInfo()
        const cachedCarrier = JSON.parse(localStorage.getItem(carrierLocation))
        if (cachedCarrier && cachedCarrier.Carriers){
            setCarriers(cachedCarrier.Carriers)
        }
    }, [])

    const reloadSignUp = async () => {
        try{
            const response = await getFamilySignUpsAPI(tokenInfo.IdToken, tokenInfo.AccessToken)
            const result = await handleErrors(response)
            result && setSignUpInfo(result)
            console.log(result)
            setLoading(false)
        }
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
    }

    const handleCopyClick = () => {
        copyTextToClipboard(signUpInfo && `${window.location.origin}/family/${signUpInfo.Code}` || '')
        notify('Copied!', 'Family Self Signup Link Has Been Copied')
    }

    const getStatusName = (s) => {
        switch (Number(s)){
            case 0: return 'Submitted'
            case 1: return 'Approved'
            case 2: return 'Rejected'
        }
        return ''
    }

    const getSignUpDetail = (id) => {
        if (!id) { 
            return {
                Status: -1
            }
        }
        return signUpInfo.SignUps.filter(x => x.SignUpId === id)[0]
    }

    const updateSignUpStatus = async (s) => {
        try{
            const request = {
                SignUpId: editId,
                NewStatus: s
            }
            setModalLoading(true)
            const response = await updateFamilySignUpsAPI(tokenInfo.IdToken, tokenInfo.AccessToken, request)
            const result = await handleErrors(response)
            if (result){
                setModalLoading(false)
                setEditId(null)
                await reloadSignUp()
            }
            else{
                setModalLoading(false)
            }
        }
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
            setModalLoading(false)
        }
    }

    const filteredData = useMemo(() => {
        if (!signUpInfo){
            return []
        }
        return signUpInfo.SignUps.filter(x => statusFilter === -1 || x.Status === statusFilter)
    }, [signUpInfo, statusFilter])
    
    return <Fragment>
        <Row>
            <Col>
                <h2>
                    Family Sign Ups
                </h2>
            </Col>
            
            {/* <Col xs={6} lg={4} xl={3}>
                <Form.Control as="select" value={selectedFamily} onChange={(e) => {setSelectedFamily(Number(e.target.value)); reloadDays(e.target.value)}}>
                    <option value={0}>{(user && user.FamilyName) || ''}</option>
                    {user && user.AdditionalFamilies && user.AdditionalFamilies.map(x => {
                        return <option value={x.FamilyId} key={x.FamilyId}>{x.Name}</option>
                    })}
                </Form.Control>
            </Col> */}
        </Row>

        <hr />

        <Row style={{marginTop: '5px', height: '85%', width: '100%'}}>
            <div style={{width: '100%'}}>
                {loading
                ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%', width: '100%' }}>
                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                </div>
                : <Fragment>
                    <Modal show={editId > 0} onHide={() => setEditId(null)} centered>
                        <Modal.Header closeButton>
                            <h3>Edit Status</h3>
                        </Modal.Header>
                        <Modal.Body>
                            {modalLoading
                                ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%', width: '100%' }}>
                                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                                </div>
                                : <Row>
                                    {getSignUpDetail(editId).Status === 0 && <Col className={classes.centerCol}>
                                        <Button variant="success" onClick={() => updateSignUpStatus(1)}>Approve</Button>
                                    </Col>}
                                    {getSignUpDetail(editId).Status === 0 && <Col className={classes.centerCol}>
                                        <Button variant="danger" onClick={() => updateSignUpStatus(2)}>Reject</Button>
                                    </Col>}
                                    {getSignUpDetail(editId).Status === 2 && <Col className={classes.centerCol}>
                                        <Button variant="warning" onClick={() => updateSignUpStatus(0)}>Reset</Button>
                                    </Col>}
                                </Row>
                            }
                            
                        </Modal.Body>
                        <Modal.Footer />
                    </Modal>
                    <Form.Group as={Row}>
                        <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={2} md={2}>
                            Sign Up Link
                        </Form.Label>
                        <Col md={4}>
                            <InputGroup>
                                <Form.Control value={signUpInfo && `${window.location.origin}/family/${signUpInfo.Code}` || ''} readOnly={true} />
                                <InputGroup.Text onClick={handleCopyClick}>
                                    <CopyIcon style={{cursor: 'pointer'}} />
                                </InputGroup.Text>
                            </InputGroup>
                            
                        </Col>
                        <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                            Status
                        </Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            <Form.Control as="select" value={statusFilter} onChange={(e) => {setStatusFilter(Number(e.target.value))}}>
                                <option value={-1}>All</option>
                                <option value={0}>Submitted</option>
                                <option value={1}>Approved</option>
                                <option value={2}>Rejected</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Row>
                        <Col xs={12}>
                            <Card style={{paddingTop: '10px', paddingBottom: '10px', marginBottom: '15px'}}>
                                <h3 style={{textAlign: 'center'}}>Family Requests</h3>
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th style={{minWidth: '100px'}}>BIGO ID</th>
                                            <th>Phone</th>
                                            {!userHasPremiumSMS() && <th>Carrier</th>}
                                            <th>Country</th>
                                            <th>Timezone</th>
                                            <th>DST</th>
                                            <th>No Texts</th>
                                            <th>Discord</th>
                                            <th>Status</th>
                                            <th>Request</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        {signUpInfo && signUpInfo.SignUps && filteredData.map(x => {
                                            const carrier = carriers && carriers.filter(y => y.Id === x.CarrierId)[0]
                                            return <tr key={x.SignUpId}>
                                                <td>{x.UserName}</td>
                                                <td>{x.Phone || ''}</td>
                                                {!userHasPremiumSMS() && <td>{(carrier && carrier.Name) || 'Unknown'}</td>}
                                                <td>{x.CountryCode}</td>
                                                <td>{x.TimezoneOffset ? 'GMT' + x.TimezoneOffset : 'UTC'}</td>
                                                <td><Checkbox checked={x.ObservesDST} readOnly={true} color="primary" /></td>
                                                <td><Checkbox checked={x.NoTexts} readOnly={true} color="primary" /></td>
                                                <td><Checkbox checked={x.HasDiscordId} readOnly={true} color="primary" /></td>
                                                <td>{getStatusName(x.Status)}</td>
                                                <td>{dateFormatter.format(new Date(x.Submission))}</td>
                                                <td>{dateFormatter.format(new Date(x.StatusDate))}</td>
                                                <td>{x.Status !== 1 && <EditIcon style={{cursor: 'pointer'}} onClick={() => setEditId(x.SignUpId)} />}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                </Fragment>
                }
            </div>
        </Row>
    </Fragment>
}