import react, { useState, useEffect, Fragment } from 'react'
import { Button, Form, Modal, Row, Table, Col } from 'react-bootstrap'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline'
import { updatePremiumSMS } from '../../api/bigo/userapi'
import { handleErrors } from '../../api/base'
import { Autocomplete, TextField } from '@mui/material'
import { getTokensSyncNoUpdate, setTokens } from '../../api/auth/session'
import { CircularProgress, useMediaQuery } from '@material-ui/core'
import { makeStyles } from "@material-ui/styles"
import { utcStringToLocal } from '../../utility/dateHelper'
import userFlags from '../../enums/userFlags'

const useStyles = makeStyles({
    colLabel: {
        fontWeight: 'bold'
    }
})

export const PremiumSMS = ({notify, show, onHide, user, reloadSub, reloadUser}) => {

    const [loading, setLoading] = useState(false)
    const tokenInfo = getTokensSyncNoUpdate()
    const classes = useStyles()
    const isSmall = useMediaQuery('(max-width:650px)')
    const isLarge = useMediaQuery('(min-width:1200px)')
    const isXLarge = useMediaQuery('(min-width:1500px)')


    useEffect(() => {
        
        if (!show){
            return
        }

    }, [show])

    const enableSMS = async () => {
        setLoading(true)
        try{
            const response = await updatePremiumSMS(tokenInfo.IdToken, tokenInfo.AccessToken, true)
            const result = await handleErrors(response)
            
            await reloadSub()
            await reloadUser()

            onHide()
        }
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
        setLoading(false)
    }

    const disableSMS = async () => {
        setLoading(true)
        try{
            const response = await updatePremiumSMS(tokenInfo.IdToken, tokenInfo.AccessToken, false)
            const result = await handleErrors(response)
            
            await reloadSub()
            await reloadUser()

            onHide()
        }
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
        setLoading(false)
    }



    return <Modal show={show} onHide={onHide} size='lg' backdrop='static' centered>
        <Modal.Header closeButton>
            <h3>Premium SMS</h3>
        </Modal.Header>
        <Modal.Body>
            {loading
                ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%', width: '100%' }}>
                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                </div>
                : <Fragment>
                    <div>
                        <h4>What is Premium SMS?</h4>
                        <div style={{marginBottom: '20px'}}>
                            <ul>
                                <li>
                                    Without Premium SMS alerts will go to the mapped user's repsective carrier Email Gateway which will then be relayed 
                                    to the user's phone as a text. Every carrier handles this a little differently. The message can be slightly delayed, comes from a 
                                    seemingly random phone number, might show up as an attachment, and if they change carriers they wont receive texts until you update their mapping.
                                </li>
                                <li style={{marginTop: '10px'}}>
                                    With Premium SMS we send "real" text messages from a predictable US phone number (916) 496-3993 that will quickly deliver 
                                    a text message to the user's phone that will look the same regardless of what carrier they are using. If the user change carriers
                                    you dont have to update the carrier in the mapping with Premium SMS. This is the ideal way of sending text alerts, but is more expensive.
                                    We could bake this into the monthly price for everyone, but want to give you the flexibility to manage your budget.
                                </li>
                            </ul>
                        </div>
                        
                        <h4>What does Premium SMS cost?</h4>
                        <div style={{marginBottom: '20px'}}>
                            <ul>
                                <li>
                                    Our price to send Premium SMS texts varies based on the amount of alerts that we send out. As such right now we are offering this service at 
                                    <b> $20 USD per month</b> which will be added onto your existing subscription. This can be disabled at any time, and we reserve the right to
                                    increase the cost of this service at any time. Once you add Premium SMS you will be locked in at the current price until you
                                    cancel your subscription or disable Premium SMS. Disabling mid billing cycle will grant a prorated credit that will be applied on your next invoice.
                                </li>
                            </ul>
                        </div>
                    </div>
                </Fragment>}
        </Modal.Body>
        <Modal.Footer>
            {user && (user.Flags & userFlags.PremiumSMS) 
                ? <Button variant="danger" onClick={disableSMS}>Disable Premium SMS</Button>
                : <Button variant="success" onClick={enableSMS}>Purchase Premium SMS</Button>
            }
        </Modal.Footer>
    </Modal>
}