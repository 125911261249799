
export const userLinkURL = 'https://discord.com/api/oauth2/authorize?client_id=966132513426767902&redirect_uri=https%3A%2F%2Fwww.bigofam.com/discord&response_type=code&scope=identify%20connections%20guilds'

export const botLinkURL = 'https://discord.com/api/oauth2/authorize?client_id=966132513426767902&permissions=274878024768&scope=bot'

export const discordTokenLocation = 'BIGO_DISCORD_TOKEN'

export const discordFlags = {
    None: 0,
    FamilyImages: 1,
    FamilyReminders: 2,
    AgencyImages: 4,
    AgencyReminders: 8,
    AgencyIncludeFamily: 16,
    FamilyIncludeTags: 32,
    AgencyIncludeTags: 64,
}