import {useState, useEffect, Fragment, useMemo} from 'react'
import { Row, Col, Table, Form, Modal } from 'react-bootstrap'
import { Card, CircularProgress, Checkbox, TextField, Tabs, Tab, FormControlLabel } from '@mui/material'
import { getTokensSyncNoUpdate } from '../../api/auth/session'

import { getCarriersAPI } from "../../api/bigo/userapi"
import { handleErrors } from "../../api/base"
import { getAgencyEventsAPI, getAgencyMappingsAPI, updateAgencyMappingAPI,
     removeAgencyMappingAPI, getTomorrowAgencyEventsAPI, getTwoDaysAgencyEventsAPI,
     getAgencyEventImageAPI } from '../../api/bigo/agencyapi'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import SaveIcon from '@material-ui/icons/Save'
import AddIcon from '@material-ui/icons/Add'
import CameraIcon from '@material-ui/icons/CameraAlt'
import MaskedInput from 'react-text-mask'
import SettingsIcon from '@material-ui/icons/Settings'
import { TimeZonePicker } from "./components/timezone"
import { Autocomplete } from "@material-ui/lab"
import { mobileCheck } from '../../utility/deviceHelper'
import userStatus from '../../enums/userStatus'
import { realPlans as testPlans} from '../../enums/bigoPlans/defaultPlan'
import { CountrySelector } from './components/countrySelector'
import userFlags from "../../enums/userFlags"
import { ImageColumns, getFlagsFromStorage, setFlagsToStorage } from './components/imageColumns'

function a11yProps(index) {
    return {
      id: `agency-tab-${index}`,
      'aria-controls': `agency-tabpanel-${index}`,
    };
  }

export const AgencyManager = ({notify, sub, user}) => {

    const [loading, setLoading] = useState(true)
    const [carriers, setCarriers] = useState([])
    const tokenInfo = getTokensSyncNoUpdate()
    const [famEvents, setFamEvents] = useState(null)
    const [tomorrowEvents, setTomorrowEvents] = useState(null)
    const [twoDayEvents, setTwoDayEvents] = useState(null)
    const [famMappings, setFamMappings] = useState(null)
    const [editMapping, setEditMapping] = useState(null)
    const [newMapping, setNewMapping] = useState(null)
    const [tabVal, setTabVal] = useState(0)
    const [imgModal, setImgModal] = useState(null)
    const [showFamily, setShowFamily] = useState(false)
    const [eventLoading, setEventLoading] = useState(false)
    const [showImgCols, setShowImgCols] = useState(null)
    const [famCols, setFamCols] = useState(null)
    const [mapSearch, setMapSearch] = useState('')
    
    const carrierLocation = 'BIGO_CARRIERS'
    const withFamilyLocation = 'AGENCY_WITH_FAMILY'
    const familyImageColsLocation = 'BIGO_AGENCY_IMG_COLS'

    const dateFormatter = new Intl.DateTimeFormat('en-US', {
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short'
    })

    useEffect(() => {
        const loadCarriers = async () => {
            try{
                let carrierInfo = localStorage.getItem(carrierLocation)
                if (carrierInfo){
                    carrierInfo = JSON.parse(carrierInfo)
                    if (carrierInfo.Expiration < new Date()){
                        carrierInfo = null
                    }
                }
                if (!carrierInfo){
                    const response = await getCarriersAPI(tokenInfo.IdToken, tokenInfo.AccessToken)
                    const result = await handleErrors(response)
                    
                    let sevenDays = new Date()
                    sevenDays.setDate(sevenDays.getDate() + 7)
                    carrierInfo = {
                        Carriers: result,
                        Expiration: sevenDays
                    }
                    localStorage.setItem(carrierLocation, JSON.stringify(carrierInfo))
                }
                setCarriers(carrierInfo.Carriers)
            }
            catch (error){
                let finalError = error
                try{
                    finalError = await error
                }
                catch(err){}
                console.log(finalError)
                notify('Error', finalError.toString())
            }
        }

        const loadMappings = async (savedWithFamily) => {
            try{
                const response = await getAgencyMappingsAPI(tokenInfo.IdToken, tokenInfo.AccessToken)
                const result = await handleErrors(response)
                setFamMappings(result)

                await reloadEvents(result, savedWithFamily)
            }            
            catch (error){
                let finalError = error
                try{
                    finalError = await error
                }
                catch(err){}
                console.log(finalError)
                notify('Error', finalError.toString())
                setFamMappings([])
                setFamEvents([])
            }
            
            setLoading(false)
        }

        let savedWithFamily = false
        if (hasFamilyOnSub()){
            try{
                savedWithFamily = Boolean(JSON.parse(localStorage.getItem(withFamilyLocation)))
                setShowFamily(savedWithFamily)
            }
            catch (error){
                setShowFamily(false)
            }
        }
        else{
            setShowFamily(false)
        }
        
        loadCarriers()
        loadMappings(savedWithFamily)
        setFamCols(getFlagsFromStorage(familyImageColsLocation))
    }, [user, sub])

    const hasFamilyOnSub = () => {
        if (user && sub && sub.UserStatus === userStatus.Paid && sub.SubscriptionId){
            const matchPlan = testPlans.filter(x => x.StripeId === sub.PlanId)[0]
            if (matchPlan){
                return matchPlan.Family !== 0 && matchPlan.Agency !== 0
            }
            else if (sub.SubscriptionId === 'MASTER'){
                return true
            }
            else{
                return false
            }
        }
        else{
            return false
        }
    }

    const reloadEvents = async (ovrMaps, ovrWithFam) => {
        setEventLoading(true)
        const withFam = ovrWithFam ?? showFamily
        let response, result
        try{
            response = await getAgencyEventsAPI(tokenInfo.IdToken, tokenInfo.AccessToken, withFam)
            result = await handleErrors(response)
            setFamEvents(parseEvent(result, ovrMaps))
        }            
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
            setFamEvents([])
        }

        try{

            response = await getTomorrowAgencyEventsAPI(tokenInfo.IdToken, tokenInfo.AccessToken, withFam)
            result = await handleErrors(response)
            setTomorrowEvents(parseEvent(result, ovrMaps))
        }            
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
            setTomorrowEvents([])
        }

        try{
            response = await getTwoDaysAgencyEventsAPI(tokenInfo.IdToken, tokenInfo.AccessToken, withFam)
            result = await handleErrors(response)
            setTwoDayEvents(parseEvent(result, ovrMaps))
        }            
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
            setTwoDayEvents([])
        }
        setEventLoading(false)
    }

    const parseEvent = (e, ovrMaps) => {
        const final = []
        const finalMaps = ovrMaps || famMappings
        const agencyUserNames = finalMaps.map(x => x.UserName.toUpperCase())
        e.forEach(x => {
            x.EventPairs.forEach(y => {            
                let famUser, famOpp
                if (agencyUserNames.includes(y.User1.User.toUpperCase())){
                    famUser = y.User1
                    famOpp = y.User2
                }
                else{                    
                    famUser = y.User2
                    famOpp = y.User1
                }

                const rawTime = new Date(famUser.Time + ' UTC')
                final.push({
                    time: dateFormatter.format(rawTime),
                    rawTime: rawTime,
                    name: famUser.User,
                    eventName: famUser.EventDetail || x.EventName,
                    opponent: (famOpp && famOpp.User) || '',
                    reward: x.Rewards,
                    family: famUser.Family,
                    type: x.EventType
                })

                // Same Agency PK
                if (famOpp && famOpp.User && agencyUserNames.includes(y.User1.User.toUpperCase()) && agencyUserNames.includes(y.User2.User.toUpperCase())){
                    final.push({
                        time: dateFormatter.format(rawTime),
                        rawTime: rawTime,
                        name: famOpp.User,
                        eventName: famOpp.EventDetail || x.EventName,
                        opponent: (famUser && famUser.User) || '',
                        reward: x.Rewards,
                        family: famOpp.Family,
                        type: x.EventType
                    })
                }
            })
        })
        
        return final.sort((a, b) => a.rawTime > b.rawTime ? 1 : -1)
    }

    const updateMappingProp = (val, field) => {
        const clone = Object.assign({}, editMapping)
        clone[field] = val
        setEditMapping(clone)
    }

    const updateNewMappingProp = (val, field) => {
        const clone = Object.assign({}, newMapping)
        clone[field] = val
        setNewMapping(clone)
    }

    const saveMapping = async () => {
        const request = Object.assign({}, editMapping)
        if (!request.TimezoneOffset){
            request.TimezoneOffset = null
        }
        request.CarrierId = Number(request.CarrierId) || null

        if (!request.NoTexts && (!request.Phone || !request.Phone.length || request.Phone.length !== 14)){
            notify('Invalid Phone', 'Invalid Phone No Length')
            return
        }
        if (!userHasPremiumSMS() && !request.NoTexts && !request.CarrierId){
            notify('Carrier Required', 'You must select a phone carrier')
            return
        }

        
        setLoading(true)
        try{
            const response = await updateAgencyMappingAPI(tokenInfo.IdToken, tokenInfo.AccessToken, request)
            const result = await handleErrors(response)
            if (result){
                const clone = famMappings.map(x => {
                    if (x.UserName === request.UserName){
                        return Object.assign({}, editMapping)
                    }
                    return Object.assign({}, x)
                })
                setFamMappings(clone)
                setEditMapping(null)
            }
        }         
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }    
        setLoading(false)    
    }

    const saveNewMapping = async () => {
        const request = Object.assign({}, newMapping)
        if (!request.TimezoneOffset){
            request.TimezoneOffset = null
        }
        request.CarrierId = Number(request.CarrierId) || null

        if (!request.NoTexts && (!request.Phone || !request.Phone.length || request.Phone.length !== 14)){
            notify('Invalid Phone', 'Invalid Phone No Length')
            return
        }
        if (!userHasPremiumSMS() && !request.NoTexts && !request.CarrierId){
            notify('Carrier Required', 'You must select a phone carrier')
            return
        }

        
        setLoading(true)
        try{
            const response = await updateAgencyMappingAPI(tokenInfo.IdToken, tokenInfo.AccessToken, request)
            const result = await handleErrors(response)
            if (result){
                const clone = famMappings.map(x => {
                    return Object.assign({}, x)
                })
                clone.push(request)
                setFamMappings(clone)
                setNewMapping(null)
            }
        }         
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }    
        setLoading(false)    
    }

    const deleteMapping = async () => {
        const request = {
            UserName: editMapping.UserName
        }

        
        setLoading(true)
        try{
            const response = await removeAgencyMappingAPI(tokenInfo.IdToken, tokenInfo.AccessToken, request)
            const result = await handleErrors(response)
            if (result){
                const clone = famMappings.filter(x => x.UserName !== editMapping.UserName).map(x => Object.assign({}, x))
                setFamMappings(clone)
                setEditMapping(null)
            }
        }         
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        } 
        setLoading(false)
    }

    const addMapping = (prefill) => {
        const newRecord = {
            UserName: prefill || '',
            CarrierId: 0,
            Phone: '',
            TimezoneOffset: null,
            ObservesDST: false,
            NoTexts: false,
            CountryCode: 'USA'
        }

        setNewMapping(newRecord)
    }

    const getTabData = (tabIdx, today, tomorrow, twoDays) => {
        switch (tabIdx){
            case 0:
                return today || []
            case 1:
                return tomorrow || []
            case 2:
                return twoDays || []
            default:
                return []
        }
    }

    const getTabName = (tabIdx) => {
        switch (tabIdx){
            case 0:
                return 'Events Today'
            case 1:
                return "Events Tomorrow"
            case 2:
                return "Events In 2 Days"
            default:
                return ''
        }
    }

    const getImage = (tabIdx) => {
        let dayName = ''
        switch (tabIdx){
            case 0:
                dayName = 'today'
                break
            case 1:
                dayName = 'tomorrow'
                break
            case 2:
                dayName = 'twodays'
                break;
            default:
                break
        }

        getAgencyEventImageAPI(tokenInfo.IdToken, tokenInfo.AccessToken, dayName, showFamily, famCols)
            .then(response => {
                if (response.status === 500){
                    notify('Error', 'Error Loading Image')
                }
                else if (response.status === 400){
                    notify('No Data', 'No Records To Display')
                }
                else{
                    return response.blob()
                }
            })
            .then(blob => {
                if (!blob){
                    return
                }
                const url = window.URL.createObjectURL(blob)
                if (mobileCheck()){
                    setImgModal(url)
                }
                else {
                    let a = document.createElement('a')
                    a.href = url
                    a.download = dayName + '.png'
                    document.body.appendChild(a)
                    a.click()
                    a.remove()
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const searchedMappings = useMemo(() => {
        if (!mapSearch){
            return famMappings
        }
        const searchUpper = mapSearch.toUpperCase()
        return famMappings.filter(x => x.UserName.toUpperCase().includes(searchUpper))
    }, [mapSearch, famMappings])

    const userHasPremiumSMS = () => Boolean(user && (user.Flags & userFlags.PremiumSMS))

    return <Fragment>
    <Row>
        <Col xs={12} lg> 
            <h2>
                Agency Management
            </h2>
        </Col>
        {hasFamilyOnSub() && <Col xs={12} lg={4} xl={3}>
            <Row>
                <Form.Group>
                    <FormControlLabel
                        label="Show Family PKs"
                        control={<Checkbox 
                            checked={showFamily} 
                            onChange={(e) => {
                                setShowFamily(e.target.checked)
                                reloadEvents(null, e.target.checked)
                                localStorage.setItem(withFamilyLocation, JSON.stringify(e.target.checked))
                            }}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} 
                        />}
                        sx={{' .MuiFormControlLabel-label': { fontSize: 24 } }}
                    />
                </Form.Group>
            </Row>
        </Col>}
    </Row>

    <hr />
    
    <Row style={{marginTop: '5px', height: '85%'}}>
        <Modal show={Boolean(imgModal)} onHide={() => setImgModal(null)} centered>
            <Modal.Header closeButton>
                <h3>Save Image</h3>
            </Modal.Header>
            <Modal.Body style={{overflow: 'hidden'}}>
                <img src={imgModal} alt="Lineup" />
            </Modal.Body>
        </Modal>
        {loading || !user || !sub || !carriers
        ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%', width: '100%' }}>
                <CircularProgress style={{ width: '100px', height: '100px' }} />
            </div>
        )
        : (
            <div style={{width: '100%'}}>
                <Row>
                    <Col xs={12}>
                        <Card style={{paddingTop: '10px', paddingBottom: '10px', marginBottom: '15px'}}>
                            <Tabs
                                value={tabVal}
                                onChange={(e, n) => setTabVal(n)}
                                indicatorColor="primary"
                                textColor="inherit"
                                centered
                            >
                                <Tab label="Today" {...a11yProps(0)} />                                
                                <Tab label="Tomorrow" {...a11yProps(1)} />
                                <Tab label="Two Days" {...a11yProps(2)} />
                            </Tabs>

                            <h3 style={{textAlign: 'center'}}>
                                {getTabName(tabVal)}
                                {!eventLoading && <CameraIcon
                                    style={{marginLeft: '20px', cursor: 'pointer'}}
                                    onClick={() => getImage(tabVal)}
                                />}
                                <SettingsIcon
                                    style={{marginLeft: '20px', cursor: 'pointer'}}
                                    onClick={(e) => setShowImgCols(e.target)}
                                />
                                <ImageColumns
                                    id='Agency-Cols'
                                    el={showImgCols}
                                    close={(flags) => {
                                        setShowImgCols(null)
                                        setFamCols(flags)
                                        setFlagsToStorage(familyImageColsLocation, flags)
                                    }}
                                    startingFlags={famCols}
                                    showFamily={hasFamilyOnSub()}
                                />
                            </h3>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Time</th>
                                        <th>BIGO ID</th>
                                        <th>Event</th>
                                        <th>Details</th>
                                        <th>Opponent</th>
                                        {showFamily && <th>Family</th>}
                                        <th>Reward</th>
                                    </tr>
                                </thead>
                                {!eventLoading && <tbody>
                                    {getTabData(tabVal, famEvents, tomorrowEvents, twoDayEvents).map(x => {
                                        return <tr>
                                            <td>{x.time}</td>
                                            <td>{x.name}</td>
                                            <td>{x.eventName}</td>
                                            <td>{x.type}</td>
                                            <td>{x.opponent}</td>
                                            {showFamily && <td>{x.family || 'Agency'}</td>}
                                            <td>{x.reward}</td>
                                        </tr>
                                    })}
                                </tbody>}
                            </Table>
                            {eventLoading && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%', width: '100%' }}>
                                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                                </div>
                            )}
                        </Card>
                    </Col>
                    <Col xs={12}>
                        <Card style={{paddingTop: '10px', paddingBottom: '10px', marginBottom: '15px'}}>
                            <div style={{display: 'flex', justifyContent: 'center', position: 'relative', width: '100%', marginBottom: '20px'}}>
                                <TextField id="AgencySearch" label="Search" style={{position: 'absolute', left: '20px'}} autoComplete="new-password" value={mapSearch} onChange={(e) => setMapSearch(e.target.value)} />
                                <h3 style={{textAlign: 'center'}}>User Mappings</h3>
                            </div>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th style={{minWidth: '200px'}}>BIGO ID</th>
                                        <th>Phone</th>
                                        {!userHasPremiumSMS() && <th>Carrier</th>}
                                        <th>Country</th>
                                        <th>Timezone</th>
                                        <th>Observes DST</th>
                                        <th>No Texts</th>
                                        <th>Discord</th>
                                        <th><AddIcon onClick={() => addMapping()} /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {newMapping && (
                                        <tr>
                                            <td>
                                                <Autocomplete
                                                    id="new-agency-mapping-id"
                                                    options={[]}
                                                    freeSolo
                                                    inputValue={newMapping.UserName}
                                                    defaultValue={newMapping.UserName}
                                                    onInputChange={(e, v) => updateNewMappingProp(v, 'UserName')}
                                                    renderInput={(params) => <TextField {...params} maxLength={200} variant="outlined" />}
                                                />
                                            </td>
                                            <td>
                                                <MaskedInput
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className="form-control"
                                                    guide={false}
                                                    onChange={(e) => updateNewMappingProp(e.target.value, 'Phone')}
                                                    required
                                                    type="tel"
                                                    value={newMapping.Phone || ''}
                                                />
                                            </td>
                                            {!userHasPremiumSMS() && <td>
                                                <Form.Control as="select" value={newMapping.CarrierId} maxLength={200} onChange={(e) => updateNewMappingProp(e.target.value, 'CarrierId')}>
                                                    <option value={0}>-- Select --</option>
                                                    {carriers.map(y => {
                                                        return <option value={y.Id} key={y.Id}>{y.Name}</option>
                                                    })}
                                                </Form.Control>
                                            </td>}
                                            <td>
                                                <CountrySelector getter={newMapping.CountryCode || ''} setter={(e) => updateNewMappingProp(e.target.value, 'CountryCode')} />
                                            </td>
                                            <td>
                                                <TimeZonePicker getter={newMapping.TimezoneOffset || 0} setter={(e) => updateNewMappingProp(e.target.value, 'TimezoneOffset')} />
                                            </td>
                                            <td>                                                    
                                                <Checkbox checked={newMapping.ObservesDST} onChange={(e) => updateNewMappingProp(e.target.checked, 'ObservesDST')} />       
                                            </td>
                                            <td>
                                                <Checkbox checked={newMapping.NoTexts} onChange={(e) => updateNewMappingProp(e.target.checked, 'NoTexts')} />
                                            </td>
                                            <td>
                                                &nbsp;
                                            </td>
                                            <td>
                                                <div style={{display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                                                    <SaveIcon onClick={saveNewMapping} />
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    {famMappings && searchedMappings && searchedMappings.map(x => {
                                        if (editMapping && x.UserName === editMapping.UserName){
                                            return <tr>
                                                <td>
                                                    <Form.Control value={editMapping.UserName} disabled />
                                                </td>
                                                <td>
                                                    <MaskedInput
                                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                        className="form-control"
                                                        guide={false}
                                                        onChange={(e) => updateMappingProp(e.target.value, 'Phone')}
                                                        required
                                                        type="tel"
                                                        value={editMapping.Phone || ''}
                                                    />
                                                </td>
                                                {!userHasPremiumSMS() && <td>
                                                    <Form.Control as="select" value={editMapping.CarrierId} maxLength={200} onChange={(e) => updateMappingProp(e.target.value, 'CarrierId')}>
                                                        <option value={0}>-- Select --</option>
                                                        {carriers.map(y => {
                                                            return <option value={y.Id} key={y.Id}>{y.Name}</option>
                                                        })}
                                                    </Form.Control>
                                                </td>}
                                                <td>
                                                    <CountrySelector getter={editMapping.CountryCode || ''} setter={(e) => updateMappingProp(e.target.value, 'CountryCode')} />
                                                </td>
                                                <td>
                                                    <TimeZonePicker getter={editMapping.TimezoneOffset || 0} setter={(e) => updateMappingProp(e.target.value, 'TimezoneOffset')} />
                                                </td>
                                                <td>                                                    
                                                    <Checkbox checked={editMapping.ObservesDST} onChange={(e) => updateMappingProp(e.target.checked, 'ObservesDST')} />       
                                                </td>
                                                <td>
                                                    <Checkbox checked={editMapping.NoTexts} onChange={(e) => updateMappingProp(e.target.checked, 'NoTexts')} />
                                                </td>
                                                <td>
                                                    <Checkbox checked={editMapping.HasDiscord} />
                                                </td>
                                                <td>
                                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                        <SaveIcon onClick={saveMapping} style={{marginRight: '10px'}} />
                                                        <DeleteIcon onClick={deleteMapping} />
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                        const carrier = carriers.filter(y => y.Id === x.CarrierId)[0]
                                        return <tr>
                                            <td>{x.UserName}</td>
                                            <td>{x.Phone || ''}</td>
                                            {!userHasPremiumSMS() && <td>{(carrier && carrier.Name) || ''}</td>}
                                            <td>{x.CountryCode || ''}</td>
                                            <td>GMT{!x.TimezoneOffset && '+'}{x.TimezoneOffset || 0}</td>
                                            <td>{Boolean(x.ObservesDST).toString()}</td>                                            
                                            <td>{(!x.Phone && !x.CarrierId).toString()}</td>
                                            <td>
                                                <Checkbox checked={x.HasDiscord} />
                                            </td>
                                            <td>
                                                <EditIcon onClick={() => {
                                                    const clone = Object.assign({}, x);
                                                    clone.NoTexts = !clone.Phone && !clone.CarrierId
                                                    setEditMapping(clone)
                                                }} />
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
            </div>
        )}
    </Row>
</Fragment>
}