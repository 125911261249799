import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Badge } from '@material-ui/core'

const useStyles = makeStyles({
    navTile: {
        backgroundColor:'#eeeeee',
        '&:hover': {
            backgroundColor: 'lightgray'
        }
    },
    selectedNavTile: {
        backgroundColor: 'darkgray',
    },
    subNavTile: {
        backgroundColor:'#eeeeee',
        '&:hover': {
            backgroundColor: 'lightblue'
        }
    },
    subSelectedNavTile: {
        backgroundColor: 'lightskyblue',
    },
    comingSoon: {
        fontWeight: 'normal',
        color: 'gray',
        fontSize: '90%'
    }
})
// TODO: This keeps throwing an error and breaking at 900px
export const LeftNavTab = ({Icon, text, selected, selector, selectKey, count, comingSoon, disabled}) => {
    const isSmall = useMediaQuery('(max-width:900px)')
    const classes = useStyles()
    const isSelected = Array.isArray(selected) ? selected.includes(selectKey) : selectKey === selected
    return (
        <div className={isSelected ? classes.selectedNavTile : classes.navTile} 
            style={{display: 'flex', fontWeight: 'bold',  padding: '15px 10px', cursor: disabled ? 'not-allowed' : 'pointer', alignItems: 'center', color: disabled ? 'gray' : ''}} 
            onClick={() => selector && !disabled && !comingSoon && selector(selectKey)}
        >
            {isSmall
            ? <div style={{textAlign: 'center', width: '100%'}}>
                <Badge color="secondary" badgeContent={count}>
                    <Icon />
                </Badge>
            </div>
            :   <Fragment>
                    {Icon
                        ? <Fragment>
                            <div style={{width: '30%', textAlign: 'center'}}>
                                <Badge color="secondary" badgeContent={count}>
                                    <Icon />
                                </Badge>
                            </div>
                            <div style={{width: '70%', textAlign: 'center', display: 'flex', flexDirection: 'column'}}>
                                {text}
                            {comingSoon && <div className={classes.comingSoon}>Coming Soon!</div>}
                            </div>
                        </Fragment>
                        : <div style={{width: '100%', textAlign: 'center', display: 'flex', flexDirection: 'column'}}>
                            <div>{text}</div>
                            {comingSoon && <div className={classes.comingSoon}>Coming Soon!</div>}
                        </div>
                    }
                </Fragment>
            }
        </div>
    )
}

export const LeftSubNavTab = ({Icon, text, selected, selector, selectKey, count, comingSoon, disabled}) => {
    const isSmall = useMediaQuery('(max-width:900px)')
    const classes = useStyles()
    const isSelected = Array.isArray(selected) ? selected.includes(selectKey) : selectKey === selected
    return (
        <div className={isSelected ? classes.subSelectedNavTile : classes.subNavTile} 
            style={{display: 'flex', fontWeight: 'bold',  padding: '15px 10px', paddingLeft: '30px', cursor: disabled ? 'not-allowed' : 'pointer', alignItems: 'center', color: disabled ? 'gray' : ''}} 
            onClick={() => selector && !disabled && !comingSoon && selector(selectKey)}
        >
            {isSmall
            ? <div style={{textAlign: 'center', width: '100%'}}>
                <Badge style={{color: 'blue'}} badgeContent={count}>
                    <Icon />
                </Badge>
            </div>
            :   <Fragment>
                    {Icon
                        ? <Fragment>
                            <div style={{width: '30%', textAlign: 'center'}}>
                                <Badge style={{color: 'blue'}} badgeContent={count}>
                                    <Icon />
                                </Badge>
                            </div>
                            <div style={{width: '70%', textAlign: 'center', display: 'flex', flexDirection: 'column'}}>
                                {text}
                            {comingSoon && <div className={classes.comingSoon}>Coming Soon!</div>}
                            </div>
                        </Fragment>
                        : <div style={{width: '100%', textAlign: 'center', display: 'flex', flexDirection: 'column'}}>
                            <div>{text}</div>
                            {comingSoon && <div className={classes.comingSoon}>Coming Soon!</div>}
                        </div>
                    }
                </Fragment>
            }
        </div>
    )
}