import { baseURL } from "../base"

const controller = `${baseURL}/master`

export async function getMasterUserListAPI(idToken, accessToken){
    const response = await fetch(`${controller}/masteruserlist`, {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    })
    return response
}

export async function switchMasterUserAPI(idToken, accessToken, target){
    const response = await fetch(`${controller}/switchtouser/${target}`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    })
    return response
}

export async function getMasterJobStatusAPI(idToken, accessToken){
    const response = await fetch(`${controller}/jobstatus`, {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    })
    return response
}

export async function setMasterJobStatusAPI(idToken, accessToken, job, status){
    const response = await fetch(`${controller}/jobstatus/${job}/${status}`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    })
    return response
}