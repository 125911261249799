import { baseURL } from "../base"

const controller = `${baseURL}/user`

const agencyController = `${baseURL}/agency`

export async function getAgencyMappingsAPI(idToken, accessToken){
    const response = await fetch(`${controller}/agencymappings`, {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    })
    return response
}

export async function getAgencyEventsAPI(idToken, accessToken, withFam){
    const request = {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    }
    if (withFam){
        request.headers['with-family'] = true
    }

    const response = await fetch(`${controller}/agencyevents`, request)
    return response
}

export async function getTomorrowAgencyEventsAPI(idToken, accessToken, withFam){
    const request = {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    }
    if (withFam){
        request.headers['with-family'] = true
    }

    const response = await fetch(`${controller}/agencyevents/tomorrow`, request)
    return response
}

export async function getTwoDaysAgencyEventsAPI(idToken, accessToken, withFam){
    const request = {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    }
    if (withFam){
        request.headers['with-family'] = true
    }
    const response = await fetch(`${controller}/agencyevents/twodays`, request)
    return response
}

export async function updateAgencyMappingAPI(idToken, accessToken, request){
    const response = await fetch(`${controller}/agency/updatemapping`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function removeAgencyMappingAPI(idToken, accessToken, request){
    const response = await fetch(`${controller}/agency/removemapping`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function getAgencyTrackingThisMonthAPI(idToken, accessToken){
    const response = await fetch(`${agencyController}/users/thismonth`, {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    })
    return response
}

export async function getAgencyTrackingLastMonthAPI(idToken, accessToken){
    const response = await fetch(`${agencyController}/users/lastmonth`, {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    })
    return response
}

export async function deleteWeeklyReportAPI(idToken, accessToken, dateFormatted){
    const response = await fetch(`${agencyController}/weeklyreport/${dateFormatted}`, {
        method: 'DELETE',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    })
    return response
}

export async function getAgencyEventImageAPI(idToken, accessToken, day, withFam, cols){
    const request = {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    }
    if (withFam){
        request.headers['with-family'] = true
    }
    if (cols){
        request.headers['custom-columns'] = cols
    }

    const response = await fetch(`${controller}/agencyeventpicture/${day}`, request)
    return response
}


export async function getHistoricEventsAPI(idToken, accessToken, request){
    const response = await fetch(`${agencyController}/historic/events`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        },
        body: JSON.stringify(request)
    })
    return response
}