import { baseURL } from "../base"

const controller = `${baseURL}/signup/discord`

export async function getDiscordTokenAPI(code){
    const response = await fetch(`${controller}/gettoken/${code}`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
        }
    })
    return response
}

export async function getDiscordUserInfoAPI(token){
    const response = await fetch(`${controller}/userinfo`, {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            Discord: token
        }
    })
    return response
}

export async function getDiscordServerList(idToken, token){
    const response = await fetch(`${controller}/serverlist`, {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: 'Bearer ' + idToken,
            Discord: token
        }
    })
    return response
}

export async function getDiscordChannelList(idToken, token, serverId){
    const response = await fetch(`${controller}/getchannels/${serverId}`, {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: 'Bearer ' + idToken,
            Discord: token
        }
    })
    return response
}

export async function getDiscordRefreshTokenAPI(refToken){
    const response = await fetch(`${controller}/refreshtoken`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            Refresh: refToken
        }
    })
    return response
}

export async function getDiscordRoleList(idToken, token, serverId){
    const response = await fetch(`${controller}/getroles/${serverId}`, {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: 'Bearer ' + idToken,
            Discord: token
        }
    })
    return response
}