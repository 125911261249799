import react, { useState, useEffect } from 'react'
import { Button, Form, Modal, Row, Table, Col } from 'react-bootstrap'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline'
import { updateExtraFamilyAPI, deleteExtraFamilyAPI, buyExtraFamilyAPI } from '../../api/bigo/familyapi'
import { handleErrors } from '../../api/base'
import { Autocomplete, TextField } from '@mui/material'
import { getTokensSyncNoUpdate } from '../../api/auth/session'
import { getFamiliesAPI } from '../../api/bigo/userapi'


export const ExtraFams = ({notify, show, onHide, user, sub, reloadUser, reloadSub}) => {

    const [extraFams, setExtraFams] = useState(sub.PurchasedFamilies)
    const [editId, setEditId] = useState(null)
    const [name, setName] = useState('')
    const tokenInfo = getTokensSyncNoUpdate()
    const [familyOpts, setFamilyOpts] = useState([])

    useEffect(() => {
        
        if (!show){
            return
        }

        const loadFamilies = async () => {
            try{
                const response = await getFamiliesAPI(tokenInfo.IdToken, tokenInfo.AccessToken)
                const result = await handleErrors(response)
                
                setFamilyOpts(result)
            }
            catch (error){
                let finalError = error
                try{
                    finalError = await error
                }
                catch(err){}
                console.log(finalError)
                notify('Error', finalError.toString())
            }
        }

        loadFamilies()
    }, [show])

    const updateFamCount = (adj) => {
        let current = Number(extraFams)
        current += adj
        if (current <= 0){
            current = 0
        }
        else if (current >= 10){
            current = 10
        }

        setExtraFams(current)
    }

    const updateFam = async () => {
        if (!name){
            notify('Error', 'Family Name Required')
            return
        }
        try{
            const request = {
                FamilyId: editId,
                FamilyName: name
            }
            const response = await updateExtraFamilyAPI(tokenInfo.IdToken, tokenInfo.AccessToken, request)
            const result = await handleErrors(response)

            setName('')
            setEditId(null)
            reloadUser && reloadUser()
        }
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
    }

    const removeFam = async () => {
        if (!editId){
            return
        }

        try{
            const response = await deleteExtraFamilyAPI(tokenInfo.IdToken, tokenInfo.AccessToken, editId)
            const result = await handleErrors(response)

            setName('')
            setEditId(null)
            reloadUser && reloadUser()
        }
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
    }

    const buyExtraFams = async () => {
        try{
            const response = await buyExtraFamilyAPI(tokenInfo.IdToken, tokenInfo.AccessToken, extraFams)
            const result = await handleErrors(response)

            setName('')
            setEditId(null)

            await reloadSub()
            await reloadUser()
        }
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
    }

    return <Modal show={show} onHide={onHide} size='lg' backdrop='static' centered>
        <Modal.Header closeButton>
            <Col>
            <h3>Additional Families</h3>
            <h4>$5 Per Month Each</h4>
            </Col>
        </Modal.Header>
        <Modal.Body>
            <Table striped bordered hover responsive>
                <colgroup>
                    <col />
                    <col style={{width: '50px'}} />
                    <col style={{width: '50px'}} />
                </colgroup>
                <thead>
                    <tr>
                        <th  style={{verticalAlign: 'middle'}}>Family</th>
                        <th>&nbsp;</th>
                        <th  style={{verticalAlign: 'middle'}}>
                            {sub && user && sub.PurchasedFamilies > user.AdditionalFamilies.length
                                ? <AddCircleOutline onClick={() => {setEditId(0); setName('')}} />
                                : ' '
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>                    
                    {editId === 0 && <tr>
                            <td>
                                <Autocomplete
                                    id="addfamily-autocomplete"
                                    options={familyOpts}
                                    freeSolo
                                    inputValue={name}
                                    defaultValue={name}
                                    onInputChange={(e, v) => setName(v)}
                                    renderInput={(params) => <TextField {...params} maxLength={200} variant="outlined" />}
                                />
                            </td>
                            <td style={{verticalAlign: 'middle'}}>
                                <SaveIcon onClick={updateFam} />
                            </td>
                            <td>&nbsp;</td>
                    </tr>}
                    {user && user.AdditionalFamilies.map(x => {
                        if (x.FamilyId === editId){
                            return <tr key={x.FamilyId}>
                                <td>
                                    <Autocomplete
                                        id="editfamily-autocomplete"
                                        options={familyOpts}
                                        freeSolo
                                        inputValue={name}
                                        defaultValue={name}
                                        onInputChange={(e, v) => setName(v)}
                                        renderInput={(params) => <TextField {...params} maxLength={200} variant="outlined" />}
                                    />
                                </td>
                                <td style={{verticalAlign: 'middle'}}>
                                    <SaveIcon onClick={updateFam} />
                                </td>
                                <td style={{verticalAlign: 'middle'}}>
                                    <DeleteIcon onClick={removeFam} />
                                </td>
                            </tr>
                        }
                        
                        return <tr key={x.FamilyId}>
                            <td>
                                {x.Name}
                            </td>
                            <td style={{verticalAlign: 'middle'}}>
                                <EditIcon onClick={() => {setEditId(x.FamilyId); setName(x.Name)}} />
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                    })}
                </tbody>
            </Table>
        </Modal.Body>
        <Modal.Footer>
            <Row style={{alignItems: 'center'}}>
                <h4>Change Qty</h4>
                <div style={{ marginLeft: '50px', marginRight: '10px'}}>
                    <Row style={{alignItems: 'center'}}>
                        <AddCircleOutline onClick={() => updateFamCount(1)} style={{fontSize: '2rem', cursor: 'pointer'}} />
                        <Form.Control disabled style={{width: '80px', textAlign: 'center', margin: '10px', fontWeight: 'bold'}} value={extraFams} />
                        <RemoveCircleOutline onClick={() => updateFamCount(-1)} style={{fontSize: '2rem', cursor: 'pointer'}} />
                    </Row>
                </div>
                <Button disabled={!sub || sub.PurchasedFamilies === extraFams} onClick={buyExtraFams}>
                    Update Purchase
                </Button>
            </Row>
        </Modal.Footer>
    </Modal>
}