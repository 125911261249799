import { useEffect, useState, Fragment } from "react"
import { Form, Col, Row, Button } from 'react-bootstrap'
import { Checkbox, CircularProgress, TextField, useMediaQuery } from '@material-ui/core'
import { getTokensSyncNoUpdate } from "../../api/auth/session"
import { getSubscriptionStatusAPI, getUserInfoAPI, getCarriersAPI, getFamiliesAPI,
    updateUserAPI, updatePlanAPI, checkCouponAPI,
    cancelPlanAPI, resumePlanAPI, updatePaymentAPI } from "../../api/bigo/userapi"
import { handleErrors } from "../../api/base"
import EditIcon from '@material-ui/icons/Edit'

import MaskedInput from 'react-text-mask'
import { TimeZonePicker } from "./components/timezone"
import { Autocomplete } from "@material-ui/lab"
import userStatus from "../../enums/userStatus"
import { SalePanel } from "./components/salePanel"
import { realPlans as testPlans } from "../../enums/bigoPlans/defaultPlan"
import { Helmet } from "react-helmet"
import { loadStripe} from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { CheckOutForm } from "./components/checkOutForm"
import userFlags from "../../enums/userFlags"
import { makeStyles } from "@material-ui/styles"
import { ExtraFams } from "./extraFams"
import { MasterAdmin } from "./masterAdmin"
import { PremiumSMS } from "./manageSMS"
import { CountrySelector } from "./components/countrySelector"

const useStyles = makeStyles({
    colLabel: {
        fontWeight: 'bold'
    }
})

const SelectedPanel = ({selectedPlan}) => {
    const target = testPlans.filter(x => x.StripeId === selectedPlan)[0]
    if (!target) {
        return ''
    }
    return <SalePanel
        key={target.StripeId}
        name={target.Name}
        amount={target.Amount}
        Details={target.Description}
        id={target.StripeId}
    />
}

export const BigoUser = ({notify, user, setUser, sub, setSub}) => {

    const [loading, setLoading] = useState(true)
    const classes = useStyles()
    const tokenInfo = getTokensSyncNoUpdate()    
    const isSmall = useMediaQuery('(max-width:650px)')
    const isLarge = useMediaQuery('(min-width:1200px)')
    const isXLarge = useMediaQuery('(min-width:1500px)')

    const carrierLocation = 'BIGO_CARRIERS'
    // const publicStripeKey = 'pk_test_51GwWoEJ4I7MVNjyqJVFw9urYxlxFOoX0HrYDoIwOJfwxUpjyaYIJ8VKUSHN5E3hMb4nFuLA9GXwUcdTl2Cf8D4xu00273DA6q7'
    const publicStripeKey = 'pk_live_51GwWoEJ4I7MVNjyq0hqDzkgnR8SLvtk4oX5ZC3rTu3qEg3aEQpfv9XjXofx5qaNZwonUJZzOVJ3YoxbXRpDvra9s00P0h6hF4f'

    const [carriers, setCarriers] = useState(null)
    const [editFamily, setEditFamily] = useState(false)
    const [familyOpts, setFamilyOpts] = useState([])
    const [selectedPlan, setSelectedPlan] = useState(null)
    const [stripePromise, setStripePromise] = useState(null)
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [coupon, setCoupon] = useState(null)
    const [useNewMethod, setUseNewMethod] = useState(false)
    const [couponResult, setCouponResult] = useState(false)
    const [manageExtraFam, setManageExtraFam] = useState(false)
    const [showMaster, setShowMaster] = useState(false)
    const [showSMS, setShowSMS] = useState(false)
    const [loadingText, setLoadingText] = useState('')
    
    const dayFormatter = new Intl.DateTimeFormat('en-US', {
        dateStyle: 'long'
    })

    useEffect(() => {
        const loadUserInfo = async () => {
            await reloadUser()
            setTimeout(doneIntialLoading, 100)
        }

        const loadSubscriptionStatus = async () => {
            await reloadSubscription()
            setTimeout(doneIntialLoading, 100)
        }

        const loadCarriers = async () => {
            try{
                let carrierInfo = localStorage.getItem(carrierLocation)
                if (carrierInfo){
                    carrierInfo = JSON.parse(carrierInfo)
                    if (carrierInfo.Expiration < new Date()){
                        carrierInfo = null
                    }
                }
                if (!carrierInfo){
                    const response = await getCarriersAPI(tokenInfo.IdToken, tokenInfo.AccessToken)
                    const result = await handleErrors(response)
                    
                    let sevenDays = new Date()
                    sevenDays.setDate(sevenDays.getDate() + 7)
                    carrierInfo = {
                        Carriers: result,
                        Expiration: sevenDays
                    }
                    localStorage.setItem(carrierLocation, JSON.stringify(carrierInfo))
                }
                setCarriers(carrierInfo.Carriers)
            }
            catch (error){
                let finalError = error
                try{
                    finalError = await error
                }
                catch(err){}
                console.log(finalError)
                notify('Error', finalError.toString())
            }
            setTimeout(doneIntialLoading, 100)
        }

        loadStripeTag()
        if (sub){            
            setSelectedPlan(sub.PlanId)
        }
        loadUserInfo()
        loadSubscriptionStatus()
        loadCarriers()
        setStripePromise(loadStripe(publicStripeKey))
    }, [])

    const reloadSubscription = async () => {
        try{
            const response = await getSubscriptionStatusAPI(tokenInfo.IdToken, tokenInfo.AccessToken)
            const result = await handleErrors(response)
            
            setSub(result)
            setSelectedPlan(result.PlanId)
        }
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
    }

    const reloadUser = async () => {
        try{
            const response = await getUserInfoAPI(tokenInfo.IdToken, tokenInfo.AccessToken)
            const result = await handleErrors(response)
            
            setUser(result)
        }
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
    }

    const updateUserProp = (val, field) => {
        const clone = Object.assign({}, user)
        clone[field] = val
        setUser(clone)
    }

    const doneIntialLoading = () => {
        setLoading(user && sub && carriers)
    }

    const loadFamilies = async () => {
        try{
            const response = await getFamiliesAPI(tokenInfo.IdToken, tokenInfo.AccessToken)
            const result = await handleErrors(response)
            
            setFamilyOpts(result)
        }
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
    }

    const getUserStatus = (x) => {
        if (x === userStatus.NeedSubscription){
            return 'No Subscription'
        }

        if (sub && sub.SubscriptionId === 'MASTER'){            
            return <div>
                <div>Master Account</div>
                <div>{user.FamilyCount} / <span>&#8734;</span> Family Users</div>
                <div>{user.AgencyCount} / <span>&#8734;</span> Agency Users</div>
            </div>
        }

        if (x === userStatus.Paid && sub.SubscriptionId){
            const matchPlan = testPlans.filter(x => x.StripeId === sub.PlanId)[0]
            if (!matchPlan){
                return 'Unknown'
            }
            return <div>
                <div>{matchPlan && matchPlan.Name}</div>
                <div>{user.FamilyCount} / {matchPlan.Family === -1 ? <span>&#8734;</span> : matchPlan.Family} Family Users</div>
                <div>{user.AgencyCount} / {matchPlan.Agency === -1 ? <span>&#8734;</span> : matchPlan.Agency} Agency Users</div>
            </div>
        }

        return 'Awaiting Payment'
    }

    const getUserLimits = () => {
        if (!sub){
            return {
                Agency: 0,
                Family: 0
            }
        }
        if (sub.SubscriptionId === 'MASTER'){
            return {
                Agency: -1,
                Family: -1
            }
        }
        const matchPlan = testPlans.filter(x => x.StripeId === sub.PlanId)[0]
        return {
            Agency: (matchPlan && matchPlan.Agency) || 0,
            Family: (matchPlan && matchPlan.Family) || 0
        }
    }

    const saveUser = async () => {
        setLoading(true)
        try{
            const request = Object.assign({}, user)
            request.CarrierId = Number(request.CarrierId)
            request.TimezoneOffset = Number(request.TimezoneOffset)
            request.Family = request.FamilyName
            request.Agency = request.AgencyName
            if (!request.TimezoneOffset){
                request.TimezoneOffset = null
            }
            if (!request.CarrierId){
                request.CarrierId = null
            }

            if (request.Phone && request.Phone.length && request.Phone.length !== 14){
                notify('Invalid Phone', 'Invalid Phone No Length')
                return
            }
            
            try{
                const response = await updateUserAPI(tokenInfo.IdToken, tokenInfo.AccessToken, request)
                const result = await handleErrors(response)
                if (result){
                    notify('Saved', 'Successfully Saved')
                    setEditFamily(false)
                }
            }            
            catch (error){
                let finalError = error
                try{
                    finalError = await error
                }
                catch(err){}
                console.log(finalError)
                notify('Error', finalError.toString())
            }
        }
        catch (err){
            notify('Error', err.toString())
        }
        finally{            
            setLoading(false)
        }
    }

    const updatePaymentMethod = async () => {
        if (loading){
            return
        }
        const request = {
            PaymentId: (paymentMethod && paymentMethod.id) || null,
        }

        
        setLoading(true)
        try{
            let response = await updatePaymentAPI(tokenInfo.IdToken, tokenInfo.AccessToken, request)
            let result = await handleErrors(response)

            if (result){
                setTimeout(() => {
                    notify('Updated', 'Updated Default Payment Method')
                    reloadSubscription()
                        .finally(() => setLoading(false))
                }, 1000)
                setUseNewMethod(false)
                setPaymentMethod(null) 
            }
            else{
                setLoading(false)
            }
        }               
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
            setLoading(false)
        }
    }

    const updatePlan = async () => {
        if (loading){
            return
        }
        const request = {
            PaymentId: (paymentMethod && paymentMethod.id) || null,
            Coupon: coupon || null,
            PlanId: selectedPlan
        }

        
        setLoading(true)
        try{
            let response = await updatePlanAPI(tokenInfo.IdToken, tokenInfo.AccessToken, request)
            let result = await handleErrors(response)

            if (result){
                if (sub && sub.SubscriptionId){
                    setTimeout(() => {
                        reloadSubscription()
                            .finally(() => setLoading(false))
                    }, 3000)
                }
                else{
                    // Load Load
                    longLoadingText(() => {
                        reloadSubscription()
                            .finally(() => setLoading(false))
                    })
                }
            }
            else{
                setLoading(false)
            }
        }               
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
            setLoading(false)
        }
    }

    const checkCouponCode = async () => {
        const matchPlan = testPlans.filter(x => x.StripeId === selectedPlan)[0]
        if (!matchPlan){
            notify('Error', 'Unknown Plan')
            return
        }
        const request = {
            CouponCode: coupon || '',
            ProductId: matchPlan.ProductId
        }
        setLoading(true)
        try{
            const response = await checkCouponAPI(tokenInfo.IdToken, tokenInfo.AccessToken, request)
            const result = await handleErrors(response)
            
            setCouponResult(result)
        }
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
        setLoading(false)
    }

    const updateUserFlags = (flag, on) => {
        const clone = Object.assign({}, user)
        if (on){
            clone.Flags |= flag
        }
        else{
            clone.Flags &= ~flag
        }
        setUser(clone)
    }

    const cancelOrResume = async (action) => {
        setLoading(true)
        try{
            let response
            if (action === 'cancel'){
                response = await cancelPlanAPI(tokenInfo.IdToken, tokenInfo.AccessToken)
            }
            else if (action === 'resume'){
                response = await resumePlanAPI(tokenInfo.IdToken, tokenInfo.AccessToken)
            }
            else{
                setLoading(false)
                return
            }
            const result = await handleErrors(response)
            if (result){
                await reloadSubscription()
            }
        }
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
        setLoading(false)
    }

    const longLoadingText = (callback) => {

        // 30 seconds of loading
        setLoadingText('Payment Successful')
        setTimeout(() => {
            setLoadingText('Setting Up Your Account')
        }, 5000)
        setTimeout(() => {
            setLoadingText('Provisioning Your User')
        }, 10000)
        setTimeout(() => {
            setLoadingText('Loading Subscription Info')
        }, 15000)
        setTimeout(() => {
            setLoadingText('Finishing Things Up')
        }, 20000)
        setTimeout(() => {
            setLoadingText('Welcome To BIGO FAM')
        }, 25000)
        setTimeout(() => {
            setLoadingText('')
            callback && callback()
        }, 30000)
    }

    const loadStripeTag = () => {
        try{
            const stripeURL = 'https://js.stripe.com/v3'
            let found = false
            window.document.head.childNodes.forEach(x => {
                if (x.nodeName === 'SCRIPT' && x.src === stripeURL){
                found = true
                }
            })
            if (!found){
                const scriptTag = window.document.createElement('script')
                scriptTag.setAttribute('type', 'text/javascript');
                scriptTag.setAttribute('src', stripeURL);
                scriptTag.setAttribute('async', 'true')
                window.document.head.appendChild(scriptTag)
            }
        }
        catch (err) {}
    }

    return <Fragment>
        <Row>
            <Col>
                <h2>
                    User Settings
                </h2>
            </Col>
            {tokenInfo && tokenInfo.Master && (
                <Col xs={6} lg={4} xl={3} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Button onClick={() => setShowMaster(true)}>Admin</Button>
                </Col>
            )}
            <Col xs={6} lg={4} xl={3}>
                <Button style={{width: '100%'}} onClick={() => {saveUser()}} >Save</Button>
            </Col>
        </Row>

        <hr />
        
        <Row style={{marginTop: '5px', height: '85%'}}>
            {loading || !user || !sub || !carriers
            ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%', width: '100%', flexDirection: 'column' }}>
                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                    {loadingText && <h3 style={{marginTop: '20px'}}>{loadingText}</h3>}
                </div>
            )
            : (
                <div style={{width: '100%'}}>
                    <ExtraFams notify={notify} show={manageExtraFam} onHide={() => setManageExtraFam(false)} user={user} sub={sub} reloadUser={reloadUser} reloadSub={reloadSubscription} />
                    <MasterAdmin notify={notify} show={showMaster} onHide={() => setShowMaster(false)} />
                    <PremiumSMS notify={notify} show={showSMS} onHide={() => setShowSMS(false)} user={user} reloadUser={reloadUser} reloadSub={reloadSubscription} />
                    <Form.Group as={Row}>
                        <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                            Family Name
                            </Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            {editFamily
                            ? <Autocomplete
                                id="family-autocomplete"
                                options={familyOpts}
                                freeSolo
                                inputValue={user.FamilyName}
                                defaultValue={user.FamilyName}
                                onInputChange={(e, v) => updateUserProp(v, 'FamilyName')}
                                renderInput={(params) => <TextField {...params} maxLength={200} variant="outlined" />}
                            />
                            : <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%'}}>
                                <span>{user.FamilyName || '(not set)'}</span>
                                <EditIcon style={{cursor: 'pointer'}} onClick={() => {
                                    if (!user.FamilyCount || window.confirm('Changing Your Family Name Will Remove All Family Mappings')){
                                        setEditFamily(true)
                                        loadFamilies()
                                    }
                                }} />
                            </div>
                            }
                        </Col>

                        <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                            Agency Name
                        </Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            <Form.Control value={(user && user.AgencyName) || ''} onChange={(e) => updateUserProp(e.target.value, 'AgencyName')} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                            Phone Number
                        </Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            <MaskedInput
                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                    className="form-control"
                                    guide={false}
                                    onChange={(e) => updateUserProp(e.target.value, 'Phone')}
                                    required
                                    type="tel"
                                    value={user?.Phone || ''}
                                />
                        </Col>
                        <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                            Country
                        </Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            <CountrySelector getter={user.CountryCode} setter={(e) => updateUserProp(e.target.value, 'CountryCode')} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                            Timezone
                        </Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            <TimeZonePicker getter={user.TimezoneOffset} setter={(e) => updateUserProp(e.target.value, 'TimezoneOffset')} />
                        </Col>
                        <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                            Carrier
                        </Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            <Form.Control as="select" value={user.CarrierId} maxLength={200} onChange={(e) => updateUserProp(e.target.value, 'CarrierId')}>
                                <option value={0}>-- Select --</option>
                                {carriers.map(x => {
                                    return <option value={x.Id} key={x.Id}>{x.Name}</option>
                                })}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                            Observes DST
                        </Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            <Checkbox checked={user.ObservesDST} onChange={(e) => updateUserProp(e.target.checked, 'ObservesDST')} />
                        </Col>
                        <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                            Status
                        </Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>{getUserStatus(sub.UserStatus)}</div>
                        </Col>
                    </Form.Group>
                    {sub && sub.SubscriptionId && (
                        <Fragment>
                            <Form.Group as={Row}>
                                <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                        Latest Invoice
                                    </Form.Label>
                                    <Col xl={2} lg={3} md={3}>
                                        <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                            {sub.InvoiceId 
                                                ? <a href={sub.InvoiceId} target="_blank" rel="noreferrer">View Invoice</a>
                                                : 'No Invoice'
                                            }
                                        </div>
                                    </Col>
                                    <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                        Recharge Date
                                    </Form.Label>
                                    <Col xl={2} lg={3} md={3}>
                                        <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                            {sub.WillCancel 
                                                ? 'Subscription Will Cancel' 
                                                : sub.NextInvoice
                                                    ? dayFormatter.format(new Date(sub.NextInvoice))
                                                    : 'No Recharge Date'
                                            }
                                        </div>
                                    </Col>
                            </Form.Group>                                
                            <Form.Group as={Row}>
                                { sub && sub.UserStatus === userStatus.Paid && sub.SubscriptionId && getUserLimits().Family !== 0 && (
                                    <Fragment>
                                        <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                            Additional Families
                                        </Form.Label>
                                        <Col xl={2} lg={3} md={3}>
                                            <Button onClick={() => setManageExtraFam(true)}>Manage</Button>
                                        </Col>
                                    </Fragment>
                                )}
                                {sub && sub.UserStatus === userStatus.Paid && (
                                    <Fragment>
                                        <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                            Premium SMS
                                        </Form.Label>
                                        <Col xl={2} lg={3} md={3}>
                                            <Button onClick={() => setShowSMS(true)}>Manage</Button>
                                        </Col>
                                    </Fragment>
                                )}
                            </Form.Group>
                            <Form.Group as={Row}>
                                {getUserLimits().Family !== 0 && <Fragment>
                                    <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                        Family Leader Notifications
                                    </Form.Label>
                                    <Col xl={2} lg={3} md={3}>
                                        <Checkbox checked={(user.Flags & userFlags.LeaderNotifications) !== 0} onChange={(e) => updateUserFlags(userFlags.LeaderNotifications, e.target.checked)} />
                                    </Col>
                                </Fragment>}
                                {getUserLimits().Agency !== 0 && <Fragment>
                                    <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                        Agency Leader Notifications
                                    </Form.Label>
                                    <Col xl={2} lg={3} md={3}>
                                        <Checkbox checked={(user.Flags & userFlags.AgencyLeaderNotifications) !== 0} onChange={(e) => updateUserFlags(userFlags.AgencyLeaderNotifications, e.target.checked)} />
                                    </Col>
                                </Fragment>}                                
                            </Form.Group>
                            <Form.Group as={Row}>
                                {getUserLimits().Family !== 0 && getUserLimits().Agency !== 0 && <Fragment>
                                    <Form.Label className={classes.colLabel} column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                        Send Family Alerts To My Agency Users
                                    </Form.Label>
                                    <Col xl={2} lg={3} md={3}>
                                        <Checkbox checked={(user.Flags & userFlags.ShowFamilyOnAgency) !== 0} onChange={(e) => updateUserFlags(userFlags.ShowFamilyOnAgency, e.target.checked)} />
                                    </Col>
                                </Fragment>}
                            </Form.Group>
                        </Fragment>
                    )}

                    <Row style={{marginTop: '35px'}}>
                        <Col>
                            <h2>
                                Subscription
                            </h2>
                        </Col>
                    </Row>
                    <hr />
                    {/* <Helmet>
                        <script async src="https://js.stripe.com/v3/"></script>
                    </Helmet> */}
                    <Row style={{
                        marginTop: '5px',
                        paddingLeft: isSmall ? '' : '10px',
                        paddingRight: isSmall ? '' : '10px',
                        paddingBottom: isSmall ? '20px' : ''
                    }}>
                        {sub.UserStatus && selectedPlan === sub.PlanId
                        ? <Row style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%'
                        }}>
                                <Col xs={12} lg={4}>
                                    <SelectedPanel selectedPlan={selectedPlan} />
                                </Col>
                                <Col xs style={{display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                    {sub.SubscriptionId === 'MASTER'
                                        ? <h3>Admin Account</h3>
                                        : paymentMethod
                                            ? <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                                                <h3>Use {paymentMethod.card.brand.toUpperCase()} Ending In {paymentMethod.card.last4}</h3>
                                                <div style={{display: 'flex'}}>
                                                    <Button variant="success" style={{marginTop: '30px', marginRight: '15px'}} onClick={updatePaymentMethod}>Update</Button>
                                                    <Button style={{marginTop: '30px'}} onClick={() => {
                                                        setUseNewMethod(false)
                                                        setPaymentMethod(null)                                                
                                                    }}>Back</Button>
                                                </div>
                                            </div>
                                            : useNewMethod
                                                ? <Elements stripe={stripePromise}>
                                                    <CheckOutForm 
                                                        setPaymentMethod={setPaymentMethod}
                                                        Back={() => <Button onClick={() => {
                                                            setUseNewMethod(false)
                                                        }}>
                                                            Back
                                                        </Button>} />
                                                </Elements>
                                                : <div>
                                                    <Button variant="success" style={{marginTop: '30px', marginRight: '15px'}} onClick={() => setSelectedPlan(null)}>Change Plan</Button>
                                                    <Button style={{marginTop: '30px', marginRight: '15px'}} onClick={() => setUseNewMethod(true)} >Update Payment Method</Button>
                                                    {sub.WillCancel
                                                        ? <Button style={{marginTop: '30px'}} onClick={() => cancelOrResume('resume')}>Resume Plan</Button>
                                                        : <Button style={{marginTop: '30px'}} variant="danger" onClick={() => cancelOrResume('cancel')}>Cancel Plan</Button>
                                                    }
                                                </div>
                                    }
                                </Col>
                            </Row>
                        : selectedPlan
                            ? <Row style={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%'
                            }}>
                                <Col xs={12} lg={4} style={{marginBottom: '10px'}}>
                                    <SelectedPanel selectedPlan={selectedPlan} />
                                </Col>
                                <Col xs>
                                    <Row>
                                        <Form.Group as={Row}>
                                            <Form.Label column style={{textAlign: 'end'}}>Coupon</Form.Label>
                                            <Col xs={6}>
                                                <Form.Control value={coupon} onChange={(e) => {setCoupon(e.target.value); setCouponResult(null)}} />
                                            </Col>
                                            <Col>
                                                <Button disabled={!coupon} onClick={checkCouponCode}>Validate</Button>
                                            </Col>
                                        </Form.Group>
                                        {couponResult && <Row style={{color: couponResult.Valid ? 'green' : 'red'}}>
                                            <Col>
                                            <h4>{couponResult.Valid ? 'Valid' : 'Not Valid'}</h4>
                                            {couponResult.Name && <Fragment>
                                                <h4>{couponResult.Name}</h4>
                                                <h4>{couponResult.Discount}</h4>
                                            </Fragment>}
                                            </Col>
                                        </Row>}
                                    </Row>
                                {paymentMethod
                                    ? <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                                        <h3>Use {paymentMethod.card.brand.toUpperCase()} Ending In {paymentMethod.card.last4}</h3>
                                        <div style={{display: 'flex'}}>
                                            <Button variant="success" style={{marginTop: '30px', marginRight: '15px'}} onClick={updatePlan}>Submit</Button>
                                            <Button style={{marginTop: '30px'}} onClick={() => {
                                                setUseNewMethod(false)
                                                setPaymentMethod(null)                                                
                                            }}>Back</Button>
                                        </div>
                                    </div>
                                    : sub.CustomerId && !useNewMethod
                                        ? <div style={{display: 'flex', alignItems: 'center'}}>
                                            <Button variant="success" style={{marginTop: '30px', marginRight: '15px'}} onClick={updatePlan}>Use Payment Method On File</Button>
                                            <Button style={{marginTop: '30px', marginRight: '15px'}} onClick={() => setUseNewMethod(true)}>Change Method</Button>
                                            <Button variant="danger" style={{marginTop: '30px'}} onClick={() => {setSelectedPlan(null); setCouponResult(null)}}>Back</Button>
                                        </div>
                                        : <Elements stripe={stripePromise}>
                                        <CheckOutForm 
                                            setPaymentMethod={setPaymentMethod}
                                            Back={() => <Button onClick={() => {
                                                setSelectedPlan(null)
                                                setUseNewMethod(false)
                                                setCouponResult(null)
                                            }}>
                                                Back
                                            </Button>} />
                                    </Elements>
                                }
                                </Col>
                            </Row>
                            : <div style={{
                                display: 'grid',
                                gridAutoRows: '1fr',
                                gridTemplateColumns: `repeat(${
                                    isXLarge ? '4' : 
                                    isSmall ? '1' : 
                                    isLarge ? '3' : '2'
                                }, 1fr)`,
                                columnGap: '5px',
                                rowGap: '10px',
                                width: '100%',
                                textAlign: 'center'
                            }}>
                                {testPlans.map(x => {
                                    return <SalePanel
                                        key={x.StripeId}
                                        name={x.Name}
                                        amount={x.Amount}
                                        Details={x.Description}
                                        id={x.StripeId}
                                        idSetter={setSelectedPlan}
                                        selected={(sub.PlanId || selectedPlan) === x.StripeId}
                                    />
                                })}
                            </div>
                        }
                    </Row>
                </div>
            )}
        </Row>
    </Fragment>
}