import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import React, { useState, useEffect, useCallback, Fragment } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormControl, Button, Modal, Alert } from 'react-bootstrap'

export const CheckOutForm = ({setPaymentMethod, Back}) => {
    const stripe = useStripe()
    const elements = useElements()

    const [name, setName] = useState('')
    const [msg, setMsg] = useState(null)
    const [showError, setShowError] = useState(false)
    const isBig = useMediaQuery('(min-width:900px)');

    const CARD_OPTIONS = {
        iconStyle: 'solid',
        style: {
          base: {
            iconColor: '#343a40',
            color: '#000',
            fontWeight: 500,
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '18px',
            textAlign: 'center',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {color: '#fce883'},
            '::placeholder': {color: '#87bbfd'},
          },
          invalid: {
            iconColor: 'darkred',
            color: 'red',
          },
        },
      };

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (!stripe || !elements){
            // No Stripe.JS loaded yet
            return
        }

        const cardElement = elements.getElement(CardElement)

        const {error, paymentMethod} = await stripe.createPaymentMethod(({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: name
            }
        }))

        if (error){
            console.log('Stripe Error', error);
            setMsg(error.message)
            setShowError(true)
        }
        else{
            console.log('PaymentMethod', paymentMethod)
            setShowError(false)
            setMsg('')
            setPaymentMethod && setPaymentMethod(paymentMethod)
        }
    }

    return (
        <Card style={{width: '100%'}}>
            <form onSubmit={handleSubmit}>
                <Card.Header><b>Payment Information</b></Card.Header>
                <Card.Body style={{paddingBottom: '0px'}}>
                    <Alert variant="danger" show={showError} onClose={() => setShowError(false)} dismissible>
                        <Alert.Heading>Error!</Alert.Heading>
                        <p>{msg}</p>
                    </Alert>
                    <Form>
                        <Form.Group as={Row} style={{textAlign: isBig ? 'end' : 'left'}}>
                            <Col sm={0} lg={1}></Col>
                            <Form.Label column sm={8} lg={3}>Name On Card</Form.Label>
                            <Col sm={8} lg={4}>
                                <Form.Control value={name} onChange={(e) => {setName(e.target.value)}} />
                            </Col>
                        </Form.Group>
                    </Form>                      
                </Card.Body>
                <CardElement options={CARD_OPTIONS} />  
                <Card.Footer>
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '15px'}}>
                            <Button variant={!stripe || !name ? 'danger' : 'dark'} type="submit" disabled={!stripe || !name} style={{marginRight: '15px'}}>
                                Validate Card
                            </Button>
                            <Back />
                        </div>
                </Card.Footer>
            </form>
        </Card>
    )
}