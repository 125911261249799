import {useState, useEffect, Fragment, useMemo} from 'react'
import { Row, Col, ProgressBar, Form, ButtonGroup, Button } from 'react-bootstrap'
import { CircularProgress, Tabs, Tab, useMediaQuery } from '@material-ui/core'
import { getMainDashboardAPI } from '../../api/bigo/dashboardapi'
import { getTokensSyncNoUpdate } from '../../api/auth/session'
import { handleErrors } from '../../api/base'
import {  Chart } from 'react-charts'
import './styles/dashboard.css'

const BarCard = ({data, primaryAxis, secondaryAxes, title}) => {
    return <Col sm={12} lg={4} style={{height: '400px',}}>
        <Col style={{backgroundColor: 'lightgray', borderRadius: '10px', padding: '10px'}}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <h3 style={{textAlign: 'center'}}>{title}</h3>
            </div>
            <div style={{height: '300px', borderRadius: '10px', padding: '0px'}}>
                <Chart
                    options={{
                        data,
                        primaryAxis,
                        secondaryAxes,
                        padding: 20,
                        // dark: true,
                    }}
                />
            </div>
        </Col>
    </Col>
}
const dateFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'short', year: '2-digit'
})

const CountWidget = ({data, title}) => {
    const primary = useMemo(() => {
        return {
            getValue: x => dateFormatter.format(new Date(x.Date)),
        }
    }, [])

    const secondary = useMemo(() => {
        return [{
            getValue: x => x.Count,            
            elementType: 'bar',
            min: 0,
        }]
    }, [])

    return <BarCard data={data} primaryAxis={primary} secondaryAxes={secondary} title={title} />
}

export const AgencyDashboard = ({notify, sub, user}) => {

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const tokenInfo = getTokensSyncNoUpdate()

    useEffect(() => {
        const loadDash = async () => {
            await loadMainDashboard()
            setLoading(false)
        }

        if(!data){
            loadDash()
        }
        else{
            setLoading(false)
        }
    }, [])

    const loadMainDashboard = async () => {
        try{
            const response = await getMainDashboardAPI(tokenInfo.IdToken, tokenInfo.AccessToken)
            const result = await handleErrors(response)
            setData(result)
            console.log(result)
        }
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
    }

    const activeData = useMemo(() => {
        return [{
            label: 'Active Host Count',
            data: (data && data.ActiveCounts.slice(0, 5)) || [],
        }]
    }, [data])

    const newHostData = useMemo(() => {
        return [{
            label: 'New Host Count',
            data: (data && data.NewCounts.slice(0, 5)) || [],
        }]
    }, [data])

    const totalHostData = useMemo(() => {
        return [{
            label: 'Total Host Count',
            data: (data && data.TotalCounts.slice(0, 5)) || [],
        }]
    }, [data])

    const activeHitTarget = useMemo(() => {
        const final = []
        if (data && data.TargetData){
            data.TargetData.forEach(x => {
                const match = final.filter(y => y.Date === x.Date)[0]
                if (!match){
                    final.push({
                        Date: x.Date,
                        Count: x.Hit ? 1 : 0
                    })
                }
                else if (x.Hit){
                    match.Count++
                }
            })
        }
        return [{
            label: 'Active Hosts Who Hit Target',
            data: final.sort((a, b) => new Date(a.Date) > new Date(b.Date) ? 1 : -1)
        }]
    }, [data])

    const activeBeatTarget = useMemo(() => {
        const final = []
        if (data && data.TargetData){
            data.TargetData.forEach(x => {
                const match = final.filter(y => y.Date === x.Date)[0]
                if (!match){
                    final.push({
                        Date: x.Date,
                        Count: x.Exceeded ? 1 : 0
                    })
                }
                else if (x.Exceeded){
                    match.Count++
                }
            })
        }
        return [{
            label: 'Active Hosts Who Beat Target',
            data: final.sort((a, b) => new Date(a.Date) > new Date(b.Date) ? 1 : -1)
        }]
    }, [data])

    return (
        <Fragment>
            <Row>
                <Col>
                    <h2>
                        Agency Dashbaord
                    </h2>
                </Col>
            </Row>

            <hr />
            
            <Row style={{marginTop: '5px', height: '85%', width: '100%'}}>
                <div style={{width: '100%'}}>
                    {loading
                    ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%', width: '100%' }}>
                        <CircularProgress style={{ width: '100px', height: '100px' }} />
                    </div>
                    : <Row>
                        {data && Boolean(data.ActiveCounts.length) && <CountWidget data={activeData} title={'Active Hosts'} />}
                        {data && Boolean(data.NewCounts.length) && <CountWidget data={newHostData} title={'New Active Hosts'} />}
                        {data && Boolean(data.TotalCounts.length) && <CountWidget data={totalHostData} title={'Total Hosts'} />}
                        {data && Boolean(data.TargetData.length) && <CountWidget data={activeHitTarget} title={'Active Hit Target'} />}
                        {data && Boolean(data.TargetData.length) && <CountWidget data={activeBeatTarget} title={'Active Beat Target'} />}
                    </Row>}
                </div>
            </Row>
        </Fragment>
    )
}