import { baseURL } from "../base"

const controller = `${baseURL}/user`

export async function getUserInfoAPI(idToken, accessToken){
    const response = await fetch(`${controller}/userinfo`, {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    })
    return response
}

export async function getSubscriptionStatusAPI(idToken, accessToken){
    const response = await fetch(`${controller}/subscriptionstatus`, {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    })
    return response
}

export async function getCarriersAPI(idToken, accessToken){
    const response = await fetch(`${controller}/carriers`, {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    })
    return response
}

export async function getFamiliesAPI(idToken, accessToken){
    const response = await fetch(`${controller}/families`, {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    })
    return response
}

export async function updateUserAPI(idToken, accessToken, request){
    const response = await fetch(`${controller}/updateuser`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function updatePlanAPI(idToken, accessToken, request){
    const response = await fetch(`${controller}/updateplan`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function updatePaymentAPI(idToken, accessToken, request){
    const response = await fetch(`${controller}/updatepaymethod`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function cancelPlanAPI(idToken, accessToken){
    const response = await fetch(`${controller}/cancelplan`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    })
    return response
}

export async function resumePlanAPI(idToken, accessToken){
    const response = await fetch(`${controller}/resumeplan`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    })
    return response
}

export async function checkCouponAPI(idToken, accessToken, request){
    const response = await fetch(`${controller}/checkcoupon`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        },
        body: JSON.stringify(request)
    })
    return response
}

export async function updatePremiumSMS(idToken, accessToken, enabled){
    const response = await fetch(`${controller}/premiumsms/${enabled}`, {
        method: 'POST',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        },
    })
    return response
}