import react from 'react'

export const testPlans = [
    {
        Name: 'MACE',
        Amount: '$9.99 per Month',
        Family: 50,
        Agency: 0,
        Description: () => <div>
            <ul>
                <li>Track Up To 50 Family Members</li>
            </ul>
        </div>,
        StripeId: 'price_1KbyyeJ4I7MVNjyqHV8PLRMH'
    },
    {
        Name: 'GALA TROPHY',
        Amount: '$19.99 per Month',
        Family: 250,
        Agency: 0,
        Description: () => <div>
            <ul>
                <li>Track Up To 250 Family Members</li>
            </ul>
        </div>,
        StripeId: 'price_1Kbz0AJ4I7MVNjyqcmtYUItN'
    },
    {
        Name: 'IC BAGZ',
        Amount: '$29.99 per Month',
        Family: -1,
        Agency: 0,
        Description: () => <div>
            <ul>
                <li>Track Unlimited Family Members</li>
            </ul>
        </div>,
        StripeId: 'price_1Kbz0MJ4I7MVNjyq6tJXX7ag'
    },
    {
        Name: 'Agency Tracking',
        Amount: '$29.99 per Month',
        Agency: -1,
        Family: 0,
        Description: () => <div>
            <ul>
                <li>Track Unlimited Agency Members</li>
            </ul>
        </div>,
        StripeId: 'price_1Kbz0XJ4I7MVNjyqL5TpzXWE'
    },
    {
        Name: 'Agency + Family Tracking',
        Amount: '$49.99 per Month',
        Family: -1,
        Agency: -1,
        Description: () => <div>
            <ul>
                <li>Track Unlimited Family and Agency Members</li>
            </ul>
        </div>,
        StripeId: 'price_1Kbz0lJ4I7MVNjyq6txgwBf1'
    }
]

export const realPlans = [
    {
        Name: 'MACE',
        Amount: '$9.99 per Month',
        Family: 50,
        Agency: 0,
        Description: () => <div>
            <ul>
                <li>Track Up To 50 Family Members</li>
            </ul>
        </div>,
        StripeId: 'price_1KcLpcJ4I7MVNjyqV7rK8lZr',
        ProductId: 'prod_LIxlPsMTbtjxpV'
    },
    {
        Name: 'GALA TROPHY',
        Amount: '$19.99 per Month',
        Family: 250,
        Agency: 0,
        Description: () => <div>
            <ul>
                <li>Track Up To 250 Family Members</li>
            </ul>
        </div>,
        StripeId: 'price_1KcLr5J4I7MVNjyqOgWIzk30',
        ProductId: 'prod_LIxmjfO9Wf3HDP'
    },
    {
        Name: 'IC BAGZ',
        Amount: '$29.99 per Month',
        Family: -1,
        Agency: 0,
        Description: () => <div>
            <ul>
                <li>Track Unlimited Family Members</li>
            </ul>
        </div>,
        StripeId: 'price_1KcLrjJ4I7MVNjyqBMrCEW9i',
        ProductId: 'prod_LIxnvdLgDQsS86'
    },
    {
        Name: 'Agency Tracking',
        Amount: '$29.99 per Month',
        Agency: -1,
        Family: 0,
        Description: () => <div>
            <ul>
                <li>Track Unlimited Agency Members</li>
            </ul>
        </div>,
        StripeId: 'price_1KcLsLJ4I7MVNjyqYlLbYg5R',
        ProductId: 'prod_LIxnjLoZkwMYyY'
    },
    {
        Name: 'Agency + Family Tracking',
        Amount: '$49.99 per Month',
        Family: -1,
        Agency: -1,
        Description: () => <div>
            <ul>
                <li>Track Unlimited Family and Agency Members</li>
            </ul>
        </div>,
        StripeId: 'price_1KcLt7J4I7MVNjyq6jKSOmQ1',
        ProductId: 'prod_LIxoIzCiSu5xgn'
    }
]

export default testPlans