import React, { useState} from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { loginAPI } from '../../api/auth/login'
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core'

export const Login = ({ props }) => {
    const history = useHistory()
    const isBig = useMediaQuery('(min-width:1200px)')
    const isSmall = useMediaQuery('(max-width:525px)')
    const { setShowLogin, showLogin } = props
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    
    const signin = async (e) => {
        setLoading(true)
        e.preventDefault()
        setMessage('')
        let msg
        try {
            await loginAPI(email, password, () => { props.setLoggedIn(true); })
        }
        catch (error) {
            let finalError = error
            try {
                finalError = await error
            }
            catch (err) { }
            msg = finalError
        }
        // Notify
        if (msg) {
            console.log(msg)
            if (msg.message === 'Failed to fetch' || msg === '503') {
                msg = 'Currently Under Maintenance'
            }
            setMessage(msg.toString())
        }
        setLoading(false)
    }

    const redirectToPWReset = (e) => {
        e.preventDefault()
        setShowLogin && setShowLogin(false)
        history.push('/resetPassword')
    }

    return (
        <div style={{ display: showLogin ? 'flex' : 'none', backgroundColor: 'rgba(255, 255, 255, 0.7)', justifyContent: 'center', paddingTop: '6vh', position:'absolute', width:'100vw', top: !isBig ? '170px' : '' }}>
            <Form style={{ width: isBig ? '30vw' : isSmall ? '80vw' : '65vw' }} onSubmit={signin}>
                <Form.Group as={Row}>
                    <Form.Label column xs={3} style={{ fontWeight: 'bold' }}>Email</Form.Label>
                    <Col>
                        <Form.Control required={true} type="email" onChange={(e) => setEmail(e.target.value)}></Form.Control>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column xs={3} style={{ fontWeight: 'bold' }}>Password</Form.Label>
                    <Col>
                        <Form.Control required={true} type="password" onChange={(e) => setPassword(e.target.value)}></Form.Control>
                    </Col>
                </Form.Group>
                {message && (
                    <Form.Group as={Row} style={{ justifyContent: 'center' }}>
                        <Form.Label style={{  color: 'red', fontWeight: 'bold' }}>{message}</Form.Label>
                    </Form.Group>
                )}
                {/* This needs to know if we are visbile or not */}
                <div style={{ fontSize: '.75rem', justifyContent: 'flex-end', display: 'flex', paddingRight: '1rem' }}>
                    <a href="" onClick={(e) => { redirectToPWReset(e) }}>Forgot Password?</a>
                </div>
                <Form.Group as={Row} style={{ justifyContent: 'center' }}>
                    <Button variant="primary" disabled={!showLogin || loading} type="submit">Submit</Button>
                </Form.Group>
            {loading &&
                <Row style={{justifyContent: 'center'}}>
                    <CircularProgress />
                </Row>}
            </Form>
        </div>
    )
}