const imageColumns = {
    None: 0,
    BigoId: 1,
    Event: 2,
    Details: 4,
    Opponent: 8,
    Reward: 16,
    Pacific: 32,
    Mountain: 64,
    Central: 128,
    Eastern: 256,
    Family: 512,


    All: 1023
}

export default imageColumns