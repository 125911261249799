import { useMediaQuery } from '@material-ui/core'
import { PeopleAlt, Settings, Assessment, History, Dashboard, AccountTree, VerticalSplit, LocalPlay, Kitchen, CameraAlt, PhoneAndroid, BubbleChart, HelpOutline } from '@material-ui/icons'
import { FactCheck } from '@mui/icons-material'
import React, { Fragment, useState, useEffect } from 'react'
import { realPlans as testPlans} from '../../enums/bigoPlans/defaultPlan'
import userPages from '../../enums/userPages'
import userStatus from '../../enums/userStatus'
import DiscordIcon from '../../images/svgIcons/discord'
import { LeftNavTab, LeftSubNavTab } from './leftNavTab'


export const LeftNav = ({HomeAccount, props}) => {

    const [allowFamily, setAllowFamily] = useState(false)
    const [allowAgency, setAllowAgency] = useState(false)
    const isSmall = useMediaQuery('(max-width:900px)')

    useEffect(() => {
        
        if (props.user && props.sub && props.sub.UserStatus === userStatus.Paid && props.sub.SubscriptionId){
            const matchPlan = testPlans.filter(x => x.StripeId === props.sub.PlanId)[0]
            if (matchPlan){
                setAllowFamily(matchPlan.Family !== 0)
                setAllowAgency(matchPlan.Agency !== 0)
            }
            else if (props.sub.SubscriptionId === 'MASTER'){
                setAllowFamily(true)
                setAllowAgency(true)
            }
            else{
                setAllowAgency(false)
                setAllowFamily(false)
            }
        }
        else{
            setAllowAgency(false)
            setAllowFamily(false)
        }
    }, [props.sub, props.user])

    return (
        <div style={{display: 'flex', flexDirection: 'column', marginTop: '10vh', maxHeight: '90vh', overflowY: 'auto', position: 'relative', height: '100%'}}>
            {HomeAccount 
            ? (
                // We only care if they have an account
                <Fragment>
                </Fragment>
            )
            : (
                <Fragment>   
                     <LeftNavTab
                        Icon={Settings}
                        text="User Settings"
                        selectKey={userPages.BigoUser}
                        selector={props.setSelectedPage}
                        selected={props.selectedPage}
                    />               
                    {props.selectedPage === userPages.BigoUser && <Fragment>
                        <LeftSubNavTab
                            Icon={DiscordIcon}
                            text="Discord"
                            selectKey={userPages.BigoDiscordSettings}
                            selector={props.setSelectedSubPage}
                            selected={props.selectedSubPage}
                            disabled={!allowFamily && !allowAgency}
                        />
                    </Fragment>}  
                    <LeftNavTab
                        Icon={PeopleAlt}
                        text="Family Management"
                        selectKey={userPages.BigoFamily}
                        selector={props.setSelectedPage}
                        selected={props.selectedPage}
                        disabled={!allowFamily}
                    />
                    {props.selectedPage === userPages.BigoFamily && <Fragment>
                        <LeftSubNavTab
                            Icon={FactCheck}
                            text="Sign Ups"
                            selectKey={userPages.BigoFamilySignUps}
                            selector={props.setSelectedSubPage}
                            selected={props.selectedSubPage}
                        />
                    </Fragment>}
                    <LeftNavTab
                        Icon={BubbleChart}
                        text="Agency Management"
                        selectKey={userPages.BigoAgency}
                        selector={props.setSelectedPage}
                        selected={props.selectedPage}
                        disabled={!allowAgency}
                    />
                    {props.selectedPage === userPages.BigoAgency && <Fragment>
                        <LeftSubNavTab
                            Icon={Assessment}
                            text="Tracking"
                            selectKey={userPages.BigoAgencyTracking}
                            selector={props.setSelectedSubPage}
                            selected={props.selectedSubPage}
                        />
                        <LeftSubNavTab
                            Icon={Dashboard}
                            text="Dashboard"
                            selectKey={userPages.BigoAgencyDashboard}
                            selector={props.setSelectedSubPage}
                            selected={props.selectedSubPage}
                        />
                        <LeftSubNavTab
                            Icon={FactCheck}
                            text="Sign Ups"
                            selectKey={userPages.BigoAgencySignUps}
                            selector={props.setSelectedSubPage}
                            selected={props.selectedSubPage}
                        />
                        <LeftSubNavTab
                            Icon={History}
                            text="History"
                            selectKey={userPages.BigoAgencyHistory}
                            selector={props.setSelectedSubPage}
                            selected={props.selectedSubPage}
                        />
                    </Fragment>}
                               
                    <LeftNavTab
                        Icon={HelpOutline}
                        text="Help"
                        selectKey={userPages.BigoHelp}
                        selector={props.setSelectedPage}
                        selected={props.selectedPage}
                    />
                </Fragment>
            )
            }
            
            {HomeAccount && (
                <Fragment>                    
                    
                </Fragment>
            )}
            {!isSmall && <div style={{
                position: 'absolute',
                bottom: '0px',
                width: '100%',
                textAlign: 'center'
            }}>
                <div>Build {props.frontBuild}</div>
                <div>v{(props.buildNumber && props.buildNumber.buildnumber) || '1'}</div>
            </div>}
        </div>
    )
}