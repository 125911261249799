import React, { useState, useEffect, useRef, Fragment } from 'react'
import { Form, Row, Col, Button, InputGroup } from 'react-bootstrap'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import {
    productDetail, listIngredients, updateProduct, searchProductTags,
    updateProductItemCategories, updateProductAddons, updateProductIngredients, updateProductTags, cloneProduct
} from '../../api/sockets/product/productRequests'
import { loadProductCategories } from '../../api/sockets/account/accountRequests'
import { ProductImageUploader } from './productImageUploader'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Input, MenuItem, FormControl, ListItemText, Select, Checkbox, Chip, Paper, List, ListItem } from '@material-ui/core'
import { IngredientList } from './viewProductComp/ingredientList'
import { IngredientSelectedList } from './viewProductComp/ingredientSelectedList'
import { AddonList } from './viewProductComp/addonList'
import { AddonSelectedList } from './viewProductComp/addonSelectedList'
import { useDebounce } from '../../utility/commands'
import CurrencyInput from '../utility/currencyInput'
import { ArrowBackIos } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip'
import productFlags from '../../enums/productFlags'
import { DeleteProduct } from './viewProductComp/deleteProduct'
import { HelpPopover } from '../utility/helpPopover'




const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
        maxWidth: 300,
        color: '#495057',
        backgroundColor: '#ffffff',
        backgroundClip: 'padding-box',
        border: '1px solid #ced4da',
        borderRadius: '.25rem',
        alignItems: 'left'
    },
    nounderline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    },
    root: {
        margin: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    }
}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 15;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            minWidth: 250
        },
    },
    getContentAnchorEl: null
}

export const ViewProduct = ({ props }) => {
    const classes = useStyles()

    const mainForm = useRef(null)

    const { socket, notify, productId, back, reloadList, setShowProductId } = props
    const isSmall = useMediaQuery('(max-width:525px)')
    const [loading, setLoading] = useState(true)

    // Product
    const [newProduct, setNewProduct] = useState({})
    const [priorState, setPriorState] = useState({})
    const [fullProduct, setFullProduct] = useState(null)
    const [vps, setVPs] = useState('0')
    const [prodCost, setProdCost] = useState('0')
    const [profitWarn, setProfitWarn] = useState(false)
    const [showDelete, setShowDelete] = useState(false)

    // Category
    const [category, setCategory] = useState([])
    const [selectedCategoryId, setSelectedCategoryId] = useState([])

    // Ingredients
    const [allIngredients, setAllIngredients] = useState([])
    const [notSelectedIngredients, setNotSelectedIngredients] = useState([])
    const [selectedIngredients, setSelectedIngredients] = useState([])

    // Addons
    const [allAddons, setAllAddons] = useState([])
    const [notSelectedAddons, setNotSelectedAddons] = useState([])
    const [selectedAddons, setSelectedAddons] = useState([])

    // Tags
    const [tags, setTags] = useState([])
    const [searchTags, setSearchTags] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [isSearching, setIsSearching] = useState(false)
    const debouncedSearchTerm = useDebounce(searchTerm, 350)

    useEffect(
        () => {
            if (debouncedSearchTerm) {
                setIsSearching(true);
                 if (debouncedSearchTerm && !isSearching) {
                    socket.props.notify = notify
                    socket.props.setLoading = setIsSearching
                    socket.props.onSearchTagSuccess = (response) => {
                        setSearchTags(response)
                    }
                    socket.props.onSearchTagFailure = () => {
                        notify('Update Error', 'Error Seaching Tags')
                    }

                    const requestData = {
                        PartialTag: debouncedSearchTerm,
                        ExistingTags: tags.map(x => x.Name)
                    }
                    searchProductTags(requestData, socket)
                }
            } else {
                setSearchTags([])
            }
        },
        [debouncedSearchTerm]
    )

    const handleRemoveTag = (removeTag) => {
        const newTags = tags.filter((x) => x.Name !== removeTag.Name)
        sendProductTags(null, newTags)
    }

    const handleAddTag = (tagToAdd) => {
        if (tagToAdd && !tags.some(x => x.Name === tagToAdd)) {
            let newTags = tags.concat({ Name: tagToAdd })
            sendProductTags(null, newTags)
        }
    }

    const sendProductTags = (e, tagsToUpdate) => {
        if (!loading) {
            setLoading(true)
            if(!tagsToUpdate) {
                tagsToUpdate = [...tags]
            }
            socket.props.notify = notify
            socket.props.setLoading = setLoading

            socket.props.onUpdateProductTagsFailure = () => {
                notify('Update Error', 'Error Updating Tags')
            }

            socket.props.onUpdateProductTagsSuccess = () => {
                setTags(tagsToUpdate)
                setSearchTerm('')
            }
            
            const requestData = {
                ProductId: productId,
                ProductTags: tagsToUpdate
            }
            updateProductTags(requestData, socket)
            setSearchTags([])
        }
    }

    useEffect(() => {
        if (socket !== null && fullProduct === null) {
            reloadDetail()
        }
        else if (fullProduct !== null) {
            if (!category.length) {
                loadCategories()
            }
            if (!allIngredients.length) {
                loadIngredientsAndAddons()
            }
        }
    }, [socket, fullProduct])

    const loadCategories = () => {
        socket.props.notify = notify
        socket.props.setLoading = setLoading
        socket.props.onListCategorySuccess = (response) => {
            setCategory(response)
        }

        const requestData = {
            ActiveFilter: 0 // active
        }
        loadProductCategories(requestData, socket)
    }

    const sendProductCategories = () => {
        socket.props.notify = notify
        let productItemCategories = selectedCategoryId.map(id => ({ ProductCategoryId: id }))

        const requestData = {
            ProductId: productId,
            ProductItemCategories: productItemCategories
        }

        updateProductItemCategories(requestData, socket)
    }

    const loadIngredientsAndAddons = () => {
        let ingredientsLocal = sessionStorage.getItem('ingredientList')
        if (ingredientsLocal) {
            setTransferListData(JSON.parse(ingredientsLocal), false)
        }
        else {
            socket.props.notify = notify
            socket.props.setLoading = setLoading
            socket.props.onListIngredientsSuccess = (response) => {
                setTransferListData(response, true)
            }
            socket.props.onListIngredientsFailure = () => {
                notify('Error', 'Error Loading Product Ingredients')
            }

            const requestData = {} // Currently there is no data for the request

            listIngredients(requestData, socket)
        }
    }

    const setTransferListData = (transferItems, setLocalStorage) => {
        // Set the full list
        let ingredients = transferItems
        setAllIngredients(ingredients)

        let addons = transferItems.filter(x => x.IsAddon)
        setAllAddons(addons)
        if (setLocalStorage) {
            sessionStorage.setItem('ingredientList', JSON.stringify(transferItems))
        }

        // Left side of the transfer list
        let selectedIngredientIds = fullProduct.ProductIngredients.map(x => x.IngredientId)
        setNotSelectedIngredients(ingredients.filter(x => !selectedIngredientIds.includes(x.IngredientId)))
        setSelectedIngredients(ingredients.filter(x => selectedIngredientIds.includes(x.IngredientId)).map(x => {
            x.Amount = fullProduct.ProductIngredients.filter(y => y.IngredientId === x.IngredientId)[0]?.Amount || 0
            return x
        }))

        let selectedAddonIds = fullProduct.ProductAddons.map(x => x.IngredientId)
        setNotSelectedAddons(addons.filter(x => !selectedAddonIds.includes(x.IngredientId)))
        setSelectedAddons(addons.filter(x => selectedAddonIds.includes(x.IngredientId)).map(x => {
            x.Price = fullProduct.ProductAddons.filter(y => y.IngredientId === x.IngredientId)[0]?.Price || 0
            x.DefaultOn = fullProduct.ProductAddons.filter(y => y.IngredientId === x.IngredientId)[0]?.DefaultOn || false
            return x
        }))

        calcVPs(fullProduct.ProductIngredients)
    }

    const sendProductIngredients = (newList) => {
        socket.props.notify = notify
        if (!newList) {
            newList = selectedIngredients
        }
        const sendIngredients = newList.map(x => {
            return {
                IngredientId: x.IngredientId,
                Amount: x.Amount
            }
        })

        const requestData = {
            ProductId: productId,
            ProductIngredients: sendIngredients
        }

        updateProductIngredients(requestData, socket)

        calcVPs(sendIngredients)
    }

    const sendProductAddons = (newList) => {
        socket.props.notify = notify

        if (!newList) {
            newList = selectedAddons
        }

        const sendAddons = newList.map(x => {
            const container = {}
            container.IngredientId = x.IngredientId
            container.DefaultOn = x.DefaultOn
            container.Price = x.Price
            return container
        })

        const requestData = {
            ProductId: productId,
            ProductAddons: sendAddons
        }

        updateProductAddons(requestData, socket)
    }

    const reloadDetail = () => {
        setLoading(true)
        socket.props.notify = notify

        socket.props.onProductDetailSuccess = (response) => {
            setFullProduct(response)
            const clone = Object.assign({}, response)
            clone.ProductId = productId
            clone.Price = clone.Price.toFixed(2)
            delete clone.ProductAddons
            delete clone.ProductTags
            delete clone.ProductIngredients
            delete clone.ImageLocation
            setNewProduct(clone)
            setPriorState(clone)
            setSelectedCategoryId(response.ProductCategories.map(x => x.ProductCategoryId))
            setTags(response.ProductTags)
        }
        socket.props.onProductDetailFailure = () => {
            notify('Error', 'Error Loading Product')
        }

        const requestData = {
            ProductId: productId
        }

        productDetail(requestData, socket)
    }

    const updateProp = (x, propName) => {
        const clone = Object.assign({}, newProduct)
        clone[propName] = x
        setNewProduct(clone)

        if (propName === 'Enabled') {
            saveBaseProduct('Enabled', clone)
        }
        else if (propName === 'IsDefaultSize') {
            saveBaseProduct('IsDefaultSize', clone)
        }
    }

    const saveBaseProduct = (prop, ovrNewProd) => {
        if (!ovrNewProd) {
            ovrNewProd = newProduct
        }

        if (priorState[prop] !== ovrNewProd[prop]) {
            setTimeout(() => mainForm && mainForm.current && mainForm.current.click(), 200)
        }
    }

    const validateAndUpdate = (e) => {
        e.preventDefault()
        console.log(newProduct)
        setLoading(true)
        socket.props.notify = notify
        socket.props.setUpdateLoading = setLoading
        const clone = Object.assign({}, newProduct)
        socket.props.onUpdateProductSuccess = (response) => {
            setPriorState(clone)

        }
        socket.props.onUpdateFailure = (msg) => {
            notify('Update Error', 'Error Updating Product')
        }

        updateProduct(newProduct, socket)
    }

    const calcVPs = (newIngredients) => {
        let ingredientsLocal = JSON.parse(sessionStorage.getItem('ingredientList'))
        if (!newProduct || !ingredientsLocal) {
            return '0'
        }

        if (!newIngredients) {
            newIngredients = selectedIngredients
        }

        let total = 0
        let totalCost = 0
        newIngredients.forEach(x => {
            const match = ingredientsLocal.filter(y => y.IngredientId === x.IngredientId)[0]
            if (match) {
                total += x.Amount * match.VolumePointsPerUnit
                totalCost += x.Amount * match.DiscountedPrice
            }
        })

        setVPs(total.toFixed(2))
        setProdCost(totalCost.toFixed(2))
        setProfitWarn(Number(totalCost.toFixed(2)) >= newProduct.Price)
    }

    const cloneThisProduct = () => {
        const requestData = {
            ProductId: productId
        }

        socket.props.onFailure = (msg) => {
            notify('Clone Error', msg)
        }
        socket.props.onSuccess = (data) => {
            setShowProductId(data.NewProductId)
            window.location.reload() // Lazy but oh well
        }

        cloneProduct(requestData, socket)
    }

    const updateProductFlags = (set, flag) => {
        const clone = Object.assign({}, newProduct)
        if(set) {
            clone.Flags |= flag
        }
        else {
            clone.Flags &= ~flag
        }
        setNewProduct(clone)
        saveBaseProduct('Flags', clone)
    }

    const handleManualTagAdd = (e) => {
        if (e && e.key === 'Enter' && e.target.value) {
            handleAddTag(e.target.value)
        }
    }

    return (
        <Fragment>
            <Form onSubmit={validateAndUpdate} onKeyDown={(e) => e.key === 'Enter' && e.target.type !== 'textarea' ? e.preventDefault() : ''  }>
                <Row style={{ marginBottom: '30px' }}>
                    <Col xs={0} md={1}></Col>
                    <Col>
                        <h2 style={{ display: 'flex', alignItems: 'center' }}>
                            <ArrowBackIos style={{ fontSize: '2rem', cursor: 'pointer' }} onClick={() => { back(); reloadList(); }} />
                            Edit Product
                        </h2>
                    </Col>
                    <Col xs={4} md={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button onClick={cloneThisProduct}>
                            Clone
                        </Button>
                        <Button variant="danger" style={{marginLeft: '.5rem'}} disabled={!fullProduct?.CanDelete} onClick={() => setShowDelete(true)}>Delete</Button>
                          <DeleteProduct 
                                productName={fullProduct && fullProduct.Name}
                                productId={productId}
                                show={showDelete}
                                socket={socket}
                                successCallBack={() => {setShowProductId(null); reloadList(); notify('Success', `${fullProduct.Name} was deleted.`)}}
                                onHide={() => setShowDelete(false)}
                           />
                        {fullProduct && !fullProduct.CanDelete && <HelpPopover message={"Cannot delete this product. This product has Order Items associated with it."} />}
                    </Col>
                </Row>
                <Row style={{ marginBottom: '1rem' }}>
                    <Col xs={1} md={2}></Col>
                    <Col>
                        <h4>Details</h4>
                    </Col>
                </Row>
                <Form.Group as={Row}>
                    <Form.Label column xs={4} md={2} style={{marginBottom: '10px', textAlign: 'end' }}>
                        Product Name
                    </Form.Label>
                    <Col xs={7} md={3}>
                        <Form.Control value={newProduct.Name || ''} onChange={(e) => updateProp(e.target.value, 'Name')} required maxLength={200} onBlur={() => saveBaseProduct('Name')} />
                    </Col>
                    <Form.Label column xs={4} md={2} style={{ textAlign: 'end', marginBottom: '10px' }}>
                        Description
                    </Form.Label>
                    <Col xs={7} md={4} lg={4} xl={3}>
                        <Form.Control as="textarea" rows={3} value={newProduct.Description || ''} onChange={(e) => updateProp(e.target.value, 'Description')} maxLength={500} style={{ resize: 'none' }} onBlur={() => saveBaseProduct('Description')} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column xs={4} md={2} style={{ marginBottom: '10px', textAlign: 'end' }}>
                        Price
                    </Form.Label>
                    <Col xs={7} md={3}>
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput placeholder="0.00" className="form-control" value={newProduct.Price} onChange={(e) => updateProp(e.target.value, 'Price')} required maxLength={8} onBlur={() => { saveBaseProduct('Price'); calcVPs() }} />
                        </InputGroup>
                    </Col>
                    <Form.Label column xs={4} md={2} style={{ marginBottom: '10px', textAlign: 'end' }}>
                        Tax Percent
                    </Form.Label>
                    <Col xs={3} md={2} lg={2}>
                        <Form.Control value={newProduct.TaxPercent || '0.00'} onChange={(e) => updateProp(e.target.value, 'TaxPercent')} maxLength={6} required onBlur={() => saveBaseProduct('TaxPercent')} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column xs={4} md={2} style={{ marginBottom: '10px', textAlign: 'end' }}>
                        Size
                    </Form.Label>
                    <Col xs={7} md={3}>
                        <Form.Control value={newProduct.SizeDetails || ''} onChange={(e) => updateProp(e.target.value, 'SizeDetails')} required maxLength={100} onBlur={() => saveBaseProduct('SizeDetails')} />
                    </Col>
                    <Form.Label column htmlFor="enabledChk" xs={4} md={2} style={{ marginBottom: '10px', textAlign: 'end' }}>
                        Enabled
                    </Form.Label>
                    <Col xs={4} md={2} align="left">
                        <Form.Check id="enabledChk" type="switch" checked={newProduct.Enabled || false} onChange={(e) => updateProp(e.target.checked, 'Enabled')} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column xs={4} md={2} style={{ marginBottom: '10px', textAlign: 'end' }}>
                        Recipe
                    </Form.Label>
                    <Col xs={7} md={3}>
                        <Form.Control as="textarea" rows={3} value={newProduct.Recipe || ''} onChange={(e) => updateProp(e.target.value, 'Recipe')} maxLength={500} style={{ resize: 'none' }} onBlur={() => saveBaseProduct('Recipe')} />
                    </Col>
                    <Form.Label column xs={4} md={2} style={{ textAlign: 'end', marginBottom: '10px' }}>
                        Category
                    </Form.Label>
                    <Col xs={7} md={3} style={{ textAlign: 'left' }}>
                        <FormControl className={classes.formControl}>
                            <Select
                                multiple
                                className={classes.nounderline}
                                value={selectedCategoryId}
                                onChange={(e) => setSelectedCategoryId(e.target.value)}
                                onClose={sendProductCategories}
                                input={<Input />}
                                renderValue={(selected) => {
                                    return category.filter(x => selected.includes(x.ProductCategoryId)).map(x => x.Name).join(', ')
                                }}
                                MenuProps={MenuProps}
                            >
                                {category.map((x) => (
                                    <MenuItem key={x.ProductCategoryId} value={x.ProductCategoryId}>
                                        <Checkbox checked={selectedCategoryId.indexOf(x.ProductCategoryId) > -1} />
                                        <ListItemText primary={x.Name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px', textAlign: 'end' }}>
                        Tags
                    </Form.Label>
                    <Col xs={7} md={3} style={{ marginBottom: '10px', marginTop:'10px' }}>
                            {tags.map((data, i) => {
                                return (
                                    <Chip
                                    label={data.Name}
                                    onDelete={() => handleRemoveTag(data)}
                                    color="primary"
                                    style={{marginBottom: '.5rem'}}
                                    key={i}
                                    />
                                )
                            })}
                        <Form.Control
                            value={searchTerm}
                            placeholder="Search Tags"
                            onChange={e => setSearchTerm(e.target.value)}
                            onKeyDown={e => handleManualTagAdd(e)}
                            onBlur={sendProductTags}
                        />
                        <List style={{ zIndex:"10",position:"absolute", background: 'white',  width:"85", display: searchTags.length > 0 ? "block" : "none" }}>
                            {searchTags.map((data) => {
                                return (
                                    <ListItem>
                                        <ListItemText
                                            primary={data}
                                            onClick={() => handleAddTag(data)}
                                            color="primary"
                                        />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Col>
                    <Form.Label column xs={4} md={2} style={{textAlign: 'end', marginBottom: '10px'}}>
                        Volume Points
                    </Form.Label>
                    <Col xs={7} md={3} style={{ textAlign: 'left' }}>
                        <Form.Control value={vps} disabled />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                <Form.Label column xs={4} md={2} style={{textAlign:'end', marginBottom: '10px'}}>
                        Ingredient Cost
                    </Form.Label>
                    <Col xs={7} md={3} style={{ textAlign: 'left' }}>
                        <InputGroup >
                            <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control value={prodCost} disabled />
                            {profitWarn && (
                                <div className="invalid-feedback" style={{display: 'block', fontWeight: 'bold'}}>
                                    Not Profitable!
                                </div>
                            )}
                        </InputGroup>
                    </Col>
                    <Form.Label column xs={4} md={2} style={{textAlign:'end', marginBottom: '10px'}}>
                        Is Default Size
                    </Form.Label>
                    <Col xs={7} md={3} style={{ textAlign: 'left' }}>
                    <Form.Check id="isDefaultSizeChk" type="switch" checked={newProduct.IsDefaultSize || false} onChange={(e) => updateProp(e.target.checked, 'IsDefaultSize')} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column xs={4} md={2} style={{textAlign: 'end', marginBottom: '10px'}}>
                      Hide Ingredient Selection
                    </Form.Label>
                        <Col xs={7} md={3} style={{ textAlign: 'left' }}>
                            <Tooltip title="Hide the ability for customers to select ingredients">
                                <Form.Check 
                                    type="switch" 
                                    onChange={(e) => updateProductFlags(e.target.checked, productFlags.NoRemoveItems)}
                                    checked={newProduct.Flags & productFlags.NoRemoveItems == productFlags.NoRemoveItems} 
                                    id="isHideIngredientChk"
                                /> 
                            </Tooltip>
                        </Col>
                </Form.Group>
                <Row style={{ marginTop: isSmall ? '0px' : '30px' }}>
                    <Col xs={0} md={1}></Col>
                    <Col xs={12} md={4} style={{ marginBottom: isSmall ? '1rem' : 0 }}>
                        <IngredientList
                            props={{
                                setNotSelectedIngredients, setSelectedIngredients,
                                allIngredients, notSelectedIngredients, selectedIngredients
                            }}
                        />
                    </Col>
                    <Col xs={0} md={1}></Col>
                    <Col xs={12} md={4} style={{ marginBottom: isSmall ? '1rem' : 0 }}>
                        <IngredientSelectedList
                            props={{
                                setNotSelectedIngredients, setSelectedIngredients, sendProductIngredients,
                                allIngredients, notSelectedIngredients, selectedIngredients
                            }}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: isSmall ? '0px' : '30px', marginBottom: '50px' }}>
                    <Col xs={0} md={1}></Col>
                    <Col xs={12} md={4}>
                        <AddonList
                            props={{
                                setNotSelectedAddons, setSelectedAddons,
                                allAddons, notSelectedAddons, selectedAddons
                            }}
                        />
                    </Col>
                    <Col xs={0} md={1}></Col>
                    <Col xs={12} md={4} style={{ marginTop: isSmall ? '1rem' : 0 }}>
                        <AddonSelectedList
                            props={{
                                setNotSelectedAddons, setSelectedAddons, sendProductAddons,
                                allAddons, notSelectedAddons, selectedAddons
                            }}
                        />
                    </Col>
                </Row>

                <ProductImageUploader productId={productId} socket={socket} existingPath={fullProduct && fullProduct.ImageLocation} />

                <Row style={{ marginTop: isSmall ? '0px' : '30px', marginBottom: '10px' }}>
                    <Col>
                        <Row style={{ justifyContent: 'center', marginBottom: '10px' }}>
                            <Button style={{ display: 'none' }} ref={mainForm} type="submit"></Button>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Fragment>
    )
}