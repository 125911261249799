import { Form } from 'react-bootstrap'

export const TimeZonePicker = ({getter, setter}) => {
    
    const names = ['UTC', 'Atlantic', 'Eastern', 'Central', 'Mountain', 'Pacific', 'Alaska', 'Hawaii']
    const offsets = [0, -4, -5, -6, -7, -8, -9, -10]

    return <Form.Control as="select" value={getter || 0} onChange={setter}>
        {names.map((x, i) => {
            return <option value={offsets[i]} key={x}>
                {x} GMT-{Math.abs(offsets[i])}
            </option>
        })}
    </Form.Control>
    
}