import { Row, Button } from 'react-bootstrap'

export const SalePanel = ({name, amount, Details, id, idSetter, selected}) => {

    return <div style={{
        border: '1px solid black',
        borderRadius: '20px',
        minHeight: '250px',
        textAlign: 'center'
    }}>
        <div style={{
            textAlign: 'center',
            backgroundColor: 'lightblue',
            borderRadius: '20px 20px 0px 0px',
            paddingTop: '5px',
            paddingBottom: '5px'
        }}><h3>{name}</h3>
        </div>
        <hr style={{marginTop: '3px'}} />
        <h4>{amount}</h4>
        <div style={{
            display: 'flex',
            width: '80%',
            justifyContent: 'center',
            marginTop: '15px',
            marginBottom: '10px'
        }}>
            {/* <div> */}
                <Details />
            {/* </div> */}
        </div>
        <Row style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            paddingBottom: '10px'
        }}>
            {idSetter && (selected
                ? <span style={{fontWeight: 'bold'}}>Current Plan</span>
                : <Button onClick={() => idSetter(id)}>Select</Button>)
            }
        </Row>
    </div>
}