import { baseURL } from "../base"

const controller = `${baseURL}/content`

export async function getHelpVideosAPI(idToken, accessToken){
    const response = await fetch(`${controller}/helpvideos`, {
        method: 'GET',
        mode: 'cors',
        headers: {            
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'bearer ' + idToken,
            'Access': accessToken
        }
    })
    return response
}