import { Fragment, useState } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import './App.css'
import { GeneralNav } from './components/navigation/generalnav';
import { BigoLanding } from './components/public/bigolanding';

import { checkSession, tokensExist } from './api/auth/session'
import { BigoHome } from './components/public/bigohome'

import { SignUp } from './components/public/signup'
import { ViewStore } from './components/public/viewStore';
import { FBLogin } from './components/public/fbLogin';
import { ForgotPassword } from './components/public/forgotPassword'
import { EULA } from './components/legal/eula';
import { PrivacyPolicy } from './components/legal/privacy';
import { DiscordLink } from './components/bigo/discordLink';
import { FamilySelfSignUp } from './components/bigo/public/familySelfSignup';
import { AgencySelfSignUp } from './components/bigo/public/agencySelfSignup';

function App() {
  const publicPaths = ['/', '/signup', '/fblogin', '/resetPassword', '/eula', '/privacypolicy']

  const isStoreView = false
  const isStoreSite = false

  // Session Storage variables
  const operatorAccountId = Number(sessionStorage.getItem('OPERATOR_ACCOUNT_ID'))
  const operatorAccountName = sessionStorage.getItem('OPERATOR_ACCOUNT_NAME')
  var isSignUpPage = window.location.pathname.startsWith('/family/') || window.location.pathname.startsWith('/agency/')
  if (!publicPaths.includes(window.location.pathname) && !isSignUpPage){
     checkSession()
  }

  const hasTokens = tokensExist(false, true)
  // const hasTokens = false;
  const [loggedIn, setLoggedIn] = useState(hasTokens)
  const [mainSocket, setMainSocket] = useState(null)
  const [socketAuthed, setSocketAuthed] = useState(false)
  const [socketReconnecting, setSocketReconnecting] = useState(false)
  const [showLogin, setShowLogin] = useState(false)
  const [customerSocket, setCustomerSocket] = useState(null)

  // Store Info  
  const [categories, setCategories] = useState([])
  const [categoryLoading, setCategoryLoading] = useState(true)
  const [storeInfo, setStoreInfo] = useState(null)
  const [storeInfoLoading, setStoreInfoLoading] = useState(true)
  const [doneLoading, setDoneLoading] = useState(false)

  const storeInfoBundle = { categories, setCategories, categoryLoading,
    setCategoryLoading, storeInfo, setStoreInfo, storeInfoLoading, setStoreInfoLoading,
    socketReconnecting, setSocketReconnecting, doneLoading, setDoneLoading
  }

  const viewStoreProps = {
    socket: customerSocket,
    setSocket: setCustomerSocket,
    isStoreView,
    isStoreSite,
    props: storeInfoBundle
  }

  const kioskProps = {
    storeSocket: mainSocket,
    setStoreSocket: setMainSocket,
    socketAuthed,
    setSocketAuthed,
    isStoreView,
    isStoreSite
  }

  const operatorProps = {
    socket: mainSocket,
    setSocket: setMainSocket,
    accountId: operatorAccountId,
    accountName: operatorAccountName,
    socketAuthed,
    setSocketAuthed,
    socketReconnecting,
    setSocketReconnecting
  }

  return (
    <Fragment>
      <Router>
        
        {/* Not Logged In Nav */}
        {!loggedIn && !isSignUpPage && <GeneralNav props={{setLoggedIn, setShowLogin, showLogin}} />}

        {/* Landing Page or Home Dashboard if signed in */}
        <Route path="/" exact 
          component={
            isStoreSite
            ? () => ViewStore(viewStoreProps)
            : loggedIn 
              ? () => BigoHome() 
              : BigoLanding
          } 
        /> 
        <Route path="/signup" exact component={SignUp} />
        <Route path="/resetPassword" exact component={ForgotPassword} />
        {/* Back End Signed in */}
        <Route path="/dashboard" exact component={loggedIn ? BigoHome : BigoLanding} />

        <Route path='/eula' exact component={EULA} />
        <Route path='/privacypolicy' exact component={PrivacyPolicy} />

        <Route path="/discord" component={DiscordLink} />

        <Route path="/fblogin" exact component={FBLogin} />

        <Route path="/family/:code" component={FamilySelfSignUp} />
        <Route path="/agency/:code" component={AgencySelfSignUp} />

        {/* <Route component={NotFound} /> */}
      </Router>
    </Fragment>
  );
}

export default App;
