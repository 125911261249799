import React from 'react'
import { Assessment } from '@material-ui/icons'
import { useMediaQuery, Typography, makeStyles } from '@material-ui/core'
import { Row, Col } from 'react-bootstrap'
import famHeaderLogo from '../../images/landing/FamHeaderLogo.png'

const useStyles = makeStyles({
    coverPhoto: {
        width: '100%',
        height: '90vh',
        position: 'absolute',
        backgroundSize: 'cover',
        backgroundColor: 'black',
        backgroundRepeat: 'repeat',
        // backgroundImage: `url(${sideLeaf})`,
        // backgroundBlendMode: 'luminosity'
    },
    title: {
        paddingTop: '20vh', // we may want to do something here for the sign dropdown
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white'
    },
    subtitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: 'white'
    },
    paragrapgh: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white'
    },
    infoCard: {
        height: '350px',
        backgroundColor: 'rgba(255,255,255,0.35)',
        // boxShadow: '5px 10px 20px rgb(255 255 255 / 18%)',        
        boxShadow: '0 0 10px 10px black inset',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    pictureCard: {
        width:'100px',
        height: '350px',
        textAlign: 'center'
    },
    picture: {
        width:'100px',
        height: '100px'
    },
    landingBackground: {
        backgroundColor: "black"
    },
    snapshotPhoto: {
        width: '100%',
        height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        //backgroundImage: `url(${dailySnapshot})`,
        backgroundPosition: 'center',
        alignItems: 'center',
        marginBottom: '10px'
    },
    productPhoto: {
        width: '100%',
        height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        //backgroundImage: `url(${productCategories})`,
        backgroundPosition: 'center',
        alignItems: 'center', marginBottom: '10px'
    }
})

export const BigoLanding = () => {
    const classes = useStyles()
    const isMd = useMediaQuery('(min-width:768px)')

    // Current Pallete suggestion
    // Greens
    //#082d0f - D
    //#235413 - DM
    //#5e9146 - LM
    //#9fd68d - L

    // Black/White
    //#ffffff - Black
    //#2b2e2b - D Grey
    //#626662 - L Grey
    //#000000 - White

    // Blue
    //#070559 - D
    //#211f73 - DM
    //#302db3 - LM
    //#322ed1 - L

    // Red
    //#6e0505 - D
    //#9e1b1b - DM
    //#ed4545 - LM
    //#f25555 - L

    // TODO: Fix hamburger and sign in overlap issue
    return (
        <div className={classes.landingBackground} >
            {/* <img className={classes.coverPhoto} style={{alignItems: 'center', marginBottom: '10px'}} alt="" /> */}
            <Row>
                <Col style={{textAlign: 'center', paddingTop: '50px', paddingBotton: '15px', display: 'flex', justifyContent: 'center'}}>
                    <div style={{
                        maxWidth: '100%',
                        backgroundImage: `url(${famHeaderLogo})`,
                        width: '500px',
                        height: '300px',
                        // box-shadow: 0 0 8px 8px white inset;
                        boxShadow: '0 0 20px 10px black inset',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat'
                    }} />
                </Col>
            </Row>
            <Row style={{alignItems: 'center', marginBottom: '10px'}}>
                <Col className={classes.infoCard} md={6} xs={12}>
                    <Typography className={classes.subtitle} variant="h3" style={{padding: '15px 0px'}}>
                        Manage Your Family
                    </Typography>
                    <Typography className={classes.subtitle} variant="body1">
                        Get SMS alerts for you and your family members PKs as they come out
                    </Typography>
                </Col>
                <Col className={classes.pictureCard} md={6} xs={12}>
                    {/* <img className={classes.productPhoto} src={dailySnapshot} style={{width: 'auto', maxWidth: '100%', borderRadius: '5px'}} /> */}
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/ikSiNOBaoaA" title="YouTube video player" frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    
                </Col>
            </Row>

            <Row style={{alignItems: 'center', marginBottom: '10px'}}>
                <Col className={classes.pictureCard} md={6} xs={12} style={{display: isMd ? '': 'none'}}>
                    {isMd && <iframe width="100%" height="315" src="https://www.youtube.com/embed/bkeHbXXMoNA" 
                        title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen></iframe>}
                </Col>
                <Col className={classes.infoCard} md={6} xs={12}>
                    <Typography className={classes.subtitle} variant="h3" style={{padding: '15px 0px'}}>
                        Manage Your Agency
                    </Typography>
                    <Typography className={classes.subtitle} variant="body1">
                        Get SMS alerts for you and your agency members to stay up to date on Agency PKs.
                    </Typography>
                </Col>
            </Row>
            <Row style={{alignItems: 'center', marginBottom: '10px', display: isMd ? 'none': ''}}>
                <Col className={classes.pictureCard} md={6} xs={12}>
                    {!isMd && <iframe width="100%" height="315" src="https://www.youtube.com/embed/bkeHbXXMoNA" 
                        title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen></iframe>}
                </Col>
            </Row>

            <Row style={{alignItems: 'center', marginBottom: '10px'}}>
                <Col className={classes.infoCard} md={6} xs={12}>
                    <Typography className={classes.subtitle} variant="h3" style={{padding: '15px 0px'}}>
                        Focus On Growth
                    </Typography>
                    <Typography className={classes.subtitle} variant="body1">
                        Grow your business without having to worry about all of the admin work.
                    </Typography>
                </Col>
                <Col className={classes.pictureCard} md={6} xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {/* <img className={classes.productPhoto} src={customerDetail} style={{width: 'auto', maxWidth: '100%', borderRadius: '5px'}} /> */}
                    <Typography className={classes.subtitle} variant="h4">
                        Contact Us <br />
                        WhatsApp +1(734)444-5609<br />
                        BIGO ID : nobizz
                    </Typography>
                </Col>
            </Row>
        </div>

    )
}