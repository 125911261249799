import { Popover } from '@mui/material'
import { Row, Form } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import imageColumns from '../../../enums/bigoImageCols'
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
    row: {
        fontWeight: 'bold'
    },
})

export const getFlagsFromStorage = (key) => {
    try{
        const f = localStorage.getItem(key)
        return JSON.parse(f)
    }
    catch (err) {}
    return null
}

export const setFlagsToStorage = (key, val) => {
    if (Number(val) || Number(val) === 0){
        localStorage.setItem(key, val)
        return
    }
    localStorage.removeItem(key)
}


export const ImageColumns = ({el, close, id, startingFlags, showFamily}) => {
    
    const [flags, setFlags] = useState(null)
    const classes = useStyles()

    useEffect(() => {
        setFlags(startingFlags)
    }, [startingFlags])

    const hasFlag = (f, s) => {
        if (f === null){
            return true
        }
        return (f & s) !== 0
    }

    const setFlag = (on, s) => {
        let theseFlags = flags
        if (theseFlags === null){
            theseFlags = imageColumns.All
        }

        if (on){
            theseFlags |= s
        }
        else{
            theseFlags &= ~s
        }
        setFlags(theseFlags)
    }

    return <Popover
        id={el ? id : undefined}
        open={Boolean(el)}
        anchorEl={el}
        onClose={() => close(flags)}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
    >
        <div style={{padding: '10px'}}>
            <Form>
                <Form.Group as={Row}>
                    <Form.Check type="switch" 
                        id={id + '-BIGOID'}
                        label="BIGO ID" 
                        checked={hasFlag(flags, imageColumns.BigoId)}
                        onChange={(e) => {
                            console.log(e)
                            setFlag(e.target.checked, imageColumns.BigoId)
                        }}
                    />
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Check type="switch" 
                        id={id + '-EVENT'}
                        label="Event" 
                        checked={hasFlag(flags, imageColumns.Event)}
                        onChange={(e) => setFlag(e.target.checked, imageColumns.Event)}
                    />
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Check type="switch" 
                        id={id + '-DETAILS'}
                        label="Details" 
                        checked={hasFlag(flags, imageColumns.Details)}
                        onChange={(e) => setFlag(e.target.checked, imageColumns.Details)}
                    />
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Check type="switch" 
                        id={id + '-OPPONENT'}
                        label="Opponent" 
                        checked={hasFlag(flags, imageColumns.Opponent)}
                        onChange={(e) => setFlag(e.target.checked, imageColumns.Opponent)}
                    />
                </Form.Group>
                {showFamily && <Form.Group as={Row}>
                    <Form.Check type="switch" 
                        id={id + '-FAMILY'}
                        label="Family" 
                        checked={hasFlag(flags, imageColumns.Family)}
                        onChange={(e) => setFlag(e.target.checked, imageColumns.Family)}
                    />
                </Form.Group>}
                <Form.Group as={Row}>
                    <Form.Check type="switch" 
                        id={id + '-REWARD'}
                        label="Reward" 
                        checked={hasFlag(flags, imageColumns.Reward)}
                        onChange={(e) => setFlag(e.target.checked, imageColumns.Reward)}
                    />
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Check type="switch" 
                        id={id + '-PST'}
                        label="Pacific" 
                        checked={hasFlag(flags, imageColumns.Pacific)}
                        onChange={(e) => setFlag(e.target.checked, imageColumns.Pacific)}
                    />
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Check type="switch" 
                        id={id + '-MST'}
                        label="Mountain" 
                        checked={hasFlag(flags, imageColumns.Mountain)}
                        onChange={(e) => setFlag(e.target.checked, imageColumns.Mountain)}
                    />
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Check type="switch" 
                        id={id + '-CST'}
                        label="Central" 
                        checked={hasFlag(flags, imageColumns.Central)}
                        onChange={(e) => setFlag(e.target.checked, imageColumns.Central)}
                    />
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Check type="switch" 
                        id={id + '-EST'}
                        label="Eastern" 
                        checked={hasFlag(flags, imageColumns.Eastern)}
                        onChange={(e) => setFlag(e.target.checked, imageColumns.Eastern)}
                    />
                </Form.Group>
            </Form>
        </div>
    </Popover>
}