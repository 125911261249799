import { handleErrors} from '../base'
import { refreshAPI } from './auth'

let sessionTimer = null
const sessionInterval = 10 * 1000 // every 10 seconds
const tokenLocation = 'BIGOFAM'
const customerTokenLoation = 'NetworkSmarketerCustomer'

export const checkSession = async () => {
    //console.log(`Checking Session Tokens - ${new Date()}`)
    clearTimeout(sessionTimer)
    let tokens = await getTokens(false)
    if (tokens){
        sessionTimer = setTimeout(() => checkSession(), sessionInterval)
    }
}

export const startSession = () => {
    sessionTimer = setTimeout(() => checkSession(), sessionInterval)
}

export const setHomeAccountId = (id) => {
    let tokens = localStorage.getItem(tokenLocation)
    if (tokens){
        tokens = JSON.parse(tokens)
        tokens.HomeAccountId = Number(id)
        localStorage.setItem(tokenLocation, JSON.stringify(tokens))
    }
}

export const setTokens = (obj) => {
    let et = new Date()
    et.setHours(et.getHours() + 24)
    obj.ExpirationTime = et

    localStorage.setItem(tokenLocation, JSON.stringify(obj))
}

export const setCustomerTokens = (obj) => {
    let et = new Date()
    et.setHours(et.getHours() + 24)
    obj.ExpirationTime = et

    localStorage.setItem(customerTokenLoation, JSON.stringify(obj))
}

export const modifyCustomerToken = (field, value) => {
    const token = getCustomerTokens()
    if (token != null){
        token[field] = value;
        localStorage.setItem(customerTokenLoation, JSON.stringify(token))
    }
}

export const getTokens = async (withUpdate = false, noRedirect = false) => {
    let tokens = localStorage.getItem(tokenLocation)
    try {
        tokens = JSON.parse(tokens)
        if (!tokens){
            throw Error('Missing Tokens')
        }

        let now = new Date()
        if (now >= new Date(tokens.ExpirationTime)){
            throw Error('Expired Tokens')
        }

        if (withUpdate){                
            if (now >= new Date(tokens.RefreshTime)){
                // Needs refreshed
                const response = await refreshAPI(tokens.RefreshToken)
                const results = await handleErrors(response)
                // Only Access + IdToken
                tokens.AccessToken = results.AccessToken
                tokens.IdToken = results.IdToken
                setTokens(tokens, true)
            }
            else{
                setTokens(tokens, false)
            }
        }
        return tokens
    }
    catch (err){
        clearSession(!noRedirect)
        return false
    }    
}

export const getTokensSyncNoUpdate = (noLogout) => {
    let tokens = localStorage.getItem(tokenLocation)
    try {
        tokens = JSON.parse(tokens)
        if (!tokens){
            throw Error('Missing Tokens')
        }
        let now = new Date()
        if (now >= new Date(tokens.ExpirationTime)){
            throw Error('Expired Tokens')
        }
        return tokens
    }
    catch (err){
        if (noLogout){
            return false
        }
        clearSession(true)
        return null
    }    
}

export const getCustomerTokens = () => {
    let tokens = localStorage.getItem(customerTokenLoation)
    try {
        tokens = JSON.parse(tokens)
        if (!tokens){
            throw Error('Missing Tokens')
        }
        let now = new Date()
        if (now >= new Date(tokens.ExpirationTime)){
            throw Error('Expired Tokens')
        }
        return tokens
    }
    catch (err){
        clearCustomerSession()
        return null
    }    
}

export const tokensExist = () => {
    let tokens = localStorage.getItem(tokenLocation)
    try {
        tokens = JSON.parse(tokens)
        if (!tokens){
            throw Error('Missing Tokens')
        }
        let now = new Date()
        if (now >= new Date(tokens.ExpirationTime)){
            throw Error('Expired Tokens')
        }
        return true
    }
    catch (err){
        clearSession()
        return false
    }    
}

export const clearSession = (withRedirect = false) => {
    localStorage.setItem(tokenLocation, null)
    // localStorage.setItem('OPERATOR_NEW_CUSTOMERS', null)
    if (withRedirect){
        window.location.href = '/'
    }
}

export const clearCustomerSession = () => {
    localStorage.setItem(customerTokenLoation, null)
}