import {useState, useEffect, Fragment} from 'react'
import { Row, Col, ProgressBar, Form, ButtonGroup, Button } from 'react-bootstrap'
import { CircularProgress, Tabs, Tab, useMediaQuery } from '@material-ui/core'
import { getTokensSyncNoUpdate } from '../../api/auth/session'

import { handleErrors, baseURL } from "../../api/base"
import { getAgencyTrackingThisMonthAPI, getAgencyTrackingLastMonthAPI, deleteWeeklyReportAPI } from '../../api/bigo/agencyapi'
import MovingIcon from '@mui/icons-material/Moving';
import BadIcon from '@mui/icons-material/TrendingDown'
import FireIcon from '@mui/icons-material/Whatshot'
import StarIcon from '@mui/icons-material/Star'
import CrisisIcon from '@mui/icons-material/CrisisAlert'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import agencyTrackingStatus from '../../enums/agencyTrackingStatus'

function a11yProps(index, name) {
    return {
      id: `section-${name}-tab-${index}`,
      'aria-controls': `section-${name}-tabpanel-${index}`,
    };
  }

export const AgencyTracking = ({notify, sub, user}) => {

    const isSmall = useMediaQuery('(max-width:650px)')
    const [loading, setLoading] = useState(true)
    const [thisMonth, setThisMonth] = useState([])
    const [lastMonth, setLastMonth] = useState([])
    const [uploadPercent, setUploadPercent] = useState(null)
    const [uploadDone, setUploadDone] = useState(true)
    const tokenInfo = getTokensSyncNoUpdate()
    const [fileDate, setFileDate] = useState(null)
    const [fileName, setFileName] = useState('')
    const [monthTab, setMonthTab] = useState(0)
    const [statusTab, setStatusTab] = useState(0)
    

    const dateFormatter = new Intl.DateTimeFormat('en-US', {
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short'
    })

    useEffect(() => {
        const loadData = async () => {
            const tm = await loadTracking()
            const lm = await loadLMTracking()

            // If This Month is empty default to last month
            if (tm && lm && tm.length === 0 && lm.length > 0){
                setMonthTab(1)
            }
            setLoading(false)
        }

        loadData()
    }, [])

    const loadTracking = async () => {
        try{
            const response = await getAgencyTrackingThisMonthAPI(tokenInfo.IdToken, tokenInfo.AccessToken)
            const result = await handleErrors(response)
            setThisMonth(result)
            return result
        }            
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
    }

    const loadLMTracking = async () => {
        try{
            const response = await getAgencyTrackingLastMonthAPI(tokenInfo.IdToken, tokenInfo.AccessToken)
            const result = await handleErrors(response)
            setLastMonth(result)
            return result
        }            
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
    }

    const reloadTracking = async () => {
        setLoading(true)
        await loadTracking()
        await loadLMTracking()
        setLoading(false)
    }

    const removeWeeklyReport = async () => {
        if (!fileDate){
            return
        }
        const dateFormatted = encodeURIComponent(fileDate.toUTCString())

        try{
            setLoading(true)
            const response = await deleteWeeklyReportAPI(tokenInfo.IdToken, tokenInfo.AccessToken, dateFormatted)
            const result = await handleErrors(response)
            reloadTracking()
        }
        catch (error) {
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
        setLoading(false)
    }

    const uploadWeeklySheet = (e) => {
        const file = e.target.files[0]
        if (!file || !fileDate){
            return
        }
        
        setUploadDone(false)
        setUploadPercent(0)
        setFileName(file.name)

        const oneHundredMB = 104857600
        if (file.size > oneHundredMB){
            notify('Upload Error', 'File Size Must Be Under 100 MB')
            return
        }

        let xhr = new XMLHttpRequest()
        if (xhr.upload){
            xhr.upload.onprogress = (e) => {
                const done = e.position || e.loaded
                const total = e.totalSize || e.total
                setUploadPercent(Math.floor(done / total * 1000) / 10)
            }
        }
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                setUploadDone(true)
                // setTimeout(() => setUploadPercent(null), 1000)
                e.target.value = ''
                if (xhr.response){
                    const responseJSON = JSON.parse(xhr.response)
                    console.log(xhr.response)
                    if (responseJSON && responseJSON.status){
                        if (responseJSON.status === 400){
                            notify('Upload Error', 'There was an error processing the data in your file')
                        }
                        else if (responseJSON.status === 401){
                            notify('Upload Error', 'Unauthorized')
                        }
                        else if (responseJSON === 'Success'){
                            reloadTracking()
                        }
                    }
                    else if (responseJSON === 'Success'){
                        reloadTracking()
                    }
                }
            }
        }
        
        let formData = new FormData()
        formData.append('uploadFile', file)

        const dateFormatted = encodeURIComponent(fileDate.toUTCString())

        xhr.open('post', `${baseURL}/agency/weeklyreport/${dateFormatted}`)
        xhr.setRequestHeader('Authorization', `bearer ${tokenInfo.IdToken}`)
        xhr.setRequestHeader('Access', `${tokenInfo.AccessToken}`)
        xhr.send(formData)
    }

    const statusTabs = {
        ACTIVE: 0,
        INACTIVE: 1,
        AHEAD: 2,
        BEHIND: 3,
        ALL: 4
    }

    const getTargetData = (tabOne, tabTwo, curData, lastData) => {
        let targetData
        if (tabOne === 0){
            targetData = curData
        }
        else{
            targetData = lastData
        }

        if (tabTwo === statusTabs.ACTIVE){
            targetData = targetData.filter(x => x.Status & agencyTrackingStatus.Active)
        }
        else if (tabTwo === statusTabs.INACTIVE){
            targetData = targetData.filter(x => x.Status & agencyTrackingStatus.NotActive)
        }
        else if (tabTwo === statusTabs.AHEAD){
            targetData = targetData.filter(x => x.Status & agencyTrackingStatus.BeatingExpectations)
        }
        else if (tabTwo === statusTabs.BEHIND){
            targetData = targetData.filter(x => (x.Status & agencyTrackingStatus.UnderExpectations) && (x.Status & agencyTrackingStatus.Active))
        }

        return targetData
    }

    const hourRender = (p) => {
        if (p.row.Status & agencyTrackingStatus.AheadOnHours){
            return goodIcon(p.value)
        }
        else if ((p.row.Status & agencyTrackingStatus.BehindOnHours) || (p.row.Status & agencyTrackingStatus.CantMakeHours)){
            return badIcon(p.value, p.row.Status & agencyTrackingStatus.CantMakeHours)
        }
        return <span>{p.value}</span>
    }

    const beanRender = (p) => {
        if (p.row.Status & agencyTrackingStatus.AheadOnBeans){
            return goodIcon(p.value, p.row.Status & agencyTrackingStatus.OnTrackToTierUp, p.row.Status & agencyTrackingStatus.HigherTier)
        }
        else if (p.row.Status & agencyTrackingStatus.BehindOnBeans){
            return badIcon(p.value)
        }
        return <span>{p.value}</span>
    }

    const goodIcon = (text, hasFire, hasStar) => {
        return <span style={{color: 'green'}}>
            {Boolean(hasStar)
                ? <span title="Beat Last Month">
                    <StarIcon style={{
                        marginRight: '5px'
                    }}/>
                </span>
                : Boolean(hasFire) 
                    ? <span title="On Track To Beat Last Month">
                        <FireIcon style={{
                            marginRight: '5px'
                        }} />
                    </span>
                    : <span title="On Track">
                        <MovingIcon style={{
                            marginRight: '5px'
                        }} />
                    </span>
            }
            {text}
        </span>
    }

    const badIcon = (text, isCrisis) => {
        return <span style={{color: 'red'}}>
            {isCrisis
                ? <span title="Cant Hit Target Goal"><CrisisIcon style={{
                    marginRight: '5px'
                }} /></span>
                : <span  title="Behind Target Goal"><BadIcon style={{
                    marginRight: '5px'
                }} /></span>
            }
            {text}
        </span>
    }

    const renderTierProgression = (val, perc) => {
        if (val === 0){
            return <span>NOT ELIGIBLE</span>
        }
        return <span style={{color: perc >= 100 ? 'green' : ''}}>
            {val} ({perc}%)
        </span>
    }

    const dtOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    const showDate = (p) => {
        return (new Date(p.value)).toLocaleDateString(undefined, dtOptions)
    }
    const sortDate = (v1, v2) => {
        return (new Date(v1)) > (new Date(v2)) ? 1 : -1
    }

    const columns = [
        { field: 'BigoId', headerName: 'BIGO ID', width: 150 },
        { field: 'Username', headerName: 'Username', width: 175 },
        { field: 'PolicyCode', headerName: 'Policy', width: 150 },
        { field: 'Hours', headerName: 'Hours', width: 150, renderCell: hourRender },
        { field: 'EligibleBeans', headerName: 'Tier Beans', width: 150, renderCell: beanRender },
        { field: 'Username', headerName: 'Username', width: 150 },
        { field: 'AvgPCU', headerName: 'Avg PCU', width: 150, valueGetter: (p) => p.value === null ? 'N/A' : p.value },
        { field: 'Fans', headerName: 'Fans', width: 150 },
        { field: 'TargetTier', headerName: 'Target Tier', width: 150, renderCell: (p) => renderTierProgression(p.value, p.row.TargetCompletion) },
        { field: 'NextTier', headerName: 'Next Tier', width: 150, renderCell: (p) => renderTierProgression(p.value, p.row.TierCompletion) },
        { field: 'TargetHours', headerName: 'Target Hours', width: 150, renderCell: (p) => renderTierProgression(p.value, p.row.TargetHourCompletion) },
        { field: 'InAgencyRate', headerName: 'In Agency %', width: 150, valueGetter: (p) => p.value + '%' },
        { field: 'CurrentTier', headerName: 'Current Tier', width: 150 },
        { field: 'LastMonthBeans', headerName: 'LM Beans', width: 150, valueGetter: (p) => p.value === null ? 'N/A' : p.value },
        { field: 'LastMonthAPCU', headerName: 'LM APCU', width: 150, valueGetter: (p) => p.value === null ? 'N/A' : p.value },
        { field: 'SignedDate', headerName: 'Signed', width: 150, renderCell: showDate, sortComparator: sortDate },
        { field: 'FileDate', headerName: 'Updated', width: 150, renderCell: showDate, sortComparator: sortDate },
    ];

    return <Fragment>
    <Row>
        <Col>
            <h2>
                Agency Tracking
            </h2>
        </Col>
    </Row>

    <hr />
    
    <Row style={{marginTop: '5px', height: '85%', width: '100%'}}>
        <div style={{width: '100%', paddingLeft: '20px', paddingRight: '10px'}}>
            <div style={{width: '100%'}}>
                <Row style={{alignItems: 'center', marginBottom: '10px'}}>
                    <Form.Label style={{paddingLeft: '20px', paddingRight: '20px', fontWeight: 'bold', minWidth: '200px', textAlign: 'right'}}>
                        Weekly Report Date
                    </Form.Label>
                    <DatePicker 
                        selected={fileDate}
                        onChange={(d) => setFileDate(d)}
                        dateFormat="MMM d, yyyy"
                    />
                    <Button style={{marginLeft: '10px'}} variant="danger" disabled={!fileDate} onClick={removeWeeklyReport}>Remove Records</Button>
                </Row>
                <Row style={{alignItems: 'center'}}>
                    <Form.Label style={{paddingLeft: '20px', paddingRight: '20px', fontWeight: 'bold', minWidth: '200px', textAlign: 'right'}}>
                        Weekly Report
                    </Form.Label>
                    <ButtonGroup style={{ width: '380px' }}>
                        <Form.File id={`report-upload`} custom>
                            <Form.File.Input accept=".xlsx" disabled={!uploadDone || !fileDate} onChange={uploadWeeklySheet} />
                            <Form.File.Label data-browse="Browse">
                                {fileName}
                            </Form.File.Label>
                        </Form.File>
                    </ButtonGroup>
                </Row>
                {uploadPercent !== null && <Row style={{alignItems: 'center'}}>
                    <Form.Label style={{paddingLeft: '20px', paddingRight: '20px', fontWeight: 'bold', minWidth: '200px'}}>
                        &nbsp;
                    </Form.Label>
                    <div style={{ width: '380px' }}>
                        <ProgressBar animated now={uploadPercent} label={`${uploadPercent}%`} variant={uploadDone ? 'success' : 'info'} />
                    </div>
                </Row>}
            </div>
            <hr />
            {loading || !user || !sub
            ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%', width: '100%' }}>
                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                </div>
            )
            : (
                <Row style={{ width: '100%', height: '70%' }}>
                    <Row style={{width: '100%'}}>
                        <Tabs
                            style={{width: '100%'}}
                            value={monthTab}
                            onChange={(e, n) => setMonthTab(n)}
                            indicatorColor="primary"
                            textColor="inherit"
                            centered
                        >
                            <Tab label="This Month" {...a11yProps(0, 'month')} />                                
                            <Tab label="Last Month" {...a11yProps(1, 'month')} />
                        </Tabs>
                    </Row>
                    <Row style={{width: '100%', minWidth: '340px'}}>
                        <Tabs
                            style={{width: '100%'}}
                            value={statusTab}
                            onChange={(e, n) => setStatusTab(n)}
                            indicatorColor="secondary"
                            textColor="inherit"
                            centered
                        >
                            <Tab label={isSmall ? 'AC' : "Active"} {...a11yProps(0, 'status')} />                                
                            <Tab label={isSmall ? 'IN' : "Inactive"} {...a11yProps(1, 'status')} />                       
                            <Tab label={isSmall ? 'AH' : "Ahead"} {...a11yProps(2, 'status')} />                       
                            <Tab label={isSmall ? 'BH' : "Behind"} {...a11yProps(3, 'status')} />                  
                            <Tab label="All" {...a11yProps(4, 'status')} />
                        </Tabs>
                    </Row>
                    <DataGrid 
                        columns={columns}
                        rows={getTargetData(monthTab, statusTab, thisMonth, lastMonth)} 
                        getRowId={(x) => x.BigoId} 
                        components={{ Toolbar: GridToolbar }}
                    />
                </Row>
            )}
        </div>
    </Row>
</Fragment>
}