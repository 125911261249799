import { Form } from 'react-bootstrap'

export const CountrySelector = ({getter, setter}) => {
    
    const countries = [
        {
            name: 'United States',
            code: 'USA'
        },
        {
            name: 'Canada',
            code: 'CAN'
        },
        {
            name: 'Mexico',
            code: 'MEX'
        },
        {
            name: 'Puerto Rico',
            code: 'PRI'
        },
        {
            name: 'Greenland',
            code: 'GRL'
        },
        {
            name: 'Australia',
            code: 'AUS'
        }
    ]

    const sortedCountries = () => {
        const final = countries.sort((a, b) => a.name > b.name ? 1 : -1)
        final.unshift({
            name: 'Unknown',
            code: ''
        })
        return final
    }

    return <Form.Control as="select" value={getter || ''} onChange={setter}>
        {sortedCountries().map(x => {
            return <option value={x.code} key={x.code}>
                {x.name}
            </option>
        })}
    </Form.Control>
    
}