import react, {useState, useEffect, Fragment, useMemo} from 'react'
import { Row, Col, Table, Form, Modal, InputGroup, Button } from 'react-bootstrap'
import { Card, CircularProgress, Checkbox, useMediaQuery } from '@material-ui/core'
import { getTokensSyncNoUpdate } from '../../api/auth/session'
import { handleErrors } from "../../api/base"
import EditIcon from '@material-ui/icons/Edit'
import { ContentCopy as CopyIcon } from '@mui/icons-material'
import userFlags from "../../enums/userFlags"
import { getHistoricEventsAPI } from '../../api/bigo/agencyapi'
import { makeStyles } from "@material-ui/styles"
import { copyTextToClipboard } from '../../utility/commands'
import DatePicker from 'react-datepicker'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { LinearProgress, FormControlLabel } from '@mui/material'
import userStatus from '../../enums/userStatus'
import { realPlans as testPlans} from '../../enums/bigoPlans/defaultPlan'

const useStyles = makeStyles({
    colLabel: {
        fontWeight: 'bold'
    },
    centerCol: {
        display: 'flex',
        justifyContent: 'center',
    },
})

export const AgencyHistory = ({notify, sub, user}) => {


    const [loading, setLoading] = useState(true)
    const [history, setHistory] = useState(null)

    const now = new Date()
    const [startDate, setStartDate] = useState(now)
    const [endDate, setEndDate] = useState(now)
    const [dataLoading, setDataLoading] = useState(true)
    const [includeFam, setIncludeFam] = useState(false)

    const tokenInfo = getTokensSyncNoUpdate()
    const classes = useStyles()
    const isSmall = useMediaQuery('(max-width:650px)')

    const dateFormatter = new Intl.DateTimeFormat('en-US', {
        // hour12: true,
        // hour: 'numeric',
        // minute: 'numeric',
        // timeZoneName: 'short',
        month: 'short',
        year: '2-digit',
        day: 'numeric'
    })

    useEffect(() => {
        const loadInfo = async () => {
            await reloadHistory()
        }

        loadInfo()
    }, [startDate, endDate])

    const reloadHistory = async (ovrdeFam) => {
        if (!startDate){
            notify('Error', 'Invalid Start Date')
            return
        }
        if (!endDate){
            notify('Error', 'Invalid End Date')
            return
        }

        if (ovrdeFam === undefined){
            ovrdeFam = includeFam
        }

        try{
            setDataLoading(true)
            let start = new Date(startDate)
            start.setHours(0,0,0,0)

            let end = new Date(endDate)
            end.setHours(23, 59, 59, 999)

            const request = {
                Start: start,
                End: end,
                IncludeFamily: !!ovrdeFam
            }
            const response = await getHistoricEventsAPI(tokenInfo.IdToken, tokenInfo.AccessToken, request)
            const result = await handleErrors(response)
            result && setHistory(result.Items)
            console.log(result)
            setLoading(false)
        }
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
        setDataLoading(false)
    }

    const hasFamilyOnSub = () => {
        if (user && sub && sub.UserStatus === userStatus.Paid && sub.SubscriptionId){
            const matchPlan = testPlans.filter(x => x.StripeId === sub.PlanId)[0]
            if (matchPlan){
                return matchPlan.Family !== 0 && matchPlan.Agency !== 0
            }
            else if (sub.SubscriptionId === 'MASTER'){
                return true
            }
            else{
                return false
            }
        }
        else{
            return false
        }
    }

    const handleCopyClick = () => {
        copyTextToClipboard(signUpInfo && `${window.location.origin}/agency/${signUpInfo.Code}` || '')
    }

    // const filteredData = useMemo(() => {
    //     if (!signUpInfo){
    //         return []
    //     }
    //     return signUpInfo.SignUps.filter(x => statusFilter === -1 || x.Status === statusFilter)
    // }, [history])

    const dtOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short' };
    const showDate = (p) => {
        return (new Date(p.value + ' UTC')).toLocaleDateString(undefined, dtOptions)
    }
    const sortDate = (v1, v2) => {
        return (new Date(v1)) > (new Date(v2)) ? 1 : -1
    }

    const columns = [
        { field: 'User', headerName: 'BIGO ID', width: 200 },
        { field: 'Time', headerName: 'Time', width: 250, renderCell: showDate, sortComparator: sortDate },
        { field: 'EventName', headerName: 'Event', width: 300 },
        { field: 'Opponent', headerName: 'Opponent', width: 200, valueGetter: (p) => p.value || 'N/A' },
        { field: 'Family', headerName: 'Family', width: 200, valueGetter: (p) => p.value || 'Agency' },
    ];
    
    return <Fragment>
        <Row>
            <Col>
                <h2>
                    Agency Event History
                </h2>
            </Col>
            {hasFamilyOnSub() && <Col xs={12} lg={4} xl={3}>
            <Row>
                <Form.Group>
                    <FormControlLabel
                        label="Show Family PKs"
                        control={<Checkbox 
                            checked={includeFam} 
                            onChange={(e) => {
                                setIncludeFam(e.target.checked)
                                reloadHistory(e.target.checked)
                                // localStorage.setItem(withFamilyLocation, JSON.stringify(e.target.checked))
                            }}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} 
                        />}
                        sx={{' .MuiFormControlLabel-label': { fontSize: 24 } }}
                    />
                </Form.Group>
            </Row>
        </Col>}
        </Row>

        <hr />

        <Row style={{marginTop: '5px', height: '85%', width: '100%'}}>
            <div style={{width: '100%'}}>
                {loading
                ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%', width: '100%' }}>
                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                </div>
                : <Fragment>
                    <Row>
                        <Col xs={12}>
                            <Card style={{paddingTop: '10px', paddingBottom: '10px', marginBottom: '15px', minHeight: '400px'}}>
                                <Row style={{marginBottom: '8px'}}>
                                    <Col xs={8} lg={3} style={isSmall ? {} : {display: 'flex', justifyContent: 'flex-end'}}>
                                        <DatePicker 
                                            selected={startDate}
                                            onChange={(d) => setStartDate(d)}
                                            dateFormat="MMM d, yyyy"
                                        />
                                    </Col>
                                    <Col xs={8} lg={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <span>To</span>
                                    </Col>
                                    <Col xs={8} lg={3}>
                                        <DatePicker 
                                            selected={endDate}
                                            onChange={(d) => setEndDate(d)}
                                            dateFormat="MMM d, yyyy"
                                        />
                                    </Col>
                                </Row>
                                <Row style={{height: '75vh'}}>
                                    <DataGrid 
                                        columns={columns}
                                        rows={history || []} 
                                        getRowId={(x) => x.Id} 
                                        components={{ 
                                            Toolbar: GridToolbar,
                                            LoadingOverlay: LinearProgress
                                        }}
                                        loading={dataLoading}
                                    />
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Fragment>
                }
            </div>
        </Row>
    </Fragment>
}