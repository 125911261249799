import React, { useEffect, useState, Fragment } from 'react'
import { Row, Col, Accordion, useAccordionToggle, Card } from 'react-bootstrap'
import { CircularProgress } from '@material-ui/core'
import { baseURL, handleErrors } from '../../api/base'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { getHelpVideosAPI } from '../../api/bigo/helpapi.js'
import { getTokensSyncNoUpdate } from '../../api/auth/session'

export const BigoHelp = ({ notify, user}) => {

    const isSmall = useMediaQuery('(max-width:650px)')
    const isLarge = useMediaQuery('(min-width:1200px)')
    const isXLarge = useMediaQuery('(min-width:1500px)')

    const tokenInfo = getTokensSyncNoUpdate()    

    const [pages, setPages] = useState([])
    const [loading, setLoading] = useState(true)
    const [activeVideo, setActiveVideo] = useState(null)

    useEffect(() => {
        const loadData = async () => {
            await loadHelpVideos()
            setLoading(false)
        }
       loadData()
    }, [])

    const loadHelpVideos = async () => {
        try{
            const response = await getHelpVideosAPI(tokenInfo.IdToken, tokenInfo.AccessToken)
            const result = await handleErrors(response)
            
            setPages(result)
        }
        catch (error){
            let finalError = error
            try{
                finalError = await error
            }
            catch(err){}
            console.log(finalError)
            notify('Error', finalError.toString())
        }
    }

    return <Fragment>
        <Row>
            <Col xs={0} md={1}></Col>
            <Col>
                <h2>
                    Help
                </h2>
            </Col>
        </Row>
        <hr />
        {loading
            ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%'}}>
                <CircularProgress style={{width: '100px', height: '100px'}} />
            </div>
            : (
                <Accordion defaultActiveKey={activeVideo}>
                    {pages && pages.map(x => {
                        return (
                            <Row style={{paddingLeft: isSmall ? '0' : '4rem', paddingRight: isSmall ? '1rem' : '4rem', marginBottom: '10px'}} key={x.VideoId}> 
                                <Card style={{width: '100%'}}>
                                    <CustomToggle eventKey={x.VideoId} setCurrentKey={setActiveVideo} currentKey={activeVideo}>
                                        <h3 style={{marginLeft: '10px'}}>
                                            {x.Title}
                                        </h3>
                                    </CustomToggle>
                                    <Accordion.Collapse eventKey={x.VideoId}>
                                        <Card.Body style={{padding: '0.5rem'}}>
                                            {activeVideo === x.VideoId && (
                                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                                    <video style={{maxHeight: '70vh', maxWidth: '100%'}} controlsList='nodownload' width={isLarge ? 600 : 400} controls={true}>
                                                        <source src={`${baseURL}/content/playvideo/${x.VideoId}`} type="video/mp4" />
                                                    </video>
                                                </div>
                                            )}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Row>
                        )
                    })}
                </Accordion>
            )
        }
    </Fragment>
}



const CustomToggle = ({ children, eventKey, setCurrentKey, currentKey }) => {
      
    const isCurrentEventKey = currentKey === eventKey

    const decoratedOnClick = useAccordionToggle( eventKey, () => {
          setCurrentKey(isCurrentEventKey ? '' : eventKey)
    })
  
  
    return <Card.Header onClick={decoratedOnClick} style={{cursor: 'pointer'}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
            {isCurrentEventKey ? <ExpandLess/> : <ExpandMore />}
            {children}
        </div>
    </Card.Header>
  }